import * as TP from '../subscriptionTypes';

import { gql } from '@apollo/client';
export type RouteMaxCostEventPayloadFragment = (
  { readonly __typename?: 'RouteMaxCostEvent' }
  & Pick<TP.RouteMaxCostEvent, 'id' | 'routeMaxCost' | 'type'>
);

export const RouteMaxCostEventPayloadFragmentDoc = gql`
    fragment RouteMaxCostEventPayload on RouteMaxCostEvent {
  id
  routeMaxCost
  type
}
    `;