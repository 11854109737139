import cx from 'classnames';
import { isUndefined } from 'lodash-es';
import { FC, ReactNode } from 'react';
import { component } from './style.css';
export interface PaddingProps {
  /** All */
  a1?: boolean;
  a2?: boolean;
  a3?: boolean;
  a4?: boolean;
  a5?: boolean;
  /** Top */
  t1?: boolean;
  t2?: boolean;
  t3?: boolean;
  t4?: boolean;
  t5?: boolean;
  /** Right */
  r1?: boolean;
  r2?: boolean;
  r3?: boolean;
  r4?: boolean;
  r5?: boolean;
  /** Bottom */
  b1?: boolean;
  b2?: boolean;
  b3?: boolean;
  b4?: boolean;
  b5?: boolean;
  /** Left */
  l1?: boolean;
  l2?: boolean;
  l3?: boolean;
  l4?: boolean;
  l5?: boolean;
  children?: ReactNode;
  className?: string;
}

// We can do this with lodash _.pickBy, but it's slower.
const pickByFromList = <T extends unknown>(
  object: T,
  list: Set<string>,
  exclude = false
): Partial<T> => {
  const obj = {} as Partial<T>;
  for (const key in object) {
    const hasTheKey = exclude ? !list.has(key) : list.has(key);
    if (!isUndefined(object) && hasTheKey) {
      obj[key] = object[key];
    }
  }
  return obj;
};

const configPropsArr = new Set([
  'a1',
  'a2',
  'a3',
  'a4',
  'a5',
  't1',
  't2',
  't3',
  't4',
  't5',
  'r1',
  'r2',
  'r3',
  'r4',
  'r5',
  'b1',
  'b2',
  'b3',
  'b4',
  'b5',
  'l1',
  'l2',
  'l3',
  'l4',
  'l5',
]);

const getConfigProps = (props: PaddingProps): Partial<PaddingProps> =>
  pickByFromList(props, configPropsArr);

const getNonConfigProps = (props: PaddingProps): Partial<PaddingProps> =>
  pickByFromList(props, configPropsArr, true);

export const Padding: FC<PaddingProps> = (props) => {
  const { className, children, ...rest } = getNonConfigProps(props);
  let mappedProps = Object.keys(getConfigProps(props)).map(
    (key) => component[key]
  );
  if (!mappedProps.length) {
    mappedProps = [component['t1'], component['b1']];
  }
  return (
    <div className={cx(mappedProps, className)} {...rest}>
      {children}
    </div>
  );
};
