import { Icon } from '@components/Icon';
import { css } from '@emotion/react';
import { useFlagMe38173ViewGroupOptionInSidebar } from '@generated/flags/ME-38173-view-group-option-in-sidebar';
import { useTheme } from '@hooks/useTheme';
import { AUTOCOMPLETE } from '@utils/zIndex';
import { useSelect } from 'downshift';
import { FC, ReactNode, RefObject } from 'react';
import {
  deprecatedGrey,
  deprecatedMidGrey,
} from '../../../../styles/variables/colors';
import { SearchType } from '../types';

const DeprecatedListMenu: FC<{
  children: ReactNode;
  innerRef?: RefObject<HTMLUListElement>;
}> = ({ children, innerRef, ...rest }) => {
  const { contextMenu } = useTheme();
  const style = css({
    position: 'absolute',
    zIndex: AUTOCOMPLETE,
    backgroundColor: contextMenu.background,
    borderRadius: '3px',
    boxShadow: `0 2px 3px ${deprecatedGrey}`,
    border: `1px solid ${deprecatedGrey}`,
    margin: '8px 0',
    padding: '4px 0',
    listStyle: 'none',
    maxHeight: '50vh',
    overflowY: 'auto',
    width: '160px',

    '& li': {
      width: '100%',
      padding: '8px',
      background: 'transparent',
      border: 'none',
      textAlign: 'left',
      transition: '.3s',
      cursor: 'pointer',

      '&:hover': { background: deprecatedMidGrey },
      '&[aria-selected="true"]': { background: deprecatedMidGrey },
    },
  });

  return (
    <ul css={style} {...rest} ref={innerRef}>
      {children}
    </ul>
  );
};

export interface Props {
  setSearchType: (searchType: SearchType) => void;
  searchType: SearchType;
}

export const SearchTypeDropDown: FC<Props> = ({
  setSearchType,
  searchType,
}) => {
  const options: SearchType[] = [
    SearchType.rep,
    SearchType.carrier,
    SearchType.customer,
    SearchType.facility,
    SearchType.load,
  ];

  const viewGroupOptionFlag = useFlagMe38173ViewGroupOptionInSidebar();
  if (viewGroupOptionFlag) {
    options.push(SearchType.group);
  }

  const { isOpen, getToggleButtonProps, getMenuProps, getItemProps } =
    useSelect({
      items: options,
      selectedItem: searchType,
      onSelectedItemChange: ({ selectedItem }) => {
        selectedItem && setSearchType(selectedItem);
      },
    });

  const { formElement, gray } = useTheme();

  const icon = isOpen ? 'angleUp' : 'angleDown';

  return (
    <div
      data-testid="search-type-dropdown"
      css={{
        position: 'relative',
      }}
    >
      {/* TODO: use shared Button component */}
      <button
        css={{
          width: '100%',
          display: 'flex',
          padding: '4.8px 8px',
          height: '100%',
          justifyContent: 'space-between',
          background: formElement.background,
          border: `1px solid ${gray[80]}`,
          alignItems: 'center',
        }}
        {...getToggleButtonProps()}
      >
        <span data-testid="dropdown-searchtype">{searchType}</span>
        <Icon i={icon} size="sm" color="text" />
      </button>
      <DeprecatedListMenu
        {...getMenuProps({
          refKey: 'innerRef',
          css: {
            visibility: isOpen ? 'visible' : 'hidden',
          },
        })}
      >
        {isOpen &&
          options.map((item, index) => {
            return (
              <li
                css={{ width: '100%' }}
                key={item}
                data-testid={`dropdown-item-${item}`}
                {...getItemProps({ item, index })}
              >
                {item}
              </li>
            );
          })}
      </DeprecatedListMenu>
    </div>
  );
};
