import { win } from './win';

export const sendSync = <D extends unknown>(
  key: string,
  ...args: unknown[]
): D => {
  return win.electron?.ipc.sendSync(key, ...args) as fixMe;
};

export const send = (key: string, kwargs: unknown): void => {
  win.electron?.ipc.send(key, kwargs) as fixMe;
};

export const on = (key: string, cb: (e: fixMe, data: fixMe) => void): void => {
  win.electron?.ipc?.on(key, cb);
};
