/* eslint-disable no-restricted-imports */
import { useDebouncedFn } from '@hooks/useDebouncedFn';
import { memoize } from 'lodash-es';
import { FC } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

interface Props {
  keys: string;
  /** Convenience prop for held={[['Control'], ['Meta']]} which is ctrl on Windows, Cmd on Mac */
  cmd?: boolean;
  onEvent: (e?: KeyboardEvent) => void;
  deps?: anyOk[];
  debounceMillis?: number;
}

export const getKeys = memoize((keys: string, cmd: boolean): string => {
  if (!cmd) {
    return keys;
  }
  return keys
    .split(',')
    .map((t) => t.trim())
    .map((s) => `ctrl+${s}, cmd+${s}`)
    .join(', ');
});

// If this value is too low, the event seems to fire at least twice per how keyboard-js works
const KEYPRESS_DEBOUNCE_MILLIS = 250;

export const useShortcut = (
  { keys: rawKeys, cmd, onEvent, debounceMillis }: Props,
  deps?: anyOk[]
): void => {
  const keys = getKeys(rawKeys, cmd || false);

  const handleEvent = (e: KeyboardEvent): void => {
    onEvent(e);
  };

  const debouncedHandleEvent = useDebouncedFn(
    handleEvent,
    debounceMillis || KEYPRESS_DEBOUNCE_MILLIS,
    deps || []
  );

  useHotkeys(
    keys,
    (e) => {
      e.preventDefault();
      debouncedHandleEvent(e);
    },
    {
      filter: () => true,
    },
    deps
  );
};

// ts-unused-exports:disable-next-line
export const Shortcut: FC<Props & { deps?: anyOk[] }> = (props) => {
  useShortcut(props);
  return null;
};
