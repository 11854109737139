import * as TP from '../subscriptionTypes';

import { gql } from '@apollo/client';
export type MinionRefreshEventPayloadFragment = (
  { readonly __typename?: 'MinionRefreshEvent' }
  & Pick<TP.MinionRefreshEvent, 'id' | 'message' | 'type'>
);

export const MinionRefreshEventPayloadFragmentDoc = gql`
    fragment MinionRefreshEventPayload on MinionRefreshEvent {
  id
  message
  type
}
    `;