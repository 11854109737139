// eslint-disable-next-line no-restricted-imports
import { baseColors } from '@components/Theme/baseColors';
// eslint-disable-next-line no-restricted-imports
import { newColors } from '@components/Theme/colors';
import { BODY_FONT_SIZE } from '@components/Theme/constants';
import { css, CSSObject } from '@emotion/react';
import { useTheme } from '@hooks/useTheme';
import { FS_UNMASK } from '@utils/fullstory';
import cx from 'classnames';
import { forwardRef, HTMLProps } from 'react';

interface ButtonTheme {
  primary: Array<string>;
  secondary?: Array<string>;
  tertiary?: Array<string>;
  quaternary?: Array<string>;
  quinary?: Array<string>;
  alert?: Array<string>;
  inverseAlert?: Array<string>;
  lockBannerAlert?: Array<string>;
  infoSystemDialog?: Array<string>;
  infoSystemDialogInverse?: Array<string>;
  errorSystemDialog?: Array<string>;
  errorSystemDialogInverse?: Array<string>;
  warningSystemDialog?: Array<string>;
  warningSystemDialogInverse?: Array<string>;
  successSystemDialog?: Array<string>;
  successSystemDialogInverse?: Array<string>;
}

export type ButtonOption = keyof ButtonTheme;

interface ButtonProps extends HTMLProps<HTMLButtonElement> {
  option?: ButtonOption;
  as?: 'button' | 'span' | 'div';
  show?: boolean;
  /** Disables svg color style on hover */
  noIconColorOnHover?: boolean;
}
const MIN_BUTTON_HEIGHT = 28;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (originalProps, ref) => {
    const {
      as = 'button',
      show = true,
      noIconColorOnHover = false,
      ...props
    } = originalProps;
    const { children, type = 'button', option = 'primary' } = props;
    const {
      button: { background },
      colors: { primary, error },
      gray,
    } = useTheme();

    const getStyleProps = (arr: string[], important?: string): CSSObject => ({
      background: `${arr[0]} ${important || ''}`,
      color: `${arr[1]} ${important || ''}`,
      border: `${arr[2]} ${important || ''}`,
      svg: !noIconColorOnHover
        ? {
            color: `${arr[1]} ${important || ''}`,
          }
        : undefined,
    });

    const initial: ButtonTheme = {
      primary: [background, primary, `1px solid ${primary}`],
      secondary: [gray[30], background, `1px solid ${gray[30]}`],
      tertiary: [background, primary, 'none'],
      quaternary: [primary, background, `1px solid ${primary}`],
      quinary: [background, gray[30], `1px solid ${gray[30]}`],
      alert: [error, background, `1px solid ${error}`],
      inverseAlert: [background, error, '1px solid white'],
      lockBannerAlert: [error, background],
      infoSystemDialog: [
        newColors.system.info.tonal,
        newColors.system.info.text,
      ],
      infoSystemDialogInverse: ['none', newColors.system.info.title],
      errorSystemDialog: [
        newColors.system.error.tonal,
        newColors.system.error.text,
      ],
      errorSystemDialogInverse: ['none', newColors.system.error.title],
      warningSystemDialog: [
        newColors.system.warning.tonal,
        newColors.system.warning.text,
      ],
      warningSystemDialogInverse: ['none', newColors.system.warning.title],
      successSystemDialog: [
        newColors.system.success.tonal,
        newColors.system.success.text,
      ],
      successSystemDialogInverse: ['none', newColors.system.success.title],
    };

    const active: ButtonTheme = {
      primary: [primary, background, `1px solid ${primary}`],
      secondary: [background, gray[30], `1px solid ${gray[30]}`],
      tertiary: [background, gray[30], 'none'],
      quaternary: [background, primary, `1px solid ${primary}`],
      quinary: [gray[30], background, `1px solid ${gray[30]}`],
      alert: [background, error, `1px solid ${error}`],
      inverseAlert: [error, background, '1px solid white'],
      lockBannerAlert: [error, background],
      infoSystemDialog: [
        newColors.system.info.tonal,
        newColors.system.info.text,
      ],
      infoSystemDialogInverse: [
        `${baseColors.blue[60]}1A`,
        newColors.system.info.title,
      ],
      errorSystemDialog: [
        newColors.system.error.tonal,
        newColors.system.error.text,
      ],
      errorSystemDialogInverse: [
        `${baseColors.red[60]}1A`,
        newColors.system.error.title,
      ],
      warningSystemDialog: [
        newColors.system.warning.tonal,
        newColors.system.warning.text,
      ],
      warningSystemDialogInverse: [
        `${baseColors.yellow[60]}1A`,
        newColors.system.warning.title,
      ],
      successSystemDialog: [
        newColors.system.success.tonal,
        newColors.system.success.text,
      ],
      successSystemDialogInverse: [
        `${baseColors.green[60]}1A`,
        newColors.system.success.title,
      ],
    };

    const initialStyles = initial[option] || initial.primary;
    const activeStyles = active[option] || active.primary;

    const style = css({
      borderRadius: '3px',
      cursor: 'pointer',
      justifyContent: 'center',
      padding: '6px 12px',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      transition: '.3s',
      fontSize: BODY_FONT_SIZE,
      minHeight: MIN_BUTTON_HEIGHT,
      ...getStyleProps(initialStyles),
      '&:disabled': {
        cursor: 'not-allowed !important',
        opacity: 0.5,
        ...getStyleProps(initialStyles, '!important'),
      },
      '&:hover': getStyleProps(activeStyles),
    });

    const El = as as anyOk;

    return (
      <>
        {show && (
          <El
            {...props}
            type={type}
            className={cx(props.className, FS_UNMASK)}
            css={style}
            ref={ref}
          >
            {children}
          </El>
        )}
      </>
    );
  }
);
