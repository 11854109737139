/**
 * @param {string} str A string to be formatted using title case.
 * @returns {string} The title case formatted string
 * @description
 * This function takes a string and makes the first letter of each new word and letters directly after a period uppercase.
 * eg:
 * '1 front st' -> '1 Front St'
 * 'p.o box stn' -> 'P.O Box Stn'
 * 'abCAbc ABC' -> 'Abcabc Abc'
 */
export const titleCaseNoStrip = (str: Maybe<string>): string => {
  if (!str) {
    return '';
  }
  const formattedStr = str
    .split(' ')
    .map((word) =>
      word
        .split('.')
        .map(
          (word) => (word[0]?.toUpperCase() ?? '') + word.slice(1).toLowerCase()
        )
        .join('.')
    )
    .join(' ');
  return formattedStr;
};
