/* istanbul ignore file */
import { AppStyles } from '@app/Styles';
import { SaveButton } from '@components/Button/SaveButton';
import { Field } from '@components/Field';
import { Grid } from '@components/Grid';
import { Logo } from '@components/Logo';
import { SaveProvider } from '@components/SaveContext';
import { Theme } from '@components/Theme';
import { sendSync } from '@utils/electron';
import { win } from '@utils/win';
import { Form, Formik } from 'formik';
import { FC, ReactNode } from 'react';
import { PRIVATE_themeObj } from '../../components/Theme';
import { getKnownDomainFromInput } from '../../config';

export const SpaceSelection: FC = () => {
  return (
    <Theme>
      <SaveProvider>
        <AppStyles />
        <div
          css={{
            display: 'grid',
            width: '100vw',
            height: '100vh',
            placeItems: 'center',
            '-webkit-app-region': 'drag',
          }}
        >
          <Formik
            onSubmit={(values): fixMe => {
              sendSync(
                'store.set',
                'space',
                getKnownDomainFromInput(values.space)
              );
              win.location.reload();
              return true;
            }}
            initialValues={{
              space: '',
            }}
            validate={(values): fixMe => {
              const d = getKnownDomainFromInput(values.space);
              return d ? undefined : { space: 'Invalid domain.' };
            }}
          >
            {(): ReactNode => (
              <Form
                css={{
                  width: 400,
                  marginTop: -100,
                  '-webkit-app-region': 'no-drag',
                }}
              >
                <Grid xs="1fr" css={{ placeItems: 'center' }}>
                  <Logo
                    color={PRIVATE_themeObj.colors.text as anyOk}
                    width="112px"
                    css={{ marginLeft: -40 }}
                  />
                </Grid>
                <Grid
                  xs="1fr min-content"
                  gap={0.5}
                  css={{ alignItems: 'end' }}
                >
                  <Field
                    name="space"
                    label="Enter Your Company Domain"
                    autoFocus
                  />
                  <SaveButton
                    fsParent="space-selection"
                    type="submit"
                    id="space"
                    option="quaternary"
                  >
                    Submit
                  </SaveButton>
                </Grid>
              </Form>
            )}
          </Formik>
        </div>
      </SaveProvider>
    </Theme>
  );
};
