import { jsonParse, jsonStringify } from './json';
import { win } from './win';

export const getLocalStorage = <T extends unknown>(key: string): T | null => {
  try {
    const ret = win.localStorage.getItem(key);
    if (typeof ret === 'string') {
      const parsed = jsonParse<T>(ret);
      if (parsed !== undefined) {
        return parsed;
      }
    }
  } catch (err: anyOk) {
    return null;
  }
  return null;
};

export const setLocalStorage = <T extends unknown>(
  key: string,
  data: T
): void => {
  try {
    win.localStorage.setItem(key, jsonStringify(data));
  } catch (err: anyOk) {
    // This can catch if the browser doesn't have localStorage (private browsing)
  }
};

export const removeLocalStorage = (key: string): void => {
  try {
    win.localStorage.removeItem(key);
  } catch (err: anyOk) {
    // This can catch if the browser doesn't have localStorage (private browsing)
  }
};
