import {
  PowersForMasterfindV2Document,
  PowersForMasterfindV2Query,
  PowersForMasterfindV2QueryVariables,
} from '@generated/queries/powersForMasterfindV2';
import { useLazyQueryWithDataPromise } from '@hooks/useLazyQueryWithDataPromise';
import { FC, useCallback } from 'react';
import { BASE_MAX_ITEM_LIMIT } from '../constants';

type SearchNode = PowersForMasterfindV2Query['powerAutoComplete'][number];

export const RenderPowerSearchItem: FC<{
  item: SearchNode;
}> = ({ item }) => {
  const activeFleet = item.carrier?.name;
  return (
    <span>
      <strong>{item.code}</strong>
      {item.displayName && <span>&nbsp;|&nbsp;{item.displayName}</span>}
      {activeFleet && <span>&nbsp;|&nbsp;{activeFleet}</span>}
    </span>
  );
};

export const useSearchPowerPromise = (): ((
  text: string
) => Promise<SearchNode[]>) => {
  const callQueryV2 = useLazyQueryWithDataPromise<
    PowersForMasterfindV2Query,
    PowersForMasterfindV2QueryVariables
  >(PowersForMasterfindV2Document);

  return useCallback(
    async (text: string): Promise<SearchNode[]> => {
      const rawRes = await callQueryV2({
        fetchPolicy: 'network-only',
        variables: {
          queryFilter: {
            searchTerm: text,
            limit: BASE_MAX_ITEM_LIMIT,
          },
        },
      });
      return rawRes.data.powerAutoComplete.filter(Boolean);
    },
    [callQueryV2]
  );
};
