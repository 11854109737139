import * as TP from '../subscriptionTypes';

import { gql } from '@apollo/client';
export type DriverAssignmentEventPayloadFragment = (
  { readonly __typename?: 'DriverAssignmentEvent' }
  & Pick<TP.DriverAssignmentEvent, 'id' | 'driverAssignmentID' | 'eventTimestamp'>
);

export const DriverAssignmentEventPayloadFragmentDoc = gql`
    fragment DriverAssignmentEventPayload on DriverAssignmentEvent {
  id
  driverAssignmentID
  eventTimestamp
}
    `;