import { useCsaGetProviderDetailsQuery } from '@generated/queries/csaGetProviderDetails';

export const useGetMessagingServiceStatus = (
  providerId: string
): (boolean | undefined)[] => {
  const { data, loading } = useCsaGetProviderDetailsQuery({
    variables: {
      providerId: providerId,
    },

    skip: !providerId,
  });

  return [data?.csaGetProviderDetails?.statusOn, loading];
};
