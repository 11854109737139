import { GetOptionByIdFunc } from '@app/TenantConfig';
import {
  TrailersForMasterfindV2Document,
  TrailersForMasterfindV2Query,
  TrailersForMasterfindV2QueryVariables,
} from '@generated/queries/trailersForMasterfindV2';
import { useLazyQueryWithDataPromise } from '@hooks/useLazyQueryWithDataPromise';
import { FC, useCallback } from 'react';
import { BASE_MAX_ITEM_LIMIT } from '../constants';

type SearchNode = TrailersForMasterfindV2Query['trailerAutocomplete'][number];

export const RenderTrailerSearchItem: FC<{
  item: SearchNode;
  getTermById: GetOptionByIdFunc;
  getOperatingStatusById: GetOptionByIdFunc;
  getTrailerServiceStatusById: GetOptionByIdFunc;
}> = ({ item, getTermById }) => {
  const option = getTermById(item.typeTerm);
  const type = option?.name;
  const activeFleet = item.carrier?.name;
  // Final output should be:
  // **Trailer Code** | Trailer Type | Active Fleet
  // optionally append TOS and TSS
  return (
    <span>
      <strong>{item.code}</strong>
      {type && <span>&nbsp;|&nbsp;{type}</span>}
      {activeFleet && <span>&nbsp;|&nbsp;{activeFleet}</span>}
    </span>
  );
};

export const useSearchTrailerPromise = (): ((
  text: string
) => Promise<SearchNode[]>) => {
  const callQueryV2 = useLazyQueryWithDataPromise<
    TrailersForMasterfindV2Query,
    TrailersForMasterfindV2QueryVariables
  >(TrailersForMasterfindV2Document);

  return useCallback(
    async (text: string): Promise<SearchNode[]> => {
      const rawRes = await callQueryV2({
        fetchPolicy: 'network-only',
        variables: {
          trailerSearch: {
            searchTerm: text,
            limit: BASE_MAX_ITEM_LIMIT,
          },
        },
      });
      return rawRes.data.trailerAutocomplete.filter(Boolean);
    },
    [callQueryV2]
  );
};
