import * as TP from '../subscriptionTypes';

import { gql } from '@apollo/client';
export type DriverMessagingNewMessageEventPayloadFragment = (
  { readonly __typename?: 'DriverMessagingNewMessageEvent' }
  & Pick<TP.DriverMessagingNewMessageEvent, 'id' | 'conversationId' | 'sentByUserId'>
);

export const DriverMessagingNewMessageEventPayloadFragmentDoc = gql`
    fragment DriverMessagingNewMessageEventPayload on DriverMessagingNewMessageEvent {
  id
  conversationId
  sentByUserId
}
    `;