import { CSSObject } from '@emotion/react';
import { breakpoint, BreakpointObject } from '@utils/breakpoint';
import { jsonStringify } from '@utils/json';
import cx from 'classnames';
import { fromPairs, isEmpty, memoize, pickBy } from 'lodash-es';
import { FC, forwardRef, HTMLProps, MouseEvent, ReactNode } from 'react';
import { BASE_GAP_MULTIPLIER } from './constants';
import { base } from './style.css';

interface Props
  extends Partial<
    Pick<BreakpointObject<string>, 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'>
  > {
  as?: 'div' | 'ul';
  hidden?: boolean;
  children: ReactNode;
  className?: string;
  onMouseDown?: (e: MouseEvent) => void;
  /** Number of same width columns */
  cols?: number;
  /** The minimum pixels a column should have */
  fit?: number;
  /** grid-gap rem */
  gap?: number;
  /** Can be a number for px or any valid padding string property */
  padding?: string | number;
  /** grid-gap for breakpoint:sm */
  smGap?: number;
}

export const colSpan = (span: number): CSSObject => ({
  gridColumn: `span ${span}`,
});

const unitMultiplier = (n: number): string => `${n * BASE_GAP_MULTIPLIER}px`;
const evenSplit = (value: number): string => `repeat(${value}, 1fr)`;

const supportedBreakPoints = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'] as const;

// generate styles for each breakpoint specified in props
const genBreakpointStylesRaw = (
  props: Pick<Props, 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'smGap'>
): Record<string, unknown> =>
  fromPairs(
    supportedBreakPoints
      .map((bp) => {
        const gridTemplateColumns = props[bp];
        const gap = props[`${bp}Gap` as keyof typeof props] as number;
        const val = pickBy({
          gridTemplateColumns,
          gap: gap ? `${unitMultiplier(gap)}` : undefined,
        });
        return [breakpoint[bp], isEmpty(val) ? undefined : val];
      })
      .filter(([, val]) => Boolean(val))
  );

const genBreakpointStyles = memoize(genBreakpointStylesRaw, jsonStringify);

const getCols = ({ fit, cols, xs }: Props): string => {
  let str = '100%';
  if (xs) {
    str = xs;
  } else if (fit) {
    str = `repeat(auto-fit, minmax(${fit}px, 1fr))`;
  } else if (cols) {
    str = evenSplit(cols);
  }
  return str;
};

export const Grid: FC<Props> = (rawProps) => {
  const { children, ...props } = rawProps;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { cols, fit, sm, xs, md, lg, xl, xxl, ...more } = props;
  const { as = 'div', className, gap: gapProp = 1, padding, ...rest } = more;

  const gap = unitMultiplier(gapProp);
  const Element = as;

  return (
    <Element
      {...rest}
      className={cx(base, className)}
      css={{
        gap,
        gridTemplateColumns: getCols(rawProps),
        padding:
          typeof padding === 'number'
            ? `${unitMultiplier(padding as number)}`
            : padding,
        ...genBreakpointStyles({
          xs: props.xs,
          sm: props.sm,
          md: props.md,
          lg: props.lg,
          xl: props.xl,
          xxl: props.xxl,
          smGap: props.smGap,
        }),
      }}
    >
      {children}
    </Element>
  );
};

export const VertGrid = forwardRef<
  HTMLDivElement,
  HTMLProps<HTMLDivElement> & {
    children: ReactNode;
    onScroll?: (evt: fixMe) => void;
  }
>((props, ref) => (
  <div
    css={{ display: 'grid', gridAutoFlow: 'column', gridGap: 20 }}
    {...props}
    ref={ref}
  />
));
