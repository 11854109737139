import * as TP from '../subscriptionTypes';

import { gql } from '@apollo/client';
export type RepeatLoadEventPayloadFragment = (
  { readonly __typename?: 'RepeatLoadEvent' }
  & Pick<TP.RepeatLoadEvent, 'id' | 'type' | 'rowUniqueId' | 'orderCode' | 'orderStatus' | 'message' | 'activationStatus' | 'activationStatusReason'>
  & { repeatedLoadId: TP.RepeatLoadEvent['loadId'] }
  & { readonly routes?: TP.Maybe<ReadonlyArray<(
    { readonly __typename?: 'RepeatedRoute' }
    & Pick<TP.RepeatedRoute, 'id' | 'code' | 'sequence'>
  )>> }
);

export const RepeatLoadEventPayloadFragmentDoc = gql`
    fragment RepeatLoadEventPayload on RepeatLoadEvent {
  id
  type
  rowUniqueId
  repeatedLoadId: loadId
  orderCode
  orderStatus
  message
  activationStatus
  activationStatusReason
  routes {
    id
    code
    sequence
  }
}
    `;