import { win } from './win';

type PerfMark =
  | 'html-1'
  | 'html-2'
  | 'src/index/init'
  | 'src/index/render'
  | 'view/load/mount'
  | 'view/load/data'
  | 'components/Table/renderCell';

export const mark = (str: PerfMark): void => {
  const prefixed = `me-${str}`;
  try {
    if (!win.performance.getEntriesByName(prefixed).length) {
      win.performance.mark(prefixed);
    }
  } catch {
    //
  }
};
