import * as TP from '../types';

import { gql } from '@apollo/client';
export type ScheduleRangeInfoFragment = (
  { readonly __typename?: 'ScheduleRange' }
  & Pick<TP.ScheduleRange, 'endOffsetMs' | 'id' | 'startOffsetMs'>
);

export const ScheduleRangeInfoFragmentDoc = gql`
    fragment ScheduleRangeInfo on ScheduleRange {
  endOffsetMs
  id
  startOffsetMs
}
    `;