import * as electron from '@utils/electron';
import { createBrowserHistory } from 'history';
import { win } from './win';

export const history = createBrowserHistory();

if (win.Cypress) {
  (win as anyOk).routerHistory = history;
}

const navigateMain = (kwargs: { path: string }): void => {
  electron.send('navigateMain', kwargs);
};

(history as fixMe).pushMain = (path: string): void => {
  navigateMain({ path });
};
