import { useFlagMe290238EnableApiElementsPhase2 } from '@generated/flags/ME-290238-enable-api-elements-phase-2';
import { FsComponentProps, getFsComponentPropsUtil } from '@utils/fullstory';

export function useFullstoryElement(): Record<string, anyOk> {
  const reportFullstoryElements = useFlagMe290238EnableApiElementsPhase2();

  function getFsComponentProps(
    args: FsComponentProps,
    flagOverride: boolean = false
  ): ReturnType<typeof getFsComponentPropsUtil> | null {
    // If the `flagOverride` is true, bypass the check on `reportFullstoryElements`
    // and directly call `getFsComponentPropsUtil`.
    if (flagOverride || reportFullstoryElements) {
      return getFsComponentPropsUtil(args);
    }

    return null;
  }

  return {
    getFsComponentProps,
  };
}
