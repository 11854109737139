import * as TP from '../subscriptionTypes';

import { gql } from '@apollo/client';
export type ProcurementMatchActionTakenEventPayloadFragment = (
  { readonly __typename?: 'ProcurementMatchActionTakenEvent' }
  & Pick<TP.ProcurementMatchActionTakenEvent, 'id' | 'actionsTaken' | 'type'>
);

export const ProcurementMatchActionTakenEventPayloadFragmentDoc = gql`
    fragment ProcurementMatchActionTakenEventPayload on ProcurementMatchActionTakenEvent {
  id
  actionsTaken
  type
}
    `;