/* eslint-disable no-restricted-imports */
import {
  AlertActionButton,
  AlertDismissButton,
  SystemAlertHeader,
} from '@components/SystemAlerts/shared/components';
import {
  AlertTypeProps,
  SystemAlertType,
} from '@components/SystemAlerts/shared/constants';
import { newColors } from '@components/Theme/colors';
import styled from '@emotion/styled';
import { FS_UNMASK } from '@utils/fullstory';
import _ from 'lodash-es';
import { ReactText } from 'react';
import { toast } from 'react-toastify';
import { ToastOptions } from '..';

const ToastContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Content = styled.div<AlertTypeProps>((props) => ({
  color: newColors.system[props.type].text,
  margin: '8px 0 16px 24px',
}));

const ButtonContainer = styled.div`
  margin-left: 24px;
`;

interface ToastProps {
  title?: string;
  content: Maybe<string>;
  action?: {
    label: string;
    onClick: () => void;
  };
  type: SystemAlertType;
  closeToast?: () => void;
  toastOptions?: ToastOptions;
  unmaskTitle?: boolean;
  unmaskContent?: boolean;
}

const Toast = ({
  title,
  content,
  action,
  closeToast,
  type,
  unmaskTitle = false,
  unmaskContent = false,
}: ToastProps): JSX.Element => {
  return (
    <ToastContainer>
      <SystemAlertHeader title={title} type={type} unmaskTitle={unmaskTitle} />
      <Content type={type} className={unmaskContent ? FS_UNMASK : undefined}>
        {content}
      </Content>
      <ButtonContainer>
        {action && <AlertActionButton action={action} type={type} />}
        <AlertDismissButton
          type={type}
          data-testid="toast-dismiss-button"
          onClick={(): void => closeToast?.()}
          style={{ marginLeft: action ? 'none' : '-12px' }}
        />
      </ButtonContainer>
    </ToastContainer>
  );
};

// ts-unused-exports:disable-next-line
export interface ToasterProps {
  title?: string;
  content: Maybe<string>;
  action?: {
    label: string;
    onClick: () => void;
  };
  type: 'success' | 'warning' | 'error' | 'info';
  toastOptions?: ToastOptions;
  unmaskTitle?: boolean;
  unmaskContent?: boolean;
}

export const toaster = ({
  title,
  content,
  action,
  type,
  unmaskTitle,
  unmaskContent,
  toastOptions,
}: ToasterProps): ReactText => {
  const getToastTime = (): number => {
    const trimTitle = title?.replace(/\s/g, '') ?? '';
    const trimContent = content?.replace(/\s/g, '') ?? '';
    const chars = trimTitle.concat(trimContent);
    const charCount = chars?.length;
    const time = charCount && charCount * 50;
    const showTime = time && _.clamp(time, 5000, 10000);

    if (showTime) {
      return showTime;
    }
    return 5000;
  };

  return toast[type](
    <Toast
      title={title}
      content={content}
      action={action}
      type={type}
      unmaskTitle={unmaskTitle}
      unmaskContent={unmaskContent}
    />,
    {
      autoClose: getToastTime(),
      hideProgressBar: true,
      containerId: 'toast',
      ...toastOptions,
    }
  );
};
