import { DependencyList, useEffect } from 'react';
import { useFirstMountState } from 'react-use';

/** useEffect but do not run the effect on mount. */
export const useEffectAfterMount = (
  cb: () => anyOk,
  arr: DependencyList
): fixMe => {
  const isFirstMount = useFirstMountState();
  useEffect(() => {
    if (!isFirstMount) {
      cb();
    }
  }, arr);
};
