export const launchDarklyKeyMap = {
  // Local Dev (only used for ld infra testing by Atlas)
  local: '5dcc30f129064b08eed09414',
  // Internal Dev (mm100-dev) - default for "yarn start" locally
  internalDev: '5dcc3f77af9e34086c559510',
  // Internal Test, Thunderdome (mm100-test, td100-test)
  internalTest: '63dbe7fb98db8b11ee224f42',
  // Client UAT (ex. sn100-test)
  preview: '5dcd725170202d0901737f9d',
  // Client Production (ex. sn100-prod)
  production: '5dcc30f129064b08eed09415',
};
