import { Ref } from 'react';

// Allow the assignment/sync of multiple refs
// Original code from ⬇
// https://github.com/facebook/react/issues/13029#issuecomment-522632038
export const useCombinedRefs =
  (...refs: Ref<unknown>[]) =>
  (target: HTMLElement | null): void => {
    refs.forEach((ref) => {
      if (!ref) {
        return;
      }

      if (typeof ref === 'function') {
        ref(target);
      } else {
        (ref as fixMe).current = target;
      }
    });
  };
