import { debounce } from 'lodash-es';
import { useCallback, useState } from 'react';

const DEFAULT_DEBOUNCE_TIMEOUT = 500;

interface StringSetter {
  (_: string): void;
}

interface Props {
  initialValue?: string;
  debounceTimeout?: number;
}

export const useTamedInputValue = ({
  initialValue = '',
  debounceTimeout = DEFAULT_DEBOUNCE_TIMEOUT,
}: Props): [string, StringSetter] => {
  const [value, updateValue] = useState<string>(initialValue);

  // TODO: examine this eslint rule
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdate = useCallback(
    debounce((newValue: string): void => {
      updateValue(newValue);
    }, debounceTimeout),
    []
  );

  return [value, debouncedUpdate];
};
