import * as TP from '../subscriptionTypes';

import { gql } from '@apollo/client';
export type IncidentEventPayloadFragment = (
  { readonly __typename?: 'IncidentEvent' }
  & Pick<TP.IncidentEvent, 'id' | 'incidentID' | 'incidentEventType' | 'type'>
);

export const IncidentEventPayloadFragmentDoc = gql`
    fragment IncidentEventPayload on IncidentEvent {
  id
  incidentID
  incidentEventType
  type
}
    `;