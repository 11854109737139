import * as TP from '../subscriptionTypes';

import { gql } from '@apollo/client';
export type SubStopExecutionEventPayloadFragment = (
  { readonly __typename?: 'SubStopExecutionEvent' }
  & Pick<TP.SubStopExecutionEvent, 'id' | 'stopEventIDs' | 'stopEventTypeID' | 'stopID' | 'actionType'>
);

export const SubStopExecutionEventPayloadFragmentDoc = gql`
    fragment SubStopExecutionEventPayload on SubStopExecutionEvent {
  id
  stopEventIDs
  stopEventTypeID
  stopID
  actionType
}
    `;