import { win } from '@utils/win';

const masteryBoot = win.masteryBoot || {};

const {
  BASE_HEADER_HEIGHT,
  IS_HEADER_ALLOWED,
  IS_SIDEBAR_ALLOWED,
  IS_SIDEBAR_SHOWN_ON_PAGE_LOAD,
  SIDEBAR_QUERY_PARAM,
} = masteryBoot;

export {
  BASE_HEADER_HEIGHT,
  IS_HEADER_ALLOWED,
  IS_SIDEBAR_ALLOWED,
  IS_SIDEBAR_SHOWN_ON_PAGE_LOAD,
  SIDEBAR_QUERY_PARAM,
};
