/* eslint-disable no-restricted-imports */
import {
  AlertActionButton,
  AlertDismissButton,
  SystemAlertHeader,
} from '@components/SystemAlerts/shared/components';
import {
  AlertTypeProps,
  SystemAlertType,
} from '@components/SystemAlerts/shared/constants';
import { newColors } from '@components/Theme/colors';
import styled from '@emotion/styled';
import { FS_UNMASK } from '@utils/fullstory';
import { ReactText } from 'react';
import { toast } from 'react-toastify';
import { SystemBannerOpts } from '..';

const BannerContainer = styled.div({
  display: 'grid',
  gridTemplateColumns: 'auto 1fr auto',
  alignItems: 'center',
});

const Content = styled.div<AlertTypeProps>((props) => ({
  color: newColors.system[props.type].text,
  marginLeft: 16,
}));

const ButtonContainer = styled.div({
  marginLeft: 24,
  width: 'max-content',
});

interface SystemBannerProps {
  title?: string;
  content: Maybe<string>;
  action?: {
    label: string;
    onClick: () => void;
  };
  type: SystemAlertType;
  dismiss?: {
    label?: string;
    onClick?: () => void;
  };
  closeToast?: () => void;
  toastOptions?: SystemBannerOpts;
  unmaskTitle?: boolean;
  unmaskContent?: boolean;
}

const SystemBanner = ({
  title,
  content,
  action,
  dismiss,
  closeToast,
  type,
  unmaskTitle = false,
  unmaskContent = false,
}: SystemBannerProps): JSX.Element => {
  return (
    <BannerContainer>
      <SystemAlertHeader title={title} type={type} unmaskTitle={unmaskTitle} />
      <Content type={type} className={unmaskContent ? FS_UNMASK : undefined}>
        {content}
      </Content>
      <ButtonContainer>
        {action && (
          <AlertActionButton
            action={action}
            type={type}
            closeToast={(): void => closeToast?.()}
          />
        )}
        <AlertDismissButton
          type={type}
          dismiss={dismiss}
          onClick={(): void => closeToast?.()}
          style={{ marginLeft: action ? 'none' : '-12px' }}
        />
      </ButtonContainer>
    </BannerContainer>
  );
};

// ts-unused-exports:disable-next-line
export interface SystemBannerComponentProps {
  title?: string;
  content: Maybe<string>;
  action?: {
    label: string;
    onClick: () => void;
  };
  dismiss?: {
    label?: string;
    onClick?: () => void;
  };
  type: 'success' | 'warning' | 'error' | 'info';
  systemBannerOpts?: SystemBannerOpts;
  unmaskTitle?: boolean;
  unmaskContent?: boolean;
}

export const systemBannerComponent = ({
  title,
  content,
  action,
  dismiss,
  type,
  unmaskTitle,
  unmaskContent,
  systemBannerOpts,
}: SystemBannerComponentProps): ReactText => {
  return toast[type](
    <SystemBanner
      title={title}
      content={content}
      action={action}
      dismiss={dismiss}
      type={type}
      unmaskTitle={unmaskTitle}
      unmaskContent={unmaskContent}
    />,
    {
      autoClose: false,
      hideProgressBar: true,
      containerId: 'systemBanner',
      position: 'top-center',
      ...systemBannerOpts,
    }
  );
};
