import * as TP from '../subscriptionTypes';

import { gql } from '@apollo/client';
export type DriverMessagingNewMessageEventV2PayloadFragment = (
  { readonly __typename?: 'DriverMessagingNewMessageEventV2' }
  & Pick<TP.DriverMessagingNewMessageEventV2, 'id' | 'drivers'>
  & { readonly conversation: (
    { readonly __typename?: 'Conversation' }
    & Pick<TP.Conversation, 'classification' | 'value'>
  ) }
);

export const DriverMessagingNewMessageEventV2PayloadFragmentDoc = gql`
    fragment DriverMessagingNewMessageEventV2Payload on DriverMessagingNewMessageEventV2 {
  id
  conversation {
    classification
    value
  }
  drivers
}
    `;