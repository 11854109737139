/* eslint-disable mastery/known-imports */
/* eslint-disable no-console */
import * as Sentry from '@sentry/browser';
import { CaptureConsole, RewriteFrames } from '@sentry/integrations';
import { ScopeContext } from '@sentry/types';
import { isString } from 'lodash-es';
import { sentryDsn } from '../config';
import {
  BUILD_VERSION,
  IS_LOCAL_OR_CI_CYPRESS,
  IS_UNIT_TEST,
  USE_SENTRY,
  environment,
} from './constants';
import { RESOLVED_TENANT } from './getTenantFromDomain';
import { win } from './win';

export const sentry = Sentry;

const isFromWalkme = (event: Sentry.Event): boolean => {
  try {
    for (const exception of event?.exception?.values ?? []) {
      for (const frame of exception.stacktrace?.frames ?? []) {
        if (frame.module?.includes('walkme')) {
          return true;
        }
      }
    }
  } catch {
    // noop
  }
  return false;
};

const isTrackedGraphQLEvent = (event: Sentry.Event): boolean => {
  try {
    return event?.tags?.graphql === 'true';
  } catch {
    // noop
  }
  return false;
};

const isFromApolloClientStack = (event: Sentry.Event): boolean => {
  try {
    for (const exception of event?.exception?.values ?? []) {
      for (const frame of exception.stacktrace?.frames ?? []) {
        if (frame.module?.includes('@apollo/client')) {
          return true;
        }
      }
    }
  } catch {
    // noop
  }
  return false;
};

const CUSTOM_ERROR_TAG_HINT = 'customError';

export const init = (): void => {
  if (USE_SENTRY) {
    Sentry.init({
      environment: environment,
      dsn: sentryDsn,
      release: BUILD_VERSION,
      integrations: [
        new CaptureConsole({ levels: ['error'] }) as fixMe,
        // If the frame does not include an extension, it is most likely coming from the url
        // The url could include custom identifiers like /load/:id
        // Without modifying the frames, we see multiple sentry errors (ungrouped) for the same actual issue
        // By coercing that frame to a general "url" filename, we should see better grouping
        // Inspiration: https://github.com/getsentry/sentry/issues/7709#issuecomment-439282029
        new RewriteFrames({
          iteratee: (frame): anyOk => {
            let filename = frame.filename ?? '';
            let mod = frame.module ?? '';
            if (!filename.match(/\.(js|ts|tsx)$/)) {
              filename = 'modified_filename';
              mod = 'modified_module';
            }
            return {
              ...frame,
              filename,
              module: mod,
            };
          },
        }),
      ],
      beforeSend(event) {
        // if the error is handled, don't send it to sentry
        // this would happen in something like a try/catch block
        if (
          event?.exception?.values?.[0]?.mechanism?.handled === true &&
          // if the error originates from reportCustomSentryError util, we want to let it through
          !event?.tags?.[CUSTOM_ERROR_TAG_HINT]
        ) {
          return null;
        }
        if (isTrackedGraphQLEvent(event)) {
          return event;
        } else if (isFromApolloClientStack(event)) {
          return null;
        }
        if (isFromWalkme(event)) {
          return null;
        }
        return event;
      },
      ignoreErrors: [
        /^Failed to fetch$/,
        /Synchronous XHR in page dismissal/,
        /LD: \[error\] network error \(Error\)/,
        /TypeError: error loading dynamically imported module/,
        // https://sentry.io/organizations/mastery-logistics-systems/issues/2544556849/events/6157fa054fb44930a65f463477503d1d/?project=1723665
        // LD Error
        /^network error (Error)$/,
        /Loading chunk.*failed\.$/,
        /AbortError/,
        /ECONNRESET/,
        /NetworkError/,
        /socket hang up/,
        /search_phase_execution_exception/,
        /Failed to fetch dynamically imported module/,
        // https://github.com/getsentry/sentry-javascript/issues/3440#issuecomment-954602174
        /Non-Error promise rejection captured with value: Object Not Found/,
      ],
    });
  }
};

if (IS_LOCAL_OR_CI_CYPRESS) {
  (win as anyOk).__mockSentryCall = (): void => {};
}

/** Report an error to Sentry. This should **not** be used for reporting API/GraphQL errors as those are already captured automatically. */
export const reportCustomSentryError = (
  rawErr: unknown,
  ctxKwargs?: Partial<ScopeContext>
): void => {
  if (IS_UNIT_TEST || !rawErr) {
    return;
  }
  let err = rawErr;
  if (isString(rawErr)) {
    err = new Error(rawErr);
  }
  const ctx = ctxKwargs || { tags: {} };

  const captureContext = {
    ...ctxKwargs,
    tags: {
      ...ctx.tags,
      origin: win.location.origin,
      tenant: RESOLVED_TENANT,
      [CUSTOM_ERROR_TAG_HINT]: 1,
    },
  };

  if (IS_LOCAL_OR_CI_CYPRESS) {
    console.error(err);
    return (win as anyOk).__mockSentryCall(err, captureContext);
  }

  sentry.captureException(err, captureContext);
};
