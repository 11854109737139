import * as TP from '../types';

import { gql } from '@apollo/client';
import { KeyValueInfoFragmentDoc } from './keyValueInfo';
import { ScheduleEntryInfoFragmentDoc } from './scheduleEntryInfo';
export type ScheduleInfoFragment = (
  { readonly __typename?: 'Schedule' }
  & Pick<TP.Schedule, 'appointmentType' | 'dropType' | 'serviceType' | 'trailerTypeIdNullable' | 'id' | 'modeType' | 'scheduleTypeNullable' | 'workType' | 'loadSizeId' | 'routeTransportModeId'>
  & { readonly trailerTypeNullable?: TP.Maybe<(
    { readonly __typename?: 'KeyValue' }
    & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
  )>, readonly loadSize?: TP.Maybe<(
    { readonly __typename?: 'KeyValue' }
    & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
  )>, readonly routeTransportMode?: TP.Maybe<(
    { readonly __typename?: 'KeyValue' }
    & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
  )>, readonly sunday: (
    { readonly __typename?: 'ScheduleEntry' }
    & Pick<TP.ScheduleEntry, 'closed' | 'id'>
    & { readonly hours: ReadonlyArray<(
      { readonly __typename?: 'ScheduleRange' }
      & Pick<TP.ScheduleRange, 'endOffsetMs' | 'id' | 'startOffsetMs'>
    )> }
  ), readonly monday: (
    { readonly __typename?: 'ScheduleEntry' }
    & Pick<TP.ScheduleEntry, 'closed' | 'id'>
    & { readonly hours: ReadonlyArray<(
      { readonly __typename?: 'ScheduleRange' }
      & Pick<TP.ScheduleRange, 'endOffsetMs' | 'id' | 'startOffsetMs'>
    )> }
  ), readonly tuesday: (
    { readonly __typename?: 'ScheduleEntry' }
    & Pick<TP.ScheduleEntry, 'closed' | 'id'>
    & { readonly hours: ReadonlyArray<(
      { readonly __typename?: 'ScheduleRange' }
      & Pick<TP.ScheduleRange, 'endOffsetMs' | 'id' | 'startOffsetMs'>
    )> }
  ), readonly wednesday: (
    { readonly __typename?: 'ScheduleEntry' }
    & Pick<TP.ScheduleEntry, 'closed' | 'id'>
    & { readonly hours: ReadonlyArray<(
      { readonly __typename?: 'ScheduleRange' }
      & Pick<TP.ScheduleRange, 'endOffsetMs' | 'id' | 'startOffsetMs'>
    )> }
  ), readonly thursday: (
    { readonly __typename?: 'ScheduleEntry' }
    & Pick<TP.ScheduleEntry, 'closed' | 'id'>
    & { readonly hours: ReadonlyArray<(
      { readonly __typename?: 'ScheduleRange' }
      & Pick<TP.ScheduleRange, 'endOffsetMs' | 'id' | 'startOffsetMs'>
    )> }
  ), readonly friday: (
    { readonly __typename?: 'ScheduleEntry' }
    & Pick<TP.ScheduleEntry, 'closed' | 'id'>
    & { readonly hours: ReadonlyArray<(
      { readonly __typename?: 'ScheduleRange' }
      & Pick<TP.ScheduleRange, 'endOffsetMs' | 'id' | 'startOffsetMs'>
    )> }
  ), readonly saturday: (
    { readonly __typename?: 'ScheduleEntry' }
    & Pick<TP.ScheduleEntry, 'closed' | 'id'>
    & { readonly hours: ReadonlyArray<(
      { readonly __typename?: 'ScheduleRange' }
      & Pick<TP.ScheduleRange, 'endOffsetMs' | 'id' | 'startOffsetMs'>
    )> }
  ) }
);

export const ScheduleInfoFragmentDoc = gql`
    fragment ScheduleInfo on Schedule {
  appointmentType
  dropType
  serviceType
  trailerTypeNullable {
    ...KeyValueInfo
  }
  trailerTypeIdNullable
  id
  modeType
  scheduleTypeNullable
  workType
  loadSize {
    ...KeyValueInfo
  }
  loadSizeId
  routeTransportMode {
    ...KeyValueInfo
  }
  routeTransportModeId
  sunday {
    ...ScheduleEntryInfo
  }
  monday {
    ...ScheduleEntryInfo
  }
  tuesday {
    ...ScheduleEntryInfo
  }
  wednesday {
    ...ScheduleEntryInfo
  }
  thursday {
    ...ScheduleEntryInfo
  }
  friday {
    ...ScheduleEntryInfo
  }
  saturday {
    ...ScheduleEntryInfo
  }
}
    ${KeyValueInfoFragmentDoc}
${ScheduleEntryInfoFragmentDoc}`;