import * as TP from '../types';

import { gql } from '@apollo/client';
export type CustomerPickerItemV2Fragment = (
  { readonly __typename?: 'CustomerV2' }
  & Pick<TP.CustomerV2, 'code' | 'id' | 'name' | 'currencyId'>
  & { readonly mainAddress?: TP.Maybe<(
    { readonly __typename?: 'CustomerAddress' }
    & Pick<TP.CustomerAddress, 'id' | 'city' | 'state'>
  )>, readonly customerStatusType?: TP.Maybe<(
    { readonly __typename?: 'KeyValue' }
    & Pick<TP.KeyValue, 'id' | 'name'>
  )> }
);

export const CustomerPickerItemV2FragmentDoc = gql`
    fragment CustomerPickerItemV2 on CustomerV2 {
  code
  id
  name
  mainAddress {
    id
    city
    state
  }
  customerStatusType {
    id
    name
  }
  currencyId
}
    `;