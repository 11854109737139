import * as TP from '../subscriptionTypes';

import { gql } from '@apollo/client';
export type RateLineItemEventPayloadFragment = (
  { readonly __typename?: 'RateLineItemEvent' }
  & Pick<TP.RateLineItemEvent, 'id' | 'action' | 'rateLineItemId'>
);

export const RateLineItemEventPayloadFragmentDoc = gql`
    fragment RateLineItemEventPayload on RateLineItemEvent {
  id
  action
  rateLineItemId
}
    `;