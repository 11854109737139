import { Loading } from '@components/Loading';
import { FC, lazy, StrictMode, Suspense } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { SaveProvider } from '../../components/SaveContext';
import { Theme } from '../../components/Theme';
import { GlobalVariables } from '../GlobalVariables';
import { AppStyles } from '../Styles';

const LazyView = lazy(() =>
  import('../../views/EmailTendering').then((module) => ({
    default: module.EmailTendering,
  }))
);

export const EmailTenderingApp: FC = () => {
  return (
    <StrictMode>
      <GlobalVariables />
      <SaveProvider>
        <Theme>
          <AppStyles />
          <Suspense fallback={<Loading />}>
            <LazyView />
          </Suspense>
        </Theme>
      </SaveProvider>
    </StrictMode>
  );
};
