// eslint-disable-next-line no-restricted-imports
import { Theme, useTheme as useEmotionTheme } from '@emotion/react';
import { MODE } from '@env';
import { PRIVATE_themeObj } from '../../components/Theme';

export function useTheme(): Theme {
  const theme = useEmotionTheme();
  // for jest
  if (MODE === 'test') {
    return PRIVATE_themeObj;
  }
  return theme;
}

export type NamedColorOrGrayValue =
  | keyof typeof PRIVATE_themeObj.colors
  | (typeof PRIVATE_themeObj.gray)[keyof typeof PRIVATE_themeObj.gray]
  | 'inherit';

/** An internal utility, this should not be used in view code. */
export const resolveThemeValueFromNamedColor = (
  theme: Theme,
  s: NamedColorOrGrayValue
): string | undefined => {
  return theme.colors[s as keyof typeof theme.colors] || s;
};
