import { useState } from 'react';
import { useEffectOnce } from 'react-use';

export function useLazyImport<Lib>(load: () => Promise<Lib>): Lib | null {
  const [lib, setLib] = useState<Lib | null>(null);
  useEffectOnce(() => {
    load().then((lib) => {
      setLib(lib);
    });
  });
  return lib;
}
