import * as TP from '../types';

import { gql } from '@apollo/client';
import { KeyValueInfoFragmentDoc } from './keyValueInfo';
import { EmployeeSimpleV2FragmentDoc } from './employeeSimpleV2';
export type EmployeeInfoV2Fragment = (
  { readonly __typename?: 'EmployeeV2' }
  & Pick<TP.EmployeeV2, 'id' | 'businessUnitId' | 'companyEmail' | 'divisionId' | 'email' | 'emergencyContact' | 'emergencyPhone' | 'employeeCompanyLevelId' | 'employeeDepartmentId' | 'employeeDepartmentLevelId' | 'employeeDisplayName' | 'employeeGenderId' | 'employeeGroupId' | 'employeeId' | 'employeeOfficeId' | 'employeeRoleId' | 'employeeShirtSizeId' | 'employeeSuffixId' | 'employeeTimeId' | 'employeeTypeId' | 'ext' | 'firstName' | 'firstNameDotLastName' | 'fullName' | 'hireDate' | 'lastName' | 'managerId' | 'middleName' | 'mobilePhone' | 'nickname' | 'personalEmail' | 'phoneNumber' | 'projectId' | 'relationship' | 'slackName' | 'subRegion' | 'termDate' | 'title' | 'userId'>
  & { readonly businessUnit?: TP.Maybe<(
    { readonly __typename?: 'KeyValue' }
    & Pick<TP.KeyValue, 'id' | 'name' | 'active'>
  )>, readonly division?: TP.Maybe<(
    { readonly __typename?: 'KeyValue' }
    & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
  )>, readonly employeeDivisions?: TP.Maybe<ReadonlyArray<(
    { readonly __typename?: 'KeyValue' }
    & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
  )>>, readonly employeeAdditionalDivisions?: TP.Maybe<ReadonlyArray<TP.Maybe<(
    { readonly __typename?: 'EmployeeAdditionalDivisions' }
    & Pick<TP.EmployeeAdditionalDivisions, 'id' | 'additionalDivisionId' | 'employeeId'>
  )>>>, readonly employeeGroup?: TP.Maybe<(
    { readonly __typename?: 'KeyValue' }
    & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
  )>, readonly employeeOffice?: TP.Maybe<(
    { readonly __typename?: 'KeyValue' }
    & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
  )>, readonly employeeStatus: (
    { readonly __typename?: 'KeyValue' }
    & Pick<TP.KeyValue, 'id' | 'active'>
  ), readonly manager?: TP.Maybe<(
    { readonly __typename?: 'EmployeeV2' }
    & Pick<TP.EmployeeV2, 'companyEmail' | 'email' | 'emergencyContact' | 'emergencyPhone' | 'employeeDepartmentId' | 'employeeDisplayName' | 'employeeGenderId' | 'employeeGroupId' | 'employeeId' | 'employeeOfficeId' | 'employeeSuffixId' | 'ext' | 'firstName' | 'fullName' | 'id' | 'lastName' | 'middleName' | 'nickname' | 'personalEmail' | 'phoneNumber' | 'relationship' | 'title' | 'userId' | 'managerId'>
  )>, readonly project?: TP.Maybe<(
    { readonly __typename?: 'KeyValue' }
    & Pick<TP.KeyValue, 'id' | 'name' | 'active'>
  )>, readonly employeeProjects?: TP.Maybe<ReadonlyArray<TP.Maybe<(
    { readonly __typename?: 'EmployeeProject' }
    & Pick<TP.EmployeeProject, 'id'>
    & { readonly employeeProject?: TP.Maybe<(
      { readonly __typename?: 'Project' }
      & Pick<TP.Project, 'id' | 'name'>
    )> }
  )>>> }
);

export type EmployeeAdditionalDivisionInfoFragment = (
  { readonly __typename?: 'EmployeeAdditionalDivisions' }
  & Pick<TP.EmployeeAdditionalDivisions, 'id' | 'additionalDivisionId' | 'employeeId'>
);

export const EmployeeAdditionalDivisionInfoFragmentDoc = gql`
    fragment EmployeeAdditionalDivisionInfo on EmployeeAdditionalDivisions {
  id
  additionalDivisionId
  employeeId
}
    `;
export const EmployeeInfoV2FragmentDoc = gql`
    fragment EmployeeInfoV2 on EmployeeV2 {
  id
  businessUnit {
    id
    name
    active
  }
  businessUnitId
  companyEmail
  division {
    ...KeyValueInfo
  }
  divisionId
  employeeDivisions {
    ...KeyValueInfo
  }
  employeeAdditionalDivisions {
    ...EmployeeAdditionalDivisionInfo
  }
  email
  emergencyContact
  emergencyPhone
  employeeCompanyLevelId
  employeeDepartmentId
  employeeDepartmentLevelId
  employeeDisplayName
  employeeGenderId
  employeeGroupId
  employeeGroup {
    ...KeyValueInfo
  }
  employeeId
  employeeOfficeId
  employeeOffice {
    ...KeyValueInfo
  }
  employeeRoleId
  employeeShirtSizeId
  employeeStatus {
    id
    active
  }
  employeeSuffixId
  employeeTimeId
  employeeTypeId
  ext
  firstName
  firstNameDotLastName
  fullName
  hireDate
  lastName
  managerId
  manager {
    ...EmployeeSimpleV2
  }
  middleName
  mobilePhone
  nickname
  personalEmail
  phoneNumber
  project {
    id
    name
    active
  }
  projectId
  employeeProjects {
    id
    employeeProject {
      id
      name
    }
  }
  relationship
  slackName
  subRegion
  termDate
  title
  userId
}
    ${KeyValueInfoFragmentDoc}
${EmployeeAdditionalDivisionInfoFragmentDoc}
${EmployeeSimpleV2FragmentDoc}`;