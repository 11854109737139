import { CARD_TITLE_FONT_SIZE } from '@components/Card';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useTheme } from '@hooks/useTheme';
import { FC } from 'react';

export const ModalHeader = styled.div`
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 16px;
`;

/** @deprecated Use Text component */
export const DeprecatedErrorText: FC = ({ children, ...rest }) => {
  const { colors } = useTheme();
  return (
    <span
      css={{ display: 'block', color: colors.error, fontSize: '12.8px' }}
      {...rest}
    >
      {children}
    </span>
  );
};

export const Title = styled.div`
  font-weight: 500;
  font-size: ${CARD_TITLE_FONT_SIZE}px;
`;

export const TruncatedText = styled.span`
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
`;

export const truncatedHeader = css({
  width: '100%',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  lineHeight: '1.5em',
});
