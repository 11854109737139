/* eslint-disable mastery/named-colors */
const newBaseColors = {
  blue: {
    99: '#FCFDFF',
    98: '#F5F7FF',
    95: '#EDF0FF',
    90: '#D8E2FF',
    80: '#ADC6FF',
    70: '#80ABFF',
    60: '#4B8EFF',
    50: '#1E73E8',
    40: '#1662C5',
    30: '#004493',
    20: '#002E69',
    10: '#001A41',
  },
  green: {
    99: '#F4FFF5',
    97: '#DCFFEB',
    95: '#CCFFE1',
    90: '#B0EFD0',
    80: '#65DCA4',
    70: '#46C08A',
    60: '#20A471',
    50: '#00885B',
    40: '#006C47',
    30: '#005235',
    20: '#003823',
    10: '#002113',
  },
  cyan: {
    99: '#ECFBFF',
    97: '#F8FDFF',
    95: '#D8F6FF',
    90: '#ABEDFF',
    80: '#55D6F4',
    70: '#2DBAD7',
    60: '#009FB9',
    50: '#008399',
    40: '#00687A',
    30: '#004E5C',
    20: '#003640',
    10: '#001F26',
  },
  violet: {
    99: '#FEFBFF',
    97: '#FDF4FF',
    95: '#FCEBFF',
    90: '#F5D9FF',
    80: '#E7B4FF',
    70: '#D78DFF',
    60: '#C762FF',
    50: '#AE3EEB',
    40: '#9216D0',
    30: '#7100A5',
    20: '#500076',
    10: '#30004A',
  },
  yellow: {
    99: '#FFFDF7',
    97: '#FFF6E1',
    95: '#FFECC2',
    90: '#FFD885',
    80: '#FFCB5C',
    70: '#F5A700',
    60: '#C38400',
    50: '#A16C00',
    40: '#815600',
    30: '#614000',
    20: '#442B00',
    10: '#291800',
  },
  red: {
    99: '#FFFBFF',
    97: '#FFF3F5',
    95: '#FEE7EB',
    90: '#FFDAD5',
    80: '#FFB4AB',
    70: '#FF897D',
    60: '#FB6156',
    50: '#DE372F',
    40: '#BA1A1A',
    30: '#930009',
    20: '#690004',
    10: '#410002',
  },
  gray: {
    white: '#FFFFFF',
    99: '#FCFCFF',
    95: '#F2F0F4',
    90: '#E3E2E6',
    80: '#C7C6CA',
    70: '#ABABAF',
    60: '#919094',
    50: '#77777A',
    40: '#5E5E62',
    30: '#46464A',
    20: '#303033',
    10: '#1B1B1F',
  },
};

export const semanticMap = {
  primary: newBaseColors.blue,
  success: newBaseColors.green,
  warning: newBaseColors.yellow,
  error: newBaseColors.red,
  neutral: newBaseColors.gray,
  cyan: newBaseColors.cyan,
  violet: newBaseColors.violet,
};

export const gray = newBaseColors.gray;
