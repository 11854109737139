import * as TP from '../types';

import { gql } from '@apollo/client';
export type EmployeeSimpleV2Fragment = (
  { readonly __typename?: 'EmployeeV2' }
  & Pick<TP.EmployeeV2, 'companyEmail' | 'email' | 'emergencyContact' | 'emergencyPhone' | 'employeeDepartmentId' | 'employeeDisplayName' | 'employeeGenderId' | 'employeeGroupId' | 'employeeId' | 'employeeOfficeId' | 'employeeSuffixId' | 'ext' | 'firstName' | 'fullName' | 'id' | 'lastName' | 'middleName' | 'nickname' | 'personalEmail' | 'phoneNumber' | 'relationship' | 'title' | 'userId' | 'managerId'>
);

export const EmployeeSimpleV2FragmentDoc = gql`
    fragment EmployeeSimpleV2 on EmployeeV2 {
  companyEmail
  email
  emergencyContact
  emergencyPhone
  employeeDepartmentId
  employeeDisplayName
  employeeGenderId
  employeeGroupId
  employeeId
  employeeOfficeId
  employeeSuffixId
  ext
  firstName
  fullName
  id
  lastName
  middleName
  nickname
  personalEmail
  phoneNumber
  relationship
  title
  userId
  managerId
}
    `;