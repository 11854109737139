import * as TP from '../subscriptionTypes';

import { gql } from '@apollo/client';
export type TenderPlanRouteEventPayloadFragment = (
  { readonly __typename?: 'TenderPlanRouteEvent' }
  & Pick<TP.TenderPlanRouteEvent, 'id' | 'type'>
);

export const TenderPlanRouteEventPayloadFragmentDoc = gql`
    fragment TenderPlanRouteEventPayload on TenderPlanRouteEvent {
  id
  type
}
    `;