import * as TP from '../subscriptionTypes';

import { gql } from '@apollo/client';
export type RouteStatusEventPayloadFragment = (
  { readonly __typename?: 'RouteStatusEvent' }
  & Pick<TP.RouteStatusEvent, 'id' | 'type' | 'capacityNeed' | 'routeLifecycleStatus' | 'routeActivationStatus'>
);

export const RouteStatusEventPayloadFragmentDoc = gql`
    fragment RouteStatusEventPayload on RouteStatusEvent {
  id
  type
  capacityNeed
  routeLifecycleStatus
  routeActivationStatus
}
    `;