import { IconBg, IconProp } from '@components/Icon';
import ReactDOMServer from 'react-dom/server';

const iconToBase64String = (iconKey: IconProp): string => {
  const elementAsString = ReactDOMServer.renderToStaticMarkup(
    <IconBg i={iconKey} />
  );
  return btoa(elementAsString);
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const iconBgImg = (icon: IconProp, paddingRight = 4): string => {
  const width = 10.5;
  const gradientStop = width + 1 + paddingRight;
  return `linear-gradient(to left, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.8) ${gradientStop}px, rgba(255,255,255,1) ${gradientStop}px, rgba(255,255,255,1) 100%),url("data:image/svg+xml;base64,${iconToBase64String(
    icon
  )}") no-repeat right ${paddingRight}px center /${width}px 12px`;
};

/** Statically generated from iconBgImg('clock', 5). Better for perf to compute outside of runtime. */
export const clock5BgImg = `linear-gradient(to left, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.8) 16.5px, rgba(255,255,255,1) 16.5px, rgba(255,255,255,1) 100%),url("data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhbCIgZGF0YS1pY29uPSJjbG9jayIgY2xhc3M9InN2Zy1pbmxpbmUtLWZhIGZhLWNsb2NrIGNzcy05YzdyNTgiIHJvbGU9ImltZyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+PHBhdGggZmlsbD0iY3VycmVudENvbG9yIiBkPSJNMjQwIDExMkMyNDAgMTAzLjIgMjQ3LjIgOTYgMjU2IDk2QzI2NC44IDk2IDI3MiAxMDMuMiAyNzIgMTEyVjI0Ny40TDM2MC45IDMwNi43QzM2OC4yIDMxMS42IDM3MC4yIDMyMS41IDM2NS4zIDMyOC45QzM2MC40IDMzNi4yIDM1MC41IDMzOC4yIDM0My4xIDMzMy4zTDI0Ny4xIDI2OS4zQzI0Mi43IDI2Ni4zIDIzOS4xIDI2MS4zIDIzOS4xIDI1NkwyNDAgMTEyek0yNTYgMEMzOTcuNCAwIDUxMiAxMTQuNiA1MTIgMjU2QzUxMiAzOTcuNCAzOTcuNCA1MTIgMjU2IDUxMkMxMTQuNiA1MTIgMCAzOTcuNCAwIDI1NkMwIDExNC42IDExNC42IDAgMjU2IDB6TTMyIDI1NkMzMiAzNzkuNyAxMzIuMyA0ODAgMjU2IDQ4MEMzNzkuNyA0ODAgNDgwIDM3OS43IDQ4MCAyNTZDNDgwIDEzMi4zIDM3OS43IDMyIDI1NiAzMkMxMzIuMyAzMiAzMiAxMzIuMyAzMiAyNTZ6Ij48L3BhdGg+PC9zdmc+") no-repeat right 5px center /10.5px 12px`;

/** Statically generated from iconBgImg('calendar', 6). Better for perf to compute outside of runtime. */
export const calendar6BgImg = `linear-gradient(to left, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.8) 17.5px, rgba(255,255,255,1) 17.5px, rgba(255,255,255,1) 100%),url("data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhbCIgZGF0YS1pY29uPSJjYWxlbmRhci1kYXlzIiBjbGFzcz0ic3ZnLWlubGluZS0tZmEgZmEtY2FsZW5kYXItZGF5cyBjc3MtOWM3cjU4IiByb2xlPSJpbWciIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDQ0OCA1MTIiPjxwYXRoIGZpbGw9ImN1cnJlbnRDb2xvciIgZD0iTTExMiAwQzEyMC44IDAgMTI4IDcuMTY0IDEyOCAxNlY2NEgzMjBWMTZDMzIwIDcuMTY0IDMyNy4yIDAgMzM2IDBDMzQ0LjggMCAzNTIgNy4xNjQgMzUyIDE2VjY0SDM4NEM0MTkuMyA2NCA0NDggOTIuNjUgNDQ4IDEyOFY0NDhDNDQ4IDQ4My4zIDQxOS4zIDUxMiAzODQgNTEySDY0QzI4LjY1IDUxMiAwIDQ4My4zIDAgNDQ4VjEyOEMwIDkyLjY1IDI4LjY1IDY0IDY0IDY0SDk2VjE2Qzk2IDcuMTY0IDEwMy4yIDAgMTEyIDB6TTQxNiAxOTJIMzEyVjI2NEg0MTZWMTkyek00MTYgMjk2SDMxMlYzNzZINDE2VjI5NnpNNDE2IDQwOEgzMTJWNDgwSDM4NEM0MDEuNyA0ODAgNDE2IDQ2NS43IDQxNiA0NDhWNDA4ek0yODAgMzc2VjI5NkgxNjhWMzc2SDI4MHpNMTY4IDQ4MEgyODBWNDA4SDE2OFY0ODB6TTEzNiAzNzZWMjk2SDMyVjM3NkgxMzZ6TTMyIDQwOFY0NDhDMzIgNDY1LjcgNDYuMzMgNDgwIDY0IDQ4MEgxMzZWNDA4SDMyek0zMiAyNjRIMTM2VjE5MkgzMlYyNjR6TTE2OCAyNjRIMjgwVjE5MkgxNjhWMjY0ek0zODQgOTZINjRDNDYuMzMgOTYgMzIgMTEwLjMgMzIgMTI4VjE2MEg0MTZWMTI4QzQxNiAxMTAuMyA0MDEuNyA5NiAzODQgOTZ6Ij48L3BhdGg+PC9zdmc+") no-repeat right 6px center /10.5px 12px`;
