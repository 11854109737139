import * as TP from '../subscriptionTypes';

import { gql } from '@apollo/client';
export type CommissionsRecalculatedEventPayloadFragment = (
  { readonly __typename?: 'CommissionsRecalculatedEvent' }
  & Pick<TP.CommissionsRecalculatedEvent, 'id' | 'loadId' | 'type'>
);

export const CommissionsRecalculatedEventPayloadFragmentDoc = gql`
    fragment CommissionsRecalculatedEventPayload on CommissionsRecalculatedEvent {
  id
  loadId
  type
}
    `;