import { IdSlash, NoAuthRoute } from '@app/routes';
import { keys } from '@utils/keys';
import { win } from '@utils/win';

export const AUTH_BYPASS_ROUTES_MAP: Record<
  NoAuthRoute<IdSlash>,
  NoAuthRoute<IdSlash>
> = {
  '/__debug': '/__debug',
  '/__debug/:id/': '/__debug/:id/',
} as const;

const AUTH_BYPASS_ROUTES = keys(AUTH_BYPASS_ROUTES_MAP);

export const routeCanBypassAuth = (): boolean =>
  AUTH_BYPASS_ROUTES.includes(win.location.pathname || ('' as fixMe));
