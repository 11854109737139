import { useEffectAfterMount } from '@hooks/useEffectAfterMount';
import { isUndefined } from 'lodash-es';
import { Dispatch, SetStateAction, useState } from 'react';

/** Utility hook to support components that can be in uncontrolled or controlled state modes. If memoizedProp is undefined, the component is in uncontrolled mode. */
export const usePropState = <T extends unknown>(
  memoizedProp: T,
  initialState: T | (() => T)
): [T, Dispatch<SetStateAction<T>>] => {
  const [state, setState] = useState<T>(
    isUndefined(memoizedProp) ? initialState : memoizedProp
  );
  useEffectAfterMount(() => {
    setState(() => memoizedProp);
  }, [memoizedProp]);
  return [state, setState];
};
