import * as TP from '../types';

import { gql } from '@apollo/client';
export type NoteInfoFragment = (
  { readonly __typename?: 'Note' }
  & Pick<TP.Note, 'createdAt' | 'id' | 'noteType' | 'noteableId' | 'noteableType' | 'text' | 'updatedAt'>
);

export const NoteInfoFragmentDoc = gql`
    fragment NoteInfo on Note {
  createdAt
  id
  noteType
  noteableId
  noteableType
  text
  updatedAt
}
    `;