import { ParameterizedRoutePath } from '@app/routes';
import { Route, RouteProps } from '@components/Route';
import { MODE } from '@env';
import { IS_LOCAL_DEV } from '@utils/constants';
import { jsonStringify } from '@utils/json';
import { reportCustomSentryError } from '@utils/sentry';
import { last } from 'lodash-es';
import { ComponentType, FC, ReactNode, Suspense, lazy, memo } from 'react';
import { Redirect, Switch } from 'react-router-dom';

export type LoadReturn = Promise<anyOk>;

type PathImport = () => Promise<anyOk>;

const NoImport: FC = () => {
  if (MODE === 'production') {
    return (
      <div>
        Something went wrong trying to load this page. You should report this to
        the Mastery team.
      </div>
    );
  }
  return (
    <div data-testid="export-not-found">
      Export not found. You tried to lazy-load a route but no exports from the
      file could be found. Lazy will either load an export called{' '}
      <code>View</code> or the last export it finds.
    </div>
  );
};

interface ImportFile extends Record<string, ComponentType | undefined> {
  View?: ComponentType;
}

const createLazyComponent = (
  loadImportFn: () => Promise<ImportFile>
): ComponentType =>
  lazy(() =>
    loadImportFn()
      .then((comp) => {
        // we assume that each view has an export called "View"
        // we fallback to the last export we find
        // if there is more than one export, routes might not load correctly ymmv
        return {
          default: comp.View || last(Object.values(comp)) || NoImport,
        };
      })
      .catch((err) => {
        reportCustomSentryError(err);
        if (IS_LOCAL_DEV) {
          /* eslint-disable no-console */
          console.error(err);
        }
        return { default: NoImport };
      })
  );

export interface LazyRoutesArrObj extends RouteProps {
  load: PathImport;
}

/** Route paths are tracked in app/routes. You must add them there for TS to pass. */
export type LazyRoutesArr = LazyRoutesArrObj[];

interface Props {
  routes: LazyRoutesArr;
  /** If a route is not matched (ie user entered bad url), the route that we should redirect to. */
  fallbackRedirect: ParameterizedRoutePath;
}

const RoutesComp: FC<Props> = ({ routes, fallbackRedirect }) => {
  const fallbackRouteComp = fallbackRedirect ? (
    <Route
      path={'*' as anyOk}
      render={(): ReactNode => {
        return <Redirect to={fallbackRedirect ?? '/'} />;
      }}
    />
  ) : null;
  return (
    <Suspense fallback={null}>
      <Switch>
        {routes.map(({ load, ...params }) => {
          const component = createLazyComponent(load);
          return (
            <Route
              component={component}
              key={jsonStringify(params.path)}
              {...params}
            />
          );
        })}
        {fallbackRouteComp}
      </Switch>
    </Suspense>
  );
};

export const LazyRoutes = memo(RoutesComp);
