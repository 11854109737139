import * as TP from '../subscriptionTypes';

import { gql } from '@apollo/client';
export type PricingRateEventPayloadFragment = (
  { readonly __typename?: 'PricingRateEvent' }
  & Pick<TP.PricingRateEvent, 'id' | 'type'>
);

export const PricingRateEventPayloadFragmentDoc = gql`
    fragment PricingRateEventPayload on PricingRateEvent {
  id
  type
}
    `;