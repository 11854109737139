import { keyframes } from '@emotion/react';
import { useTheme } from '@hooks/useTheme';
import { FC, memo } from 'react';
import { useTimeout } from 'react-use';

const frames = keyframes({
  '0%': {
    top: '20%',
    height: '70%',
  },
  '50%, 100%': {
    top: '40%',
    height: '35%',
  },
});

// ts-unused-exports:disable-next-line
export const LOADING_DEFAULT_DELAY = 250;

const Bar: FC<{
  className?: string;
}> = (props) => {
  const { gray } = useTheme();
  return (
    <div
      css={{
        animation: `${frames} 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite`,
        background: gray[10],
        display: 'inline-block',
        left: '10%',
        position: 'absolute',
        width: '20%',
      }}
      className={props.className}
    />
  );
};

interface Props {
  /** Amount to wait before showing the loader */
  delay?: number;
  size?: 'small' | 'default';
}

export const DEFAULT_LOADING_WIDTH = 64;

const widths = {
  small: 30,
  default: DEFAULT_LOADING_WIDTH,
};

export const Loading: FC<Props> = memo(
  ({ delay = LOADING_DEFAULT_DELAY, size = 'default', ...rest }) => {
    const [isReady] = useTimeout(delay || 0);
    if (!isReady()) {
      return null;
    }
    return (
      <div
        data-testid="component-loading"
        css={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        {...rest}
      >
        <div
          css={{
            width: widths[size],
            height: widths[size],
            position: 'relative',
          }}
        >
          <Bar css={{ left: '10%', animationDelay: '-0.24s' }} />
          <Bar css={{ left: '40%', animationDelay: '-0.12s' }} />
          <Bar css={{ left: '70%', animationDelay: '0s' }} />
        </div>
      </div>
    );
  }
);
