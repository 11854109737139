// strips phonenumber input and checks for 7 to 10 numbers to search as a phone number
export const searchPhoneNumberMatch = (input: string): string | undefined => {
  if (input.match(/[a-zA-Z]/gm)) {
    return input;
  }
  const parsed = input
    // eslint-disable-next-line
    .replace(/[\s\.\-\(\)]/gm, '')
    .match(/^(\+\d{0,3})?\d{7,10}/gm);

  return parsed ? parsed[0] : input;
};
