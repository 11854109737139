import { TreeNode } from '@components/TreePicker';
import { EmployeeInfoV2Fragment } from '@generated/fragments/employeeInfoV2';
import { useWhoAmIv2Query } from '@generated/queries/whoAmIV2';
import { ReactElement, useContext, useEffect, useState } from 'react';
import {
  EmployeeTreeNodeValue,
  GroupTreeNodeValue,
  SidebarContext,
} from '../util';
import { GroupTreePicker } from './groupTreePicker';

interface Props {
  updateEmployeeTreeNodeValue: (emp: EmployeeTreeNodeValue) => void;
}
export const GroupTree = ({
  updateEmployeeTreeNodeValue,
}: Props): ReactElement => {
  const [noGroups, setNoGroups] = useState(true);
  const { data: loggedInUserV2 } = useWhoAmIv2Query();
  const [currentEmployee] = useState(loggedInUserV2?.whoAmIV2?.employee);
  const [groupTreeNodes, setGroupTreeNodes] = useState<
    TreeNode<GroupTreeNodeValue>[]
  >([]);

  const { group } = useContext(SidebarContext);

  useEffect(() => {
    setNoGroups(true);
    if (group) {
      setGroupTreeNodes([getGroupTreeNodes(group.id, group.groupName)]);
    }
  }, [group]);

  useEffect(() => {
    if (groupTreeNodes.length) {
      setNoGroups(false);
    }
  }, [groupTreeNodes]);

  const getGroupTreeNodes = (
    groupId: string,
    groupName: string
  ): TreeNode<GroupTreeNodeValue> => {
    return {
      id: groupId || '',
      label: groupName,
      value: {
        id: groupId || '',
        groupName: groupName || '',
      },
      isChecked: false,
      isExpanded: false,
      children: undefined,
    };
  };

  return (
    <div style={{ width: '98%' }}>
      {noGroups ? (
        <div>No Group found</div>
      ) : (
        <GroupTreePicker
          currentEmployee={currentEmployee as EmployeeInfoV2Fragment}
          groupTreeNodes={groupTreeNodes}
          updateEmployeeTreeNodeValue={updateEmployeeTreeNodeValue}
          group={group}
        />
      )}
    </div>
  );
};
