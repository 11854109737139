import { FS_UNMASK } from '@utils/fullstory';
import cx from 'classnames';
import { FC, HTMLProps } from 'react';

export const LABEL_MIN_HEIGHT = 16;

export const Label: FC<HTMLProps<HTMLLabelElement>> = (props) => (
  <label
    css={{
      padding: 0,
      cursor: 'pointer',
      width: '100%',
      minHeight: LABEL_MIN_HEIGHT,
    }}
    {...props}
    className={cx(FS_UNMASK, props.className)}
  />
);
