import * as TP from '../subscriptionTypes';

import { gql } from '@apollo/client';
export type TruckPostingMatchEventPayloadFragment = (
  { readonly __typename?: 'TruckPostingMatchEvent' }
  & Pick<TP.TruckPostingMatchEvent, 'id' | 'type'>
);

export const TruckPostingMatchEventPayloadFragmentDoc = gql`
    fragment TruckPostingMatchEventPayload on TruckPostingMatchEvent {
  id
  type
}
    `;