import { fetchFromGraphInternalDevOnly } from '@utils/fetchHttp';
import { jsonStringify } from '@utils/json';
import { Machine, assign } from 'xstate';

interface Message {
  role: 'user' | 'assistant' | 'tool';
  type?: 'listening';
  data?: anyOk;
  content: string;
  // Underlying prompt. Use content for what you want to show in the chat.
  prompt?: string;
  loading?: boolean;
  source?: string;
}

export type ChatEvent =
  | { type: 'SEND_MESSAGE'; message: Message }
  | { type: 'NEW_MESSAGE'; message: Message }
  | { type: 'LOADING' }
  | { type: 'INPUT_CHANGE'; data: string }
  | { type: 'VOICE_INPUT_START' }
  | { type: 'VOICE_INPUT_END'; data: string };

export interface ChatContext {
  messages: Message[];
  inputValue?: string | null;
}

const LISTENING = 'Listening...';
const LOADING_CONTENT = 'Loading...';
const INITIAL_MESSAGE =
  "Hello! I'm The Assistant, here to answer any questions you have about MasterMind. How can I help?";
interface ChatState {
  states: {
    idle: Record<string, unknown>;
    loading: {
      states: {
        thinking: Record<string, unknown>;
      };
    };
    userSpeaking: Record<string, unknown>;
  };
}

const chatWithMastermindResourceHub = async (kwargs: {
  context: ChatContext;
  loadingCallback: (m: Message) => void;
}): Promise<void> => {
  const { context, loadingCallback } = kwargs;
  const { messages } = context;
  const filteredMessages = messages.filter(
    (message) =>
      message?.loading !== true &&
      message.content !== INITIAL_MESSAGE &&
      message?.content !== LISTENING
  );
  const chatMessageHistoryItems = filteredMessages.slice(0, -1).map((m) => ({
    role: m.role,
    content: m.content,
  }));

  const chatMessage =
    filteredMessages[filteredMessages.length - 1]?.content || '';

  const query = `query MmaiGetDocsChatResponseV1($request: MMaiDocsChatResponseInputV1!) {
    mmaiGetDocsChatResponseV1(request: $request) {
      id
      responseText
      source
    }
  }`;

  const variables = {
    request: {
      text: chatMessage,
      history: chatMessageHistoryItems,
    },
  };
  const q = jsonStringify({
    query: query,
    variables: variables,
  });
  fetchFromGraphInternalDevOnly({
    body: q,
    qs: 'q=mmaiDocsChat',
  }).then(async (res) => {
    const json = await res.json();
    const llmResponse =
      json.data.mmaiGetDocsChatResponseV1?.responseText ||
      'Something went wrong.';
    const sources = json.data.mmaiGetDocsChatResponseV1.source ?? [];
    loadingCallback({
      role: 'assistant',
      content: llmResponse,
      source: sources,
    });
  });
};

export const chatMachine = Machine<ChatContext, ChatState, ChatEvent>({
  id: 'chat',
  initial: 'idle',
  context: {
    messages: [
      {
        role: 'assistant',
        content: INITIAL_MESSAGE,
      },
    ],
    inputValue: '',
  },
  on: {
    INPUT_CHANGE: {
      actions: assign((context, event) => {
        return {
          inputValue: event?.data ?? '',
        };
      }),
    },
    SEND_MESSAGE: {
      actions: assign((context, event) => {
        return {
          ...context,
          inputValue: '',
          messages: [
            ...context.messages,
            event.message || { role: 'user', content: '' },
            { role: 'assistant', content: LOADING_CONTENT, loading: true },
          ],
        };
      }),
      target: 'loading',
    },
  },
  states: {
    idle: {
      on: {
        VOICE_INPUT_START: {
          target: 'userSpeaking',
        },
      },
    },
    loading: {
      initial: 'thinking',
      states: {
        thinking: {
          invoke: {
            src: (context) => {
              return (callback): void => {
                chatWithMastermindResourceHub({
                  context,
                  loadingCallback: (s) => {
                    callback({
                      type: 'NEW_MESSAGE',
                      message: {
                        role: 'assistant',
                        content: s.content,
                        source: s.source,
                      },
                    });
                  },
                });
              };
            },
          },
          on: {
            NEW_MESSAGE: {
              actions: assign({
                messages: (context, event) => {
                  return [...context.messages, event.message]?.filter(
                    (messages) => messages.loading !== true
                  );
                },
              }),
            },
          },
        },
      },
    },
    userSpeaking: {
      entry: assign((context) => ({
        ...context,
        messages: [
          ...context.messages,
          { role: 'assistant', content: 'Listening...', type: 'listening' },
        ],
      })),
      on: {
        VOICE_INPUT_END: {
          actions: assign((context, event) => {
            const messages = context.messages.filter(
              (m) => m.type !== 'listening'
            );
            const data = event.data;
            if (!data) {
              return {
                ...context,
                inputValue: '',
                messages,
              };
            }
            return {
              ...context,
              inputValue: context.inputValue + ' ' + data,
              messages,
            };
          }),
          target: 'idle',
        },
        SEND_MESSAGE: {
          actions: assign((context, event) => {
            return {
              ...context,
              inputValue: '',
              messages: [
                ...context.messages,
                { role: 'user', content: event.message?.content || '' },
              ],
            };
          }),
          target: 'loading',
        },
      },
    },
  },
});
