import { useRef } from 'react';
import { useEffectOnce } from 'react-use';

interface Props<T extends unknown> {
  id: string;
  onMessage?: (message: T) => void;
  sendMessage?: (message: T) => void;
}

// ts-unused-exports:disable-next-line
export const useBroadcastChannel = <T extends unknown>(
  kwargs: Props<T>
): { postMessage: (msg: T) => void } => {
  const channelRef = useRef<BroadcastChannel>();
  useEffectOnce(() => {
    const channel = new BroadcastChannel(kwargs.id);
    channelRef.current = channel;
    channel.addEventListener('message', (event) =>
      kwargs.onMessage?.(event.data)
    );
  });
  const postMessage = (msg: T): void => channelRef.current?.postMessage(msg);
  return { postMessage };
};
