import * as TP from '../subscriptionTypes';

import { gql } from '@apollo/client';
export type ToastEventPayloadFragment = (
  { readonly __typename?: 'ToastEvent' }
  & Pick<TP.ToastEvent, 'id' | 'toastType' | 'message'>
);

export const ToastEventPayloadFragmentDoc = gql`
    fragment ToastEventPayload on ToastEvent {
  id
  toastType
  message
}
    `;