import * as TP from '../subscriptionTypes';

import { gql } from '@apollo/client';
export type RouteLockingEventPayloadFragment = (
  { readonly __typename?: 'RouteLockingEvent' }
  & Pick<TP.RouteLockingEvent, 'id' | 'type' | 'expirationTimestamp' | 'userIdentity' | 'adminIdentity' | 'lockEventType'>
);

export const RouteLockingEventPayloadFragmentDoc = gql`
    fragment RouteLockingEventPayload on RouteLockingEvent {
  id
  type
  expirationTimestamp
  userIdentity
  adminIdentity
  lockEventType
}
    `;