import * as TP from '../subscriptionTypes';

import { gql } from '@apollo/client';
export type TrackingUpdateEventPayloadFragment = (
  { readonly __typename?: 'TrackingUpdateEvent' }
  & Pick<TP.TrackingUpdateEvent, 'id' | 'trackingUpdateId' | 'trackingUpdateEventType' | 'type'>
);

export const TrackingUpdateEventPayloadFragmentDoc = gql`
    fragment TrackingUpdateEventPayload on TrackingUpdateEvent {
  id
  trackingUpdateId
  trackingUpdateEventType
  type
}
    `;