import { ZonedDateTime } from '@internationalized/date';
import { win } from '@utils/win';

export const CURRENT_LOCALE: string = win.navigator.language;

export const startOfDay = (zoned: ZonedDateTime): ZonedDateTime => {
  return zoned.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
};

export const endOfDay = (zoned: ZonedDateTime): ZonedDateTime => {
  return zoned.set({ hour: 23, minute: 59, second: 59, millisecond: 999 });
};

export const addYearsToZoned = (
  zoned: ZonedDateTime,
  years: number
): ZonedDateTime => {
  return zoned.set({ year: zoned.year + years });
};
