import * as TP from '../subscriptionTypes';

import { gql } from '@apollo/client';
export type TrackingStatusEventPayloadFragment = (
  { readonly __typename?: 'TrackingStatusEvent' }
  & Pick<TP.TrackingStatusEvent, 'id' | 'trackingStatus'>
);

export const TrackingStatusEventPayloadFragmentDoc = gql`
    fragment TrackingStatusEventPayload on TrackingStatusEvent {
  id
  trackingStatus
}
    `;