export const INPUT_HEIGHT = 32;
export const BODY_FONT_SIZE = 12;

export const MAIN_FONT = 'Roboto, sans-serif';
/** Only used for Adobe Spectrum components */
export const ADOBE_FONT =
  'adobe-clean-ux,adobe-clean,Source Sans Pro,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,sans-serif';
export const CODE_FONT = 'source-code-pro, monospace';
export const TABLE_ROW_MIN_HEIGHT = 32;
export const TABLE_ROW_BORDER_HEIGHT = 1;
