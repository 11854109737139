import { userDataFromStorage } from '@components/AuthContext';
import { slim } from '@generated/flags/slim';
import { BUILD_VERSION, IS_PR_PREVIEW, USE_LD } from '@utils/constants';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { FC, ReactNode } from 'react';

const FakeLDProvider: FC = ({ children }) => <>{children}</>;

const anonmyousUser = {
  anonymous: true,
  key: 'anonymous',
};

interface initLDProps {
  launchDarklyKey: string;
}

export const initLD = (
  props: initLDProps
): Promise<FC<{ children: ReactNode }>> => {
  /* istanbul ignore next: we do not use real LD in tests */
  if (USE_LD) {
    return asyncWithLDProvider({
      clientSideID: props.launchDarklyKey,
      context: {
        kind: 'user',
        ...(userDataFromStorage || anonmyousUser),
      },
      options: {
        bootstrap: 'localStorage',
        application: {
          id: 'mastermind-frontend',
          version: BUILD_VERSION ?? IS_PR_PREVIEW ? 'pull' : 'unknown',
        },
      },
      reactOptions: {
        // https://docs.launchdarkly.com/docs/react-sdk-reference#section-flag-keys
        useCamelCaseFlagKeys: false,
      },
      flags: slim,
    });
  }
  return Promise.resolve(FakeLDProvider);
};
