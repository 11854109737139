import { CSSProperties, FC, ReactNode, memo } from 'react';

interface ExternalLinkProps {
  to: string;
  children: ReactNode;
  style?: CSSProperties;
}

const ExternalLinkComp: FC<ExternalLinkProps> = (props) => {
  const { to, children, style } = props;

  return (
    <a href={to} target="_blank" rel="noopener noreferrer" style={style}>
      {children}
    </a>
  );
};

export const ExternalLink = memo(ExternalLinkComp);
