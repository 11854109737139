import { EmployeeSimpleV2Fragment } from '@generated/fragments/employeeSimpleV2';
import { useSearchEmployeesV2Query } from '@generated/queries/searchEmployeesV2';
import { useTamedInputValue } from '@hooks/useTamedInputValue';
import { FC } from 'react';
import {
  AutoComplete,
  Props as AutoCompleteProps,
} from '../../../components/AutoComplete';

type Item = EmployeeSimpleV2Fragment;

type SortableField = keyof Omit<EmployeeSimpleV2Fragment, '__typename'>;

type Props = {
  sort?: { field: SortableField; direction: 'asc' | 'desc' };
} & Omit<AutoCompleteProps<Item>, 'items'>;

export const RepSearch: FC<Props & { includeAll?: boolean }> = ({
  includeAll,
  sort,
  ...props
}) => {
  const [searchText, setSearchText] = useTamedInputValue({
    initialValue: '',
  });

  const { loading: loading2, data: dataV2 } = useSearchEmployeesV2Query({
    errorPolicy: 'all',
    variables: {
      filter: {
        firstNameLastNameOrEmail: searchText,
        employeeStatus: includeAll ? null : 'Active',
        ...(sort ? { sort: `${sort.field} ${sort.direction}` } : {}),
      },
      first: 50,
    },
    skip: !setSearchText,
  });

  const employees: ReadonlyArray<Item> =
    dataV2?.allEmployeesV2?.edges.map(({ node }) => node) ?? [];
  const items = employees.map((employee) => ({
    value: employee,
    label: employee.fullName,
    id: employee.id,
  }));

  return (
    <AutoComplete<Item>
      onInputValueChange={setSearchText}
      loading={loading2}
      items={items}
      {...props}
    />
  );
};
