import * as electron from '@utils/electron';
import { history } from '@utils/history';
import { win } from '@utils/win';

const TAB_ID = Date.now().toString();

interface WindowData {
  currentWindows: Set<string>;
  main: string;
}

let windowData: WindowData;

electron.send('registerWindow', { tabId: TAB_ID });
electron.on('currentWindows', (e: fixMe, data: fixMe) => {
  windowData = data;
});

win.addEventListener('beforeunload', () => {
  electron.send('unregisterWindow', { tabId: TAB_ID });
});

interface Navigate {
  tabId: string;
  path: string;
}

electron.on('navigateMain', (e: fixMe, data: Navigate): void => {
  if (windowData.main === TAB_ID) {
    history.push(data.path);
  }
});

export const getWindowData = (): WindowData => windowData;

export const initWindowManager = (): void => {
  // noop
};
