/* eslint-disable no-restricted-imports */
/* eslint-disable mastery/known-imports */
/* eslint-disable mastery/no-fetch */
import { ApolloQueryResult } from '@apollo/client';
import { getExtraHeaders } from '@app/client';
import { Loading } from '@components/Loading';
import {
  HasPermissionContext,
  PermissionsContext,
  usePermissionsContext,
} from '@components/PermissionsContext';
import { WhoAmIv2Document, WhoAmIv2Query } from '@generated/queries/whoAmIV2';
import { useHasHappened } from '@hooks/useHasHappened';
import { USER_ID_LOCAL_STORAGE_KEY } from '@hooks/useUserId';
import {
  IS_CI,
  IS_CYPRESS,
  IS_CY_COMPONENT_TEST,
  IS_LOCAL_DEV,
  IS_UNIT_TEST,
  QUERY_PARAM_FLAGS_ENABLED,
  USE_LD,
} from '@utils/constants';
import { fromEntries } from '@utils/fromEntries';
import {
  FullstoryApprovedUserShape,
  getFullstoryUserDisplayName,
  isUsingDarkModeExtension,
  setUserVars,
} from '@utils/fullstory';
import { jsonParse } from '@utils/json';
import { getLocalStorage, setLocalStorage } from '@utils/localStorage';
import { HAS_WINDOW, win } from '@utils/win';
import { GraphQLClient } from 'graphql-request';
import { print } from 'graphql/language/printer';
import stringify from 'json-stable-stringify';
import { omit } from 'lodash-es';
import { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { useIdle } from 'react-use';
import shiro from 'shiro-trie';
import useSWR from 'swr';
import { reportErrorIfNotJWTExpired } from '../../../app/TenantConfig';
import { WhoAmIContext } from '../../../app/WhoAmI';
import { getAuthToken } from '../../../app/auth/token';
import { setWalkmeVariables } from '../../../app/auth/user';
import { FallbackCard } from '../../../components/FallbackCard';
import { config } from '../../../config';
import {
  PermissionsScopeNames,
  PermissionsScopeObject,
  scopesFalseForLocalDev,
} from './constants';

interface FallbackProps {
  title?: string;
  scopesStr: string;
}

interface PermissionProviderProps {
  scope: PermissionsScopeNames;
  children?: ReactNode;
}

interface HasPermissionOptionalProps {
  children?: ReactNode;
  fbcTitle?: string;
  fallback?: ReactNode | boolean;
}

interface HasPermissionProps extends HasPermissionOptionalProps {
  scope: PermissionsScopeNames;
}

interface HasPermissionsArrayProps extends HasPermissionOptionalProps {
  scopes: PermissionsScopeNames[];
}

interface CheckPermissionsProps extends HasPermissionsArrayProps {
  hook: fixMe;
}

type UseCheckPermissionsType = (
  scopes: PermissionsScopeNames[],
  everyOrSome: 'every' | 'some'
) => boolean;

type UseHasPermissionType = (scope: PermissionsScopeNames) => boolean;

type UseHasPermissionsArrayType = (scopes: PermissionsScopeNames[]) => boolean;

type UseHasPermissionSetType = (
  scopes: PermissionsScopeNames[]
) => Partial<PermissionsScopeObject>;

let queryScopes: Partial<{ [k in PermissionsScopeNames]: boolean }> = {};
if (HAS_WINDOW) {
  const initialURL = new URL(win.location.href);
  // Allow users to set scopes via the query string, but ONLY for test environments
  if (QUERY_PARAM_FLAGS_ENABLED) {
    queryScopes = {
      ...((IS_LOCAL_DEV || IS_CI) && scopesFalseForLocalDev),
      ...fromEntries(
        (
          ([...(initialURL.searchParams as fixMe).entries()] ?? []) as Array<
            [PermissionsScopeNames, string]
          >
        )
          .filter(([name]) => name.startsWith('scope-'))
          .map(([name, value]) => {
            return [name.replace('scope-', ''), jsonParse(value)];
          })
      ),
    };
  }
}
const checkScope = (
  scope: PermissionsScopeNames,
  shiroTrie: shiro.ShiroTrie | undefined
): boolean => {
  if ((IS_UNIT_TEST || IS_CY_COMPONENT_TEST) && shiroTrie) {
    return shiroTrie.check(scope) || false;
  }
  if (!USE_LD || IS_LOCAL_DEV) {
    return queryScopes[scope] ?? true;
  }
  return shiroTrie?.check(scope) || false;
};

export const getResolvedPermissionValues = (kwargs: {
  permissionsContext: ReturnType<typeof usePermissionsContext>;
  scopes: PermissionsScopeNames[];
}): boolean[] => {
  const { permissionsContext: shiroTrie, scopes } = kwargs;
  const permissions = scopes.map((scope) => {
    return checkScope(scope, shiroTrie);
  });
  return permissions;
};

const useCheckPermissions: UseCheckPermissionsType = (scopes, everyOrSome) => {
  const permissionsContext = usePermissionsContext();
  const permissions = getResolvedPermissionValues({
    permissionsContext,
    scopes,
  });
  return permissions[everyOrSome](Boolean);
};

export const useHasPermission: UseHasPermissionType = (scope) => {
  const shiroTrie = usePermissionsContext();
  return checkScope(scope, shiroTrie);
};

// ts-unused-exports:disable-next-line
export const useHasSomePermissions: UseHasPermissionsArrayType = (scopes) => {
  return useCheckPermissions(scopes, 'some');
};

// ts-unused-exports:disable-next-line
export const useHasEveryPermission: UseHasPermissionsArrayType = (scopes) => {
  return useCheckPermissions(scopes, 'every');
};

export const useHasPermissionSet: UseHasPermissionSetType = (scopes) => {
  const shiroTrie = usePermissionsContext();
  const permissionsSetChecked: Partial<{
    [k in PermissionsScopeNames]: boolean;
  }> = {};
  scopes.forEach((scope: PermissionsScopeNames) => {
    permissionsSetChecked[scope] = checkScope(scope, shiroTrie);
  });
  return permissionsSetChecked;
};

export const whoAmIV2Document = print(WhoAmIv2Document);

export const PermissionsProvider: FC = ({ children }) => {
  const [userPermissions, setUserPermissions] = useState<
    shiro.ShiroTrie | undefined
  >(undefined);
  const isIdle = useIdle();
  let polling: number | undefined = isIdle ? 10 * 60 * 1000 : 60000;
  if (IS_CYPRESS) {
    polling = undefined;
  }
  const fetcher = useMemo(
    () =>
      async (query: string): Promise<WhoAmIv2Query> => {
        const client = new GraphQLClient(`${config.apiEndpoint}?q=whoAmIV2`, {
          headers: { authorization: getAuthToken(), ...getExtraHeaders() },
        });
        const res = await client.request<WhoAmIv2Query>(query);
        if (!res.whoAmIV2?.user?.id) {
          throw new Error(
            'User has no id and will not be able to use the application.'
          );
        }
        return res;
      },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getAuthToken()]
  );
  const options = {
    revalidateOnFocus: !IS_CYPRESS,
    revalidateOnMount: true,
    revalidateOnReconnect: !IS_CYPRESS,
    refreshInterval: polling,
  };

  const { data: dataV2, error: errorV2 } = useSWR<
    ApolloQueryResult<WhoAmIv2Query>['data']
  >(whoAmIV2Document, fetcher, {
    onError: (err) => {
      reportErrorIfNotJWTExpired(err);
    },
    ...options,
  });

  const whoAmIData = dataV2?.whoAmIV2;

  useEffect(() => {
    const dataId = whoAmIData?.user?.id;
    const currId = getLocalStorage<string>(USER_ID_LOCAL_STORAGE_KEY);
    if (dataId && currId !== dataId) {
      setLocalStorage(USER_ID_LOCAL_STORAGE_KEY, dataId);
    }
  }, [whoAmIData?.user?.id]);

  const loading = !dataV2 && !errorV2;
  const hasLoaded = useHasHappened(!loading);

  const permissions = whoAmIData?.permissions || undefined;

  const employee = whoAmIData?.employee;

  /** Data to send to WalkMe */
  const wmVars = {
    name: whoAmIData?.employee?.fullName,
    email: whoAmIData?.user?.email,
    userId: whoAmIData?.user?.id ?? 'unknown',
    departmentId: employee?.email?.includes('@mastery.net')
      ? 'Mastery'
      : employee?.employeeDepartmentId,
    division: employee?.division?.name,
    displayName: employee?.employeeDisplayName,
    group: employee?.employeeGroup?.name,
    office: employee?.employeeOffice?.name,
    title: employee?.title,
    roleId: employee?.employeeRoleId,
    timeId: employee?.employeeTimeId,
    hostname: win.location.hostname,
  } as const;

  const fullStoryVars: FullstoryApprovedUserShape = {
    displayName: getFullstoryUserDisplayName(whoAmIData?.user?.id),
    userId: wmVars.userId,
    departmentId: wmVars.departmentId,
    division: wmVars.division,
    office: wmVars.office,
    title: wmVars.title,
    roleId: wmVars.roleId,
    timeId: wmVars.timeId,
    hostname: wmVars.hostname,
    isUsingDarkModeExtension: isUsingDarkModeExtension(),
  };

  setWalkmeVariables(wmVars);
  setUserVars(fullStoryVars);

  useEffect(() => {
    if (permissions) {
      const shiroTrie = shiro.newTrie();
      permissions?.forEach((p) => {
        p.scopes.forEach((s): void => {
          if ((queryScopes as anyOk)[s] !== false) {
            shiroTrie.add(s);
          }
        });
      });
      Object.entries(queryScopes).forEach(([scope, value]) => {
        if (value) {
          shiroTrie.add(scope);
        }
      });
      if (QUERY_PARAM_FLAGS_ENABLED) {
        // eslint-disable-next-line no-console
        console.debug('User Permissions: ', permissions, new Date().toString());
      }
      setUserPermissions(shiroTrie);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stringify(permissions)]);

  const whoAmIProviderValue = useMemo(
    () => omit(whoAmIData, ['permissions']),
    [whoAmIData]
  );

  if (!hasLoaded || !userPermissions || !whoAmIData) {
    return <Loading />;
  }

  return (
    <PermissionsContext.Provider value={userPermissions}>
      <WhoAmIContext.Provider value={whoAmIProviderValue}>
        {children}
      </WhoAmIContext.Provider>
    </PermissionsContext.Provider>
  );
};

export const ResetPermissionContext: FC = ({
  children,
}: {
  children?: ReactNode;
}) => {
  const permsTuple: [boolean, PermissionsScopeNames] = useMemo(() => {
    return [true, 'default:no:scope'];
  }, []);

  return (
    <HasPermissionContext.Provider value={permsTuple}>
      {children}
    </HasPermissionContext.Provider>
  );
};

export const HasPermissionProvider: FC<PermissionProviderProps> = ({
  scope,
  children,
}) => {
  const userHasPermission = useHasPermission(scope);

  const permsTuple: [boolean, PermissionsScopeNames] = useMemo(() => {
    return [userHasPermission, scope];
  }, [userHasPermission, scope]);

  return (
    <HasPermissionContext.Provider value={permsTuple}>
      {children}
    </HasPermissionContext.Provider>
  );
};

export const HasPermission: FC<HasPermissionProps> = ({
  children,
  fbcTitle,
  fallback,
  scope,
}) => {
  const [hasPermission, setHasPermission] = useState(true);
  const permission = useHasPermission(scope);
  useEffect(() => {
    setHasPermission(permission);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stringify(permission), scope]);
  if (hasPermission) {
    return <>{children}</>;
  }
  if (fallback === true) {
    return <PermissionsFallbackCard title={fbcTitle} scopesStr={scope} />;
  }
  return <>{fallback}</>;
};

const CheckPermissions: FC<CheckPermissionsProps> = ({
  children,
  fbcTitle,
  fallback,
  scopes,
  hook,
}) => {
  const [hasPermission, setHasPermission] = useState(true);
  const permission = hook(scopes);
  useEffect(() => {
    setHasPermission(permission);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stringify(permission), scopes]);

  if (hasPermission) {
    return <>{children}</>;
  }
  if (fallback === true) {
    return (
      <PermissionsFallbackCard title={fbcTitle} scopesStr={scopes.toString()} />
    );
  }
  return <>{fallback}</>;
};

// ts-unused-exports:disable-next-line
export const HasSomePermissions: FC<HasPermissionsArrayProps> = ({
  children,
  fbcTitle,
  fallback,
  scopes,
}) => (
  <CheckPermissions
    fbcTitle={fbcTitle}
    fallback={fallback}
    scopes={scopes}
    hook={useHasSomePermissions}
  >
    {children}
  </CheckPermissions>
);

export const HasEveryPermission: FC<HasPermissionsArrayProps> = ({
  children,
  fbcTitle,
  fallback,
  scopes,
}) => (
  <CheckPermissions
    fbcTitle={fbcTitle}
    fallback={fallback}
    scopes={scopes}
    hook={useHasEveryPermission}
  >
    {children}
  </CheckPermissions>
);

export const PermissionsFallbackCard: FC<FallbackProps> = ({
  title,
  scopesStr,
}) => {
  let cardTitle = 'Restricted Access';
  if (title) {
    cardTitle = `${title}: ${cardTitle}`;
  }

  return (
    <FallbackCard title={cardTitle}>
      <div css={{ lineHeight: 1.5 }}>
        <p>Whoops, permission is needed to access this functionality.</p>
        <p>
          Please contact your administrator for assistance with the following
          message:
        </p>
        <code>
          User lacks permission required for accessing
          <em>&apos;{win.location.href}&apos;</em>. User may lack permission on
          one or more of the following scopes: {scopesStr}.
        </code>
      </div>
    </FallbackCard>
  );
};
