import * as TP from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MfTrailerItemV2Fragment = (
  { readonly __typename?: 'TrailerAutocompleteOutput' }
  & Pick<TP.TrailerAutocompleteOutput, 'id' | 'typeTerm' | 'code'>
  & { readonly carrier?: TP.Maybe<(
    { readonly __typename?: 'CarrierV2' }
    & Pick<TP.CarrierV2, 'id' | 'name'>
  )> }
);

export type TrailersForMasterfindV2QueryVariables = TP.Exact<{
  trailerSearch: TP.TrailerAutocompleteFilter;
}>;


export type TrailersForMasterfindV2Query = (
  { readonly __typename?: 'Query' }
  & { readonly trailerAutocomplete: ReadonlyArray<(
    { readonly __typename?: 'TrailerAutocompleteOutput' }
    & Pick<TP.TrailerAutocompleteOutput, 'id' | 'typeTerm' | 'code'>
    & { readonly carrier?: TP.Maybe<(
      { readonly __typename?: 'CarrierV2' }
      & Pick<TP.CarrierV2, 'id' | 'name'>
    )> }
  )> }
);

export const MfTrailerItemV2FragmentDoc = gql`
    fragment MFTrailerItemV2 on TrailerAutocompleteOutput {
  id
  typeTerm
  code
  carrier {
    id
    name
  }
}
    `;
export const TrailersForMasterfindV2Document = gql`
    query trailersForMasterfindV2($trailerSearch: TrailerAutocompleteFilter!) {
  trailerAutocomplete(queryFilter: $trailerSearch) {
    ...MFTrailerItemV2
  }
}
    ${MfTrailerItemV2FragmentDoc}`;

/**
 * __useTrailersForMasterfindV2Query__
 *
 * To run a query within a React component, call `useTrailersForMasterfindV2Query` and pass it any options that fit your needs.
 * When your component renders, `useTrailersForMasterfindV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrailersForMasterfindV2Query({
 *   variables: {
 *      trailerSearch: // value for 'trailerSearch'
 *   },
 * });
 */
export function useTrailersForMasterfindV2Query(baseOptions: Apollo.QueryHookOptions<TrailersForMasterfindV2Query, TrailersForMasterfindV2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TrailersForMasterfindV2Query, TrailersForMasterfindV2QueryVariables>(TrailersForMasterfindV2Document, options);
      }
export function useTrailersForMasterfindV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TrailersForMasterfindV2Query, TrailersForMasterfindV2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TrailersForMasterfindV2Query, TrailersForMasterfindV2QueryVariables>(TrailersForMasterfindV2Document, options);
        }
export type TrailersForMasterfindV2QueryHookResult = ReturnType<typeof useTrailersForMasterfindV2Query>;
export type TrailersForMasterfindV2LazyQueryHookResult = ReturnType<typeof useTrailersForMasterfindV2LazyQuery>;
export type TrailersForMasterfindV2QueryResult = Apollo.QueryResult<TrailersForMasterfindV2Query, TrailersForMasterfindV2QueryVariables>;