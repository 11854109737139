import { useShortcut } from '@components/Shortcut';
import { IS_UNIT_TEST } from '@utils/constants';
import { compact, findIndex, last, noop } from 'lodash-es';
import {
  Dispatch,
  FC,
  RefObject,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
interface StackItem {
  group: string;
  id: string;
  ref: RefObject<HTMLButtonElement>['current'];
  htmlId: string;
}

interface SaveContext {
  stack: StackItem[];
  setStack: Dispatch<SetStateAction<StackItem[]>>;
}

const defaultCtx: SaveContext = {
  stack: [],
  setStack: noop,
};

const SaveContext = createContext<SaveContext>(defaultCtx);

export const SaveProvider: FC = ({ children }) => {
  const [stack, setStack] = useState<StackItem[]>([]);
  const value = useMemo(() => ({ stack, setStack }), [stack, setStack]);
  useShortcut(
    {
      cmd: IS_UNIT_TEST ? false : true,
      keys: 's',
      onEvent: (): void => {
        const lastGroup = last(stack)?.group;
        if (lastGroup) {
          stack.forEach(({ ref, group }) => {
            if (group === lastGroup) {
              if (ref && !ref.disabled) {
                if (IS_UNIT_TEST) {
                  ref.classList.add('clicked');
                } else {
                  ref.click();
                }
              }
            }
          });
        }
      },
    },
    [stack]
  );
  return <SaveContext.Provider value={value}>{children}</SaveContext.Provider>;
};

interface HookRet {
  push: (item: StackItem) => void;
  remove: (id: string) => void;
}

export const useSaveContext: () => HookRet = () => {
  const { setStack } = useContext(SaveContext);

  const push = useCallback(
    (newItem: StackItem) => {
      setStack((arr) => {
        if (arr.find((obj) => obj.id === newItem.id)) {
          return arr;
        }
        return [...arr, newItem];
      });
    },
    [setStack]
  );

  const remove = useCallback(
    (id: string) => {
      setStack((arr) => {
        const foundIndex = findIndex(arr, (obj) => obj.id === id);
        if (foundIndex > -1) {
          const copy = [...arr];
          copy[foundIndex] = undefined as anyOk;
          return compact(copy);
        }
        return arr;
      });
    },
    [setStack]
  );

  return { push, remove };
};
