import { useMachine } from '@xstate/react';
import { createContext, useMemo } from 'react';
import { State } from 'xstate';
import { ChatContext as CC, ChatEvent, chatMachine } from './machine';

// Interpreter<TContext, any, TEvent, TTypestate>['send'],

interface ChatContextValue {
  state: State<CC, ChatEvent>;
  send: (event: ChatEvent) => void;
}

export const ChatContext = createContext<ChatContextValue>({
  // TODO: can we remove this anyOk and fix the ts somehow? null makes downstream code more complex...
  state: null as anyOk,
  send: null as anyOk,
});

export const ChatProvider: React.FC = ({ children }) => {
  const [state, send] = useMachine(chatMachine);
  const value = useMemo(() => ({ state, send }), [state, send]);
  return (
    <ChatContext.Provider value={value as anyOk}>
      {children}
    </ChatContext.Provider>
  );
};
