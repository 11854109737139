import { useInterpret } from '@xstate/react';
import { createContext, FC, useMemo } from 'react';
import { ActorRefFrom } from 'xstate';
import { mfMachine } from '..';

interface MasterFindGlobalStateContextType {
  masterFindGlobalService: ActorRefFrom<typeof mfMachine>;
}

export const MasterFindGlobalStateContext = createContext(
  {} as MasterFindGlobalStateContextType
);

export const MasterFindGlobalStateProvider: FC = (props) => {
  const masterFindGlobalService = useInterpret(mfMachine);

  const masterFindService = useMemo(
    () => ({ masterFindGlobalService }),
    [masterFindGlobalService]
  );

  return (
    <MasterFindGlobalStateContext.Provider value={masterFindService}>
      {props.children}
    </MasterFindGlobalStateContext.Provider>
  );
};
