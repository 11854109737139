import { getAddressDisplayString } from '@components/AddressDisplay';
import { Item, Props } from '@components/AutoComplete';
import { PRIVATE_themeObj } from '@components/Theme';
import { CarrierInfoSidebarV2Fragment } from '@generated/fragments/carrierInfoSidebarV2';
import { CustomerSearchResult } from './CustomerSearch';

type SearchTypes = CustomerSearchResult | CarrierInfoSidebarV2Fragment;
type ResultType = <T extends SearchTypes>() => Props<T>['DEPRECATEDrenderItem'];

export const renderSideBarResults: ResultType = <T extends SearchTypes>() => {
  const render: Props<T>['DEPRECATEDrenderItem'] = ({
    item,
    key,
    defaultItemStyles,
    getItemProps,
    isHighlighted,
    index,
  }) => {
    const { value } = item;
    const addressDisplayString = getAddressDisplayString({
      value: {
        city: value?.mainAddress?.city,
        state: value?.mainAddress?.state,
      },
      city: true,
      state: true,
    });
    const titleString = `${value?.name} | ${value?.code} | ${addressDisplayString}`;
    return (
      <Item
        key={key}
        css={{
          ...defaultItemStyles,
          ...{
            display: 'block',
            width: '100%',
            padding: '8px',
            textAlign: 'left',
          },
        }}
        {...getItemProps({
          item,
          index,
          key,
          style: {
            backgroundColor: isHighlighted
              ? PRIVATE_themeObj.colors.primary
              : PRIVATE_themeObj.card.background,
            color: isHighlighted ? PRIVATE_themeObj.card.background : 'inherit',
          },
        })}
        title={titleString}
        data-testid={value?.id ?? ''}
      >
        <span css={{ fontWeight: 600 }}>{value?.name}</span>
        <br />
        <span>
          {value?.code}{' '}
          {addressDisplayString.length ? `| ${addressDisplayString}` : ''}
        </span>
      </Item>
    );
  };
  return render;
};
