import * as TP from '../types';

import { gql } from '@apollo/client';
export type CarrierInfoSearchPageV2Fragment = (
  { readonly __typename?: 'CarrierV2' }
  & Pick<TP.CarrierV2, 'id' | 'name' | 'code' | 'scacNumber' | 'mcNumber' | 'dotNumber' | 'levelId' | 'status'>
  & { readonly mainAddress?: TP.Maybe<(
    { readonly __typename?: 'CarrierAddress' }
    & Pick<TP.CarrierAddress, 'id' | 'city' | 'state'>
  )>, readonly reps?: TP.Maybe<ReadonlyArray<(
    { readonly __typename?: 'RepV2' }
    & Pick<TP.RepV2, 'id' | 'main'>
    & { readonly employee: (
      { readonly __typename?: 'EmployeeV2' }
      & Pick<TP.EmployeeV2, 'id' | 'firstName' | 'lastName'>
    ) }
  )>>, readonly opportunity?: TP.Maybe<(
    { readonly __typename?: 'KeyValue' }
    & Pick<TP.KeyValue, 'id' | 'name'>
  )>, readonly carrierServices?: TP.Maybe<ReadonlyArray<(
    { readonly __typename?: 'CarrierServiceV2' }
    & Pick<TP.CarrierServiceV2, 'id'>
    & { readonly carrierServiceType: (
      { readonly __typename?: 'KeyValue' }
      & Pick<TP.KeyValue, 'id' | 'name'>
    ) }
  )>>, readonly certifications?: TP.Maybe<ReadonlyArray<(
    { readonly __typename?: 'CarrierCertificationV2' }
    & Pick<TP.CarrierCertificationV2, 'id'>
    & { readonly carrierCertificationType: (
      { readonly __typename?: 'KeyValue' }
      & Pick<TP.KeyValue, 'id' | 'name'>
    ) }
  )>>, readonly intrastateStateAuthorities?: TP.Maybe<ReadonlyArray<(
    { readonly __typename?: 'IntrastateAuthority' }
    & Pick<TP.IntrastateAuthority, 'intrastateNumber' | 'country'>
  )>> }
);

export const CarrierInfoSearchPageV2FragmentDoc = gql`
    fragment CarrierInfoSearchPageV2 on CarrierV2 {
  id
  name
  code
  mainAddress {
    id
    city
    state
  }
  reps {
    id
    main
    employee {
      id
      firstName
      lastName
    }
  }
  scacNumber
  mcNumber
  dotNumber
  levelId
  status
  opportunity {
    id
    name
  }
  carrierServices {
    id
    carrierServiceType {
      id
      name
    }
  }
  certifications {
    id
    carrierCertificationType {
      id
      name
    }
  }
  intrastateStateAuthorities {
    intrastateNumber
    country
  }
}
    `;