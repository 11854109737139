import {
  AuthContext,
  AuthStatus,
  defaultContext,
  LOCAL_STORAGE_LD_USER_IDENTIFIERS,
  MMKeycloakProfile,
} from '@components/AuthContext';
import { flagDefaultsForLocalDev } from '@components/Flag/flags';
import {
  AuthClient,
  AuthClientError,
  AuthClientEvent,
} from '@react-keycloak/core/lib/types';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import {
  HAS_MASTERY_CHROME_EXTENSION,
  IS_NOT_PROD_ENV,
  SKIP_AUTH,
} from '@utils/constants';
import { jsonParse, jsonStringify } from '@utils/json';
import { removeLocalStorage } from '@utils/localStorage';
import { reportCustomSentryError } from '@utils/sentry';
import { HAS_WINDOW, win } from '@utils/win';
import Keycloak, {
  KeycloakError,
  KeycloakInitOptions,
  KeycloakPromise,
  KeycloakTokenParsed,
} from 'keycloak-js';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { noop } from 'lodash-es';
import { FC, useCallback, useMemo, useState } from 'react';
import { config } from '../../config';
import {
  APP_KEYCLOAK_MIN_VALIDITY_SECONDS_SYMBOL,
  APP_KEYCLOAK_TRIGGER_REFRESH_SECONDS_SYMBOL,
} from '../GlobalVariables/constants';
import { getGlobalVariable } from '../GlobalVariables/util';
import { setUserData } from './user';

interface KeycloakTokenExt extends KeycloakTokenParsed {
  auth0_id?: string;
  keycloak_user_id?: string;
}

interface AuthState {
  status: AuthStatus;
  user: MMKeycloakProfile | null;
  ldIdentified: boolean;
}

const testLog = (...args: anyOk[]): void => {
  if (IS_NOT_PROD_ENV) {
    // eslint-disable-next-line no-console
    console.debug(...args);
  }
};

let keycloakInstanceObj = null as unknown as Keycloak.KeycloakInstance;
if (HAS_WINDOW) {
  try {
    keycloakInstanceObj = Keycloak({
      realm: config.keycloakRealm,
      url: config.keycloakUri,
      clientId: config.keycloakClientId,
    });
  } catch {
    const errMsg =
      'Creation of the Keycloak Instance Object failed with these config settings: ' +
      jsonStringify({
        realm: config.keycloakRealm,
        url: config.keycloakUri,
        clientId: config.keycloakClientId,
      });
    reportCustomSentryError(errMsg);
    testLog(errMsg);
  }
}

export const keycloakInstance = keycloakInstanceObj;

export const getKeycloakUserId = (): string | undefined => {
  const kcToken: KeycloakTokenExt = keycloakInstance?.tokenParsed || {};
  return kcToken.keycloak_user_id;
};

// monkeypatching the init method, it allows a developer to SKIP_AUTH with Keycloak.

if (SKIP_AUTH) {
  keycloakInstance.init = function (
    initOptions: KeycloakInitOptions
  ): KeycloakPromise<boolean, KeycloakError> {
    noop(initOptions);
    this.onAuthSuccess && this.onAuthSuccess();
    return new Promise<boolean>(noop) as KeycloakPromise<
      boolean,
      KeycloakError
    >;
  };
}

const keycloakProviderInitConfig: KeycloakInitOptions = {
  onLoad: 'check-sso',
  enableLogging: IS_NOT_PROD_ENV ? false : true,
  silentCheckSsoRedirectUri: win.location.origin + '/silent-check-sso.html',
};

const clearLocalStorage = (): void => {
  const swrWhoAmIStorageKeys = Object.keys(win.localStorage).filter(
    (key) =>
      key.startsWith('swr-query whoAmI') ||
      key.startsWith('swr-err@query whoAmI')
  );
  swrWhoAmIStorageKeys.forEach(removeLocalStorage);
  removeLocalStorage(LOCAL_STORAGE_LD_USER_IDENTIFIERS);
};

export const MasteryKeycloakProvider: FC = ({ children }) => {
  // would be better to use useReducer instead of fragmenting state across different hooks but im lazy
  const [user, setUser] = useState<MMKeycloakProfile | null>(
    defaultContext.user
  );
  const [status, setStatus] = useState<AuthStatus>(defaultContext.status);
  const [hasLdIdentified, setHasLdIdentified] = useState<boolean>(
    defaultContext.ldIdentified
  );
  const ldClient = useLDClient();
  const [refreshTokenInterval, setRefreshTokenInterval] = useState<
    number | undefined
  >();

  const minValiditySeconds =
    getGlobalVariable<number>(APP_KEYCLOAK_MIN_VALIDITY_SECONDS_SYMBOL) ||
    flagDefaultsForLocalDev['app-keycloak-min-validity-seconds'] ||
    30000;
  const triggerRefreshSeconds =
    getGlobalVariable<number>(APP_KEYCLOAK_TRIGGER_REFRESH_SECONDS_SYMBOL) ||
    flagDefaultsForLocalDev['app-keycloak-trigger-refresh-seconds'] ||
    240;

  const clearTokenRefresh = useCallback((): void => {
    if (refreshTokenInterval || keycloakInstance.idToken) {
      testLog('Clear token refresh interval, tokens, and WhoAmI localStorage.');
      clearInterval(refreshTokenInterval);
      setRefreshTokenInterval(undefined);
      clearLocalStorage();
    }
  }, [refreshTokenInterval]);

  const loadData = useCallback((): void => {
    setStatus(AuthStatus.authenticated);
    const userKey = getKeycloakUserId() || '';
    if (keycloakInstance.authenticated && keycloakInstance.token) {
      keycloakInstance
        .loadUserProfile()
        .then(async (result) => {
          setUser(result);
          try {
            await setUserData({
              ldClient,
              data: {
                email: result.email,
                key: userKey,
                hostname: win.location.hostname,
                // TODO: this is not good, we should use a real .name value if we can get one
                name: `${result.firstName} ${result.lastName}`,
              },
            });
            setHasLdIdentified(true);
          } catch (err: anyOk) {
            reportCustomSentryError(err);
          }
        })
        .catch(() => {
          testLog(
            `Loading the user's profile from Keycloak failed.  Session may have ended.`
          );
          keycloakInstance.logout();
        });
    } else {
      testLog(
        `Not loading user data: authenticated ${
          keycloakInstance.authenticated
        }, token ${!!keycloakInstance.token}`
      );
    }
  }, [ldClient]);

  const updateToken = useCallback((): void => {
    try {
      if (keycloakInstance.authenticated && keycloakInstance.token) {
        const tokenExpired =
          keycloakInstance.isTokenExpired(minValiditySeconds);
        keycloakInstance
          .updateToken(minValiditySeconds)
          .then(() => {
            testLog('UpdateToken successful. Call loadData next;');
            loadData();
          })
          .catch(() => {
            if (!tokenExpired) {
              reportCustomSentryError(
                'Failed to refresh token. keycloakInstance.updateToken on non-expired token FAILED.'
              );
              testLog(
                'Failed to refresh token. Error reported.  keycloakInstance.updateToken on non-expired token FAILED.',
                new Date().toISOString()
              );
            } else {
              testLog('Failed to refresh token.', new Date().toISOString());
            }
          });
      }
    } catch {
      clearTokenRefresh();
      testLog(
        'An error ocured while updating the token and loading user data from Keycloak.'
      );
    }
  }, [loadData, clearTokenRefresh, minValiditySeconds]);

  const onKeycloakEvent = useCallback(
    (event: AuthClientEvent, error: AuthClientError | undefined): void => {
      testLog('onKeycloakEvent:', event, new Date().toISOString());
      if (error) {
        testLog('Reporting error:', error);
        reportCustomSentryError(jsonStringify(error));
        keycloakInstance.logout();
        return;
      }

      if (SKIP_AUTH) {
        setStatus(AuthStatus.authenticated);
        const user = {
          email: 'test@mastery.net',
          firstName: 'Test',
          lastName: 'User',
          username: '1234',
          updatedAt: '',
          emailVerified: true,
        };
        setUser(user);
        return;
      }

      const eventMap: Record<AuthClientEvent, () => void> = {
        onAuthSuccess: () => {
          const CHECK_TOKEN_INTERVAL_MS = triggerRefreshSeconds * 1000;
          if (HAS_MASTERY_CHROME_EXTENSION) {
            updateToken();
          } else {
            loadData();
          }
          setRefreshTokenInterval(
            win.setInterval(() => {
              try {
                testLog('Update token on refresh.');
                updateToken();
              } catch {
                testLog('Update token failed on refresh.');
              }
            }, CHECK_TOKEN_INTERVAL_MS)
          );
        },
        onAuthLogout: clearTokenRefresh,
        onAuthError: clearTokenRefresh,
        onAuthRefreshError: () => {
          testLog(
            "User's Keycloak session expired but Keycloak tried to refresh the token.  We've patched keycloak-js to prevent error logging in Sentry."
          );
          clearTokenRefresh();
        },
        onInitError: noop,
        onTokenExpired: noop,
        onAuthRefreshSuccess: noop,
        onReady: noop,
      };

      eventMap[event]();
    },
    [clearTokenRefresh, loadData, triggerRefreshSeconds, updateToken]
  );

  const onTokensHandler = useCallback(
    (tokens: Pick<AuthClient, 'idToken' | 'refreshToken' | 'token'>): void => {
      noop(tokens);
      testLog('Have token.');
    },
    []
  );

  const authContextValue = useMemo((): AuthState => {
    let orgs = [];
    try {
      orgs = user?.attributes?.Orgs?.map(jsonParse);
    } catch {
      // noop
    }
    return { status, user: { ...user, orgs }, ldIdentified: hasLdIdentified };
  }, [status, user, hasLdIdentified]);

  return (
    <ReactKeycloakProvider
      authClient={keycloakInstance}
      initOptions={keycloakProviderInitConfig} //object to be passed to keycloak.init()
      onTokens={onTokensHandler} //handler function receives keycloak tokens as an object every time they change.
      onEvent={onKeycloakEvent}
    >
      <AuthContext.Provider value={authContextValue}>
        {children}
      </AuthContext.Provider>
    </ReactKeycloakProvider>
  );
};
