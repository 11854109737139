import * as TP from '../subscriptionTypes';

import { gql } from '@apollo/client';
export type BookingEventPayloadFragment = (
  { readonly __typename?: 'BookingEvent' }
  & Pick<TP.BookingEvent, 'id' | 'type' | 'capacityId' | 'bookingSourceId'>
);

export const BookingEventPayloadFragmentDoc = gql`
    fragment BookingEventPayload on BookingEvent {
  id
  type
  capacityId
  bookingSourceId
}
    `;