import { DebounceSettings, debounce, throttle } from 'lodash-es';
import { DependencyList, useCallback } from 'react';

export function useDebouncedFn<FuncType extends unknown>(
  func: FuncType,
  millis: number,
  comparator: DependencyList,
  options?: DebounceSettings,
  useThrottle?: boolean
): FuncType {
  const run = useThrottle ? throttle : debounce;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(run(func as fixMe, millis, options), comparator) as fixMe;
}
