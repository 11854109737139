import {
  DriversForMasterfindV2Document,
  DriversForMasterfindV2Query,
  DriversForMasterfindV2QueryVariables,
} from '@generated/queries/driversForMasterfindV2';
import { useLazyQueryWithDataPromise } from '@hooks/useLazyQueryWithDataPromise';
import { FC, useCallback } from 'react';
import { BASE_MAX_ITEM_LIMIT } from '../constants';

type SearchNode = DriversForMasterfindV2Query['driverAutocomplete'][number];

export const RenderDriverSearchItem: FC<{
  item: SearchNode;
}> = ({ item }) => {
  const activeFleet = item.carrier?.name;
  const name = item.displayName;
  // Final output should be:
  // **Driver Code** | Display Name | Active Fleet
  return (
    <span>
      <strong>{item.code}</strong>
      {name && <span> | {name}</span>}
      {activeFleet && <span> | {activeFleet}</span>}
    </span>
  );
};

export const useSearchDriverPromise = (): ((
  text: string
) => Promise<SearchNode[]>) => {
  const callQueryV2 = useLazyQueryWithDataPromise<
    DriversForMasterfindV2Query,
    DriversForMasterfindV2QueryVariables
  >(DriversForMasterfindV2Document);

  return useCallback(
    async (text: string): Promise<SearchNode[]> => {
      const rawRes = await callQueryV2({
        fetchPolicy: 'network-only',
        variables: {
          queryFilter: {
            searchTerm: text,
            limit: BASE_MAX_ITEM_LIMIT,
          },
        },
      });
      return (rawRes.data.driverAutocomplete ?? []).filter(Boolean);
    },
    [callQueryV2]
  );
};
