import { useTheme } from '@emotion/react';
import { useBroadcastChannel } from '@hooks/useBroadcastChannel';
import { getLocalStorage, setLocalStorage } from '@utils/localStorage';
import { win } from '@utils/win';
import { useMemo } from 'react';
import { useEffectOnce, useUpdate } from 'react-use';
import { v4 } from 'uuid';

interface StoreShape {
  groups: Map<string, string[]>;
  currentGroup: string;
}

const localStoreDefault: StoreShape = {
  groups: new Map(),
  currentGroup: '',
};

interface CloseWindowAfterIntervalType {
  overlayTitle: string;
  timeInSeconds?: number;
  ribbonColor: string;
  ribbonMessage: string;
  ribbonTextColor: string;
}

export const MASTERLINK = 'masterlink';
export const MASTERLINK_TAB_CONNECTION = 'tab_connection';

const localStore: StoreShape = { groups: new Map(), currentGroup: '' };

const setGroups = (obj?: StoreShape): void => {
  if (obj) {
    localStore.groups = new Map(obj.groups);
  }
};

const showConnectedRibbon = (
  color: string,
  title?: string,
  textColor?: string
): void => {
  document.documentElement.style.setProperty('--connected-tabs-bg', color);
  document.documentElement.style.setProperty(
    '--connected-tabs-display',
    `block`
  );
  if (title) {
    const element: HTMLElement = document.getElementById(
      'connected-tabs'
    ) as HTMLElement;
    element.innerHTML = title as string;

    if (textColor) {
      element.style.color = textColor;
    }
  }
};

const hideConnectedRibbon = (): void => {
  document.documentElement.style.setProperty(
    '--connected-tabs-display',
    'none'
  );
};

const addPageOverlay = (title?: string): void => {
  document.documentElement.style.setProperty('--overlay-display', 'block');

  if (title) {
    const element: HTMLElement = document.getElementById(
      'overlay'
    ) as HTMLElement;
    element.innerHTML = title as string;
    element.style.paddingTop = '45vh';
    element.style.fontSize = '16px';
  }
};

const closeWindowAfterInterval = ({
  overlayTitle,
  timeInSeconds,
  ribbonColor,
  ribbonMessage,
  ribbonTextColor,
}: CloseWindowAfterIntervalType): void => {
  let time = timeInSeconds ? timeInSeconds : 3;
  setInterval(() => {
    showConnectedRibbon(ribbonColor, ribbonMessage, ribbonTextColor);
    if (time > 0) {
      addPageOverlay(
        overlayTitle.concat(` Page will be closed in ${time} Seconds`)
      );
    }
    if (time === 0) {
      win.close();
    }
    time -= 1;
  }, 1000);
};

const getCurrentUrl = (): URL => {
  return new URL(win.location.href);
};

const isWindowChanged = (launchWindow: URL): boolean => {
  const currentUrl = getCurrentUrl();
  if (currentUrl.pathname !== launchWindow.pathname) {
    return true;
  }
  return false;
};

export const isChildTabOpen = (): boolean => {
  const data = getLocalStorage<MasterlinkLocalStorageType>(MASTERLINK);
  const currentUrl = getCurrentUrl();
  return (
    data?.isChildTabOpen ?? currentUrl.searchParams.get('activeTab') == childTab
  );
};

const connectClient = (group: string, id: string): void => {
  const gMap = new Map(localStore.groups);
  const arr = gMap.get(group as unknown as string) ?? [];
  localStore.groups = gMap.set(group as unknown as string, arr.concat(id));
};

interface BaseMessage {
  id: string;
  group: string;
}

type Message =
  | (BaseMessage & { action: 'connect' })
  | (BaseMessage & { action: 'disconnect' })
  | (BaseMessage & {
      action: 'sync';
      data: StoreShape;
      controller: string;
    });

export interface MasterlinkLocalStorageType {
  isChildTabOpen: boolean;
}
const post = (channel: Maybe<BroadcastChannel>, msg: Message): void => {
  if (channel) {
    try {
      channel.postMessage(msg);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }
};

export const childTab = 'childWindow';
// ts-unused-exports:disable-next-line
export const parentTab = 'parentWindow';

interface Kwargs {
  group?: string;
  title?: string;
  launchWindow: URL;
}

// Using state outside of React here is theoretically ok because we won't have more than one instance of a window connection. (singleton pattern)
let channel: BroadcastChannel | null = null;
let controllerId = '';
let group = '';

const resetLocalStore = (groupStr: string): void => {
  localStore.groups = new Map(localStoreDefault.groups);
  localStore.currentGroup = localStoreDefault.currentGroup;
  controllerId = '';
  group = groupStr;
};

resetLocalStore(v4());

export const useConnectedWindows = (
  kwargs: Kwargs
): StoreShape & { thisId: string; childTab: string; parentTab: string } => {
  if (!channel) {
    channel = new BroadcastChannel('connected');
  }

  const { postMessage } = useBroadcastChannel<MasterlinkLocalStorageType>({
    id: MASTERLINK_TAB_CONNECTION,
  });

  const { colors } = useTheme();
  const id = useMemo(() => v4(), []);
  const triggerComponentRender = useUpdate();
  let isController = controllerId && controllerId === id;
  localStore.currentGroup = group;

  const ConnectionBrokeMsg =
    'Connection to parent window lost. Please close this window';
  const overlayTitle = 'Connection to Parent Page has been lost.';

  useEffectOnce(() => {
    resetLocalStore(kwargs.group ?? v4());
    channel?.addEventListener('message', (rawEvent) => {
      const event = rawEvent.data as Message;
      const eventData = event.action === 'sync' ? event.data : undefined;
      const eventGroup = event.group as unknown as string;
      const eventId = event.id as string;
      const isMatchingGroup = eventGroup === group;

      switch (event.action) {
        case 'connect':
          {
            const modifiedGroups = new Map(localStore.groups);
            const newGroupIds = (modifiedGroups.get(eventGroup) ?? []).concat(
              eventId
            );
            modifiedGroups.set(eventGroup, newGroupIds);
            const nextData = {
              groups: modifiedGroups,
              currentGroup: group,
            };
            if (!controllerId) {
              controllerId = id;
              isController = true;
            }
            if (isController) {
              // Note: the console will help us to debug hook better
              // eslint-disable-next-line no-console
              // console.log('connect, run sync', {
              //   isController,
              //   nextData,
              //   externalState,
              // });
              post(channel, {
                id,
                group,
                action: 'sync',
                data: nextData,
                controller: id,
              });
            }
            if (isMatchingGroup && newGroupIds.length > 1) {
              showConnectedRibbon(colors.lightCyan, kwargs.title);
            }
            setGroups(nextData);
          }
          break;

        case 'sync':
          {
            if (isMatchingGroup && event.controller) {
              const nextGroupIds = eventData?.groups.get(eventGroup) ?? [];
              if (nextGroupIds.length > 1) {
                showConnectedRibbon(colors.lightCyan, kwargs.title);
              }

              const currentUrl = getCurrentUrl();
              if (currentUrl.searchParams.get('activeTab') == childTab) {
                postMessage({ isChildTabOpen: true });
                setLocalStorage<MasterlinkLocalStorageType>(MASTERLINK, {
                  isChildTabOpen: true,
                });
              }
              controllerId = event.controller;
            }
            setGroups(eventData);
          }
          break;

        case 'disconnect':
          {
            const currentUrl = getCurrentUrl();
            if (currentUrl.searchParams.get('activeTab') == childTab) {
              postMessage({ isChildTabOpen: false });
              setLocalStorage<MasterlinkLocalStorageType>(MASTERLINK, {
                isChildTabOpen: false,
              });
              closeWindowAfterInterval({
                overlayTitle,
                ribbonColor: colors.error,
                ribbonMessage: ConnectionBrokeMsg,
                ribbonTextColor: colors.textReverse,
              });
            } else {
              hideConnectedRibbon();
            }

            if (isController) {
              const modifiedGroups = new Map(localStore.groups);
              const newConnectedIds = (
                (modifiedGroups.get(eventGroup) ?? []) as string[]
              ).filter((s) => s !== eventId);
              modifiedGroups.set(eventGroup, newConnectedIds);
              if (newConnectedIds.length === 0) {
                modifiedGroups.delete(eventGroup);
              }
              const nextData = {
                groups: modifiedGroups,
                currentGroup: group,
              };
              setGroups(nextData);

              postMessage({ isChildTabOpen: false });
              setLocalStorage<MasterlinkLocalStorageType>(MASTERLINK, {
                isChildTabOpen: false,
              });
              if (!isWindowChanged(kwargs.launchWindow)) {
                win.location.href = currentUrl.href;
              }
            }
          }
          break;
      }
      triggerComponentRender();
    });

    const initMessage: Message = {
      id,
      group,
      action: 'connect',
    };
    post(channel, initMessage);
    connectClient(group, id);
    triggerComponentRender();

    win.addEventListener('unload', () => {
      hideConnectedRibbon();
      post(channel, {
        id,
        group,
        action: 'disconnect',
      });
    });

    win.addEventListener('pushstate', () => {
      const currentUrl = getCurrentUrl();
      if (!currentUrl.searchParams.has('activeTab')) {
        hideConnectedRibbon();
        post(channel, {
          id,
          group,
          action: 'disconnect',
        });
      }
    });

    return () => {
      if (isWindowChanged(kwargs.launchWindow)) {
        hideConnectedRibbon();
        resetLocalStore(kwargs.group ?? v4());
        post(channel, {
          id,
          group,
          action: 'disconnect',
        });
      }
    };
  });
  return { ...localStore, thisId: id, childTab, parentTab };
};
