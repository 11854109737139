import { ExternalLink } from '@components/ExternalLink';
import { lockMask } from '@components/Field/PhoneField';
import { useLazyImport } from '@hooks/useLazyImport';
import { FC, memo, useCallback, useEffect, useState } from 'react';
import { ReactElementType } from 'react-window';

export interface Props extends MasteryHTML {
  value?: Maybe<string>;
  as?: string;
  country?: string;
  lockPhoneValue?: boolean;
}

export const usePhoneLib = ():
  | typeof import('react-phone-number-input')
  | null => {
  return useLazyImport(() => {
    return import('react-phone-number-input');
  });
};

interface CoercedPhoneNumberRet {
  original?: Maybe<string>;
  callingCode?: string;
  country?: Maybe<string> | false;
  formattedNational?: string;
  formattedIntl?: Maybe<string>;
  resolved: boolean;
}

const useCoercedPhoneNumber = (props: Props): CoercedPhoneNumberRet => {
  const [state, setState] = useState<CoercedPhoneNumberRet>({
    original: props.value,
    resolved: false,
  });
  const phoneLib = usePhoneLib();

  const { country, value: valueProp } = props;

  const coerceEffect = useCallback(() => {
    let value = valueProp;
    if (!phoneLib || !value) {
      return;
    }
    let callingCode = '';
    if (country && !callingCode) {
      callingCode = phoneLib.getCountryCallingCode(country);
      if (!value.startsWith(`+${callingCode}`)) {
        value = `+${callingCode}${value}`;
      }
    }

    const parsed = phoneLib.parsePhoneNumber(value);
    const formattedIntl = phoneLib.formatPhoneNumberIntl(value);
    const formattedNational = phoneLib.formatPhoneNumber(value);

    setState(() => ({
      original: props.value,
      callingCode: callingCode || parsed?.countryCallingCode,
      country: parsed?.country,
      formattedIntl: formattedIntl || props.value,
      formattedNational,
      resolved: true,
    }));
  }, [country, phoneLib, props.value, valueProp]);

  useEffect(() => {
    if (phoneLib) {
      if (!state.resolved || valueProp !== state.original) {
        coerceEffect();
      }
    }
  }, [coerceEffect, phoneLib, state, valueProp]);

  return state;
};

const PhoneValueComp: FC<Props> = (props) => {
  const { value, as: asProp = 'a', lockPhoneValue = false, ...rest } = props;
  const { formattedIntl, formattedNational, callingCode, resolved } =
    useCoercedPhoneNumber(props);
  if (!value || !resolved) {
    return <span {...rest}>{(resolved && value) || null}</span>;
  }
  const callingCodeRender = callingCode ? `+${callingCode} ` : '';
  let render = `${callingCodeRender}${formattedNational || formattedIntl}`;
  render = lockPhoneValue ? lockMask(render) : render;
  const El: ReactElementType = asProp;
  if (El === 'a') {
    return (
      <ExternalLink to={`tel:${formattedIntl}`} {...rest}>
        {render}
      </ExternalLink>
    );
  }
  return <El {...rest}>{render}</El>;
};

export const PhoneValue = memo(PhoneValueComp);
