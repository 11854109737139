import * as TP from '../subscriptionTypes';

import { gql } from '@apollo/client';
export type UserMatchEventPayloadFragment = (
  { readonly __typename?: 'UserMatchEvent' }
  & Pick<TP.UserMatchEvent, 'id' | 'type'>
);

export const UserMatchEventPayloadFragmentDoc = gql`
    fragment UserMatchEventPayload on UserMatchEvent {
  id
  type
}
    `;