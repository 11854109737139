import { Card } from '@components/Card';
import { Icon } from '@components/Icon';
import { CSSObject } from '@emotion/react';
import { useFlagMe250106ViewFacilityTabOnMainpageSidebar } from '@generated/flags/ME-250106-view-facility-tab-on-mainpage-sidebar';
import { EmployeeInfoV2Fragment } from '@generated/fragments/employeeInfoV2';
import { EmployeeSimpleV2Fragment } from '@generated/fragments/employeeSimpleV2';
import { useTheme } from '@hooks/useTheme';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@reach/tabs';
import '@reach/tabs/styles.css';
import { FC } from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { EmployeeTree } from '../EmployeeTree';
import { GroupTree } from '../GroupTree';
import { List } from '../List';
import { SearchType } from '../Search/types';
import {
  EMPLOYEE_ENTITY_TABS_HEIGHT,
  EmployeeTreeNodeValue,
  SIDEBAR_BORDER_WIDTH,
  SIDEBAR_OPEN_WIDTH,
} from '../util';

const tabPanelStyle: CSSObject = { height: '100%', overflowY: 'hidden' };
interface Props {
  employee: EmployeeSimpleV2Fragment;
  isSidebarOpen: boolean;
  resetRepFilter: () => void;
  updateEmployeeTreeNodeValue: (emp: EmployeeTreeNodeValue) => void;
  searchType: SearchType;
}

export const EmployeeEntityTabs: FC<Props> = ({
  employee,
  isSidebarOpen,
  resetRepFilter,
  updateEmployeeTreeNodeValue,
  searchType,
}) => {
  const { card, colors, gray } = useTheme();

  const EmployeeTreeErrFallback: FC<FallbackProps> = ({ error }) => {
    return <Card css={{ height: '100%' }}>{error.message}</Card>;
  };
  const showFacilityTab = useFlagMe250106ViewFacilityTabOnMainpageSidebar();

  return (
    <Tabs
      css={{
        bottom: 1,
        position: 'relative',
        display: 'grid',
        gridTemplateRows: '1fr min-content',
        height: '100%',
        overflow: 'hidden',
      }}
      data-testid="entity-tabs-wrapper"
    >
      <TabPanels
        css={{
          padding: '0 0 16px 16px',
          height: '100%',
          overflowY: 'hidden',
        }}
      >
        <TabPanel
          css={{
            height: '100%',
            overflowY: 'auto',
            overflowX: 'auto',
          }}
        >
          {searchType == SearchType.rep && employee ? (
            <ErrorBoundary FallbackComponent={EmployeeTreeErrFallback}>
              <EmployeeTree
                updateEmployeeTreeNodeValue={updateEmployeeTreeNodeValue}
                employee={employee as EmployeeInfoV2Fragment}
              />
            </ErrorBoundary>
          ) : (
            ''
          )}
          {searchType == SearchType.group ? (
            <GroupTree
              updateEmployeeTreeNodeValue={updateEmployeeTreeNodeValue}
            />
          ) : (
            ''
          )}
        </TabPanel>
        <TabPanel css={tabPanelStyle}>
          <List
            key="carrier"
            searchType={SearchType.carrier}
            employee={employee}
            resetRepFilter={resetRepFilter}
          />
        </TabPanel>
        <TabPanel css={tabPanelStyle}>
          <List
            key="customer"
            searchType={SearchType.customer}
            employee={employee}
            resetRepFilter={resetRepFilter}
          />
        </TabPanel>
        {showFacilityTab && (
          <TabPanel css={tabPanelStyle}>
            <List
              key="facility"
              searchType={SearchType.facility}
              employee={employee}
              resetRepFilter={resetRepFilter}
            />
          </TabPanel>
        )}
      </TabPanels>
      <TabList
        data-testid="sidebar-bottom-icons"
        css={{
          width: isSidebarOpen ? SIDEBAR_OPEN_WIDTH - SIDEBAR_BORDER_WIDTH : 0,
          transform: 'width 250ms',
          display: 'grid',
          gridTemplate: `auto / repeat(${showFacilityTab ? 4 : 3}, auto)`,
          background: card.background,
          borderTop: `1px solid ${gray[70]}`,
          height: EMPLOYEE_ENTITY_TABS_HEIGHT,
          '> *': {
            whiteSpace: 'nowrap',
            padding: '16px',
            display: isSidebarOpen ? 'flex' : 'none',
            textDecoration: 'none',
            alignItems: 'center',
            justifyContent: 'center',

            '&[data-selected], &.active': {
              color: colors.primary,
              borderBottomColor: 'transparent',
            },
          },
          '[disabled]': {
            color: gray[70],
            cursor: 'default',
          },
        }}
      >
        <Tab data-testid="sidebar-people-tab" data-walkme="sidebar-users">
          <Icon
            i="usersGroup"
            size="lg"
            color="inherit"
            title="Employee Tree"
          />
        </Tab>
        <Tab data-testid="sidebar-carriers-tab" data-walkme="sidebar-carriers">
          <Icon i="truckMoving" size="lg" color="inherit" title="Carriers" />
        </Tab>
        <Tab
          data-testid="sidebar-customers-tab"
          data-walkme="sidebar-customers"
        >
          <Icon i="truckLoading" size="lg" color="inherit" title="Customers" />
        </Tab>
        {showFacilityTab && (
          <Tab
            data-testid="sidebar-facility-tab"
            data-walkme="sidebar-facilities"
          >
            <Icon i="facility" size="lg" color="inherit" title="Facility" />
          </Tab>
        )}
      </TabList>
    </Tabs>
  );
};
