import { Dropdown } from '@components/Dropdown';
import { Icon } from '@components/Icon';
import { isString } from 'lodash-es';
import { FC } from 'react';
import { CountrySelectComponentProps } from 'react-phone-number-input';

export const CountrySelect: FC<CountrySelectComponentProps> = (props) => {
  const { onChange, value, options, ...rest } = props;
  const selected = value;
  const items = options?.map((obj) => ({ ...obj })) || [];
  const selectedItem = items.find((obj) => obj.value === selected);
  let buttonText = selectedItem ? (
    <img
      src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${selectedItem.value}.svg`}
      title={isString(selectedItem.label) ? selectedItem.label : undefined}
      css={{ marginTop: 2 }}
    />
  ) : undefined;
  if (!selectedItem?.value) {
    buttonText = (
      <Icon i="globe" color="text" size="sm" css={{ marginLeft: 3 }} />
    );
  }
  return (
    <Dropdown
      buttonText={buttonText}
      items={items as fixMe}
      onChange={(item): void => onChange?.(item?.value as fixMe)}
      selectedItem={selectedItem as fixMe}
      css={{
        'button[aria-haspopup="listbox"]': {
          padding: '2px 15px 0px 2px',
        },
        '[data-icon]': {
          right: 2,
        },
      }}
      {...rest}
    />
  );
};
