import * as TP from '../types';

import { gql } from '@apollo/client';
import { KeyValueInfoFragmentDoc } from '../fragments/keyValueInfo';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetEmployeeGroupByIdv2QueryVariables = TP.Exact<{
  id: TP.Scalars['ID'];
}>;


export type GetEmployeeGroupByIdv2Query = (
  { readonly __typename?: 'Query' }
  & { readonly employee?: TP.Maybe<(
    { readonly __typename?: 'EmployeeV2' }
    & Pick<TP.EmployeeV2, 'id'>
    & { readonly employeeGroup?: TP.Maybe<(
      { readonly __typename?: 'KeyValue' }
      & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
    )> }
  )> }
);


export const GetEmployeeGroupByIdv2Document = gql`
    query getEmployeeGroupByIDV2($id: ID!) {
  employee: employeeV2(id: $id) {
    id
    employeeGroup {
      ...KeyValueInfo
    }
  }
}
    ${KeyValueInfoFragmentDoc}`;

/**
 * __useGetEmployeeGroupByIdv2Query__
 *
 * To run a query within a React component, call `useGetEmployeeGroupByIdv2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeGroupByIdv2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeGroupByIdv2Query({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEmployeeGroupByIdv2Query(baseOptions: Apollo.QueryHookOptions<GetEmployeeGroupByIdv2Query, GetEmployeeGroupByIdv2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmployeeGroupByIdv2Query, GetEmployeeGroupByIdv2QueryVariables>(GetEmployeeGroupByIdv2Document, options);
      }
export function useGetEmployeeGroupByIdv2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmployeeGroupByIdv2Query, GetEmployeeGroupByIdv2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmployeeGroupByIdv2Query, GetEmployeeGroupByIdv2QueryVariables>(GetEmployeeGroupByIdv2Document, options);
        }
export type GetEmployeeGroupByIdv2QueryHookResult = ReturnType<typeof useGetEmployeeGroupByIdv2Query>;
export type GetEmployeeGroupByIdv2LazyQueryHookResult = ReturnType<typeof useGetEmployeeGroupByIdv2LazyQuery>;
export type GetEmployeeGroupByIdv2QueryResult = Apollo.QueryResult<GetEmployeeGroupByIdv2Query, GetEmployeeGroupByIdv2QueryVariables>;