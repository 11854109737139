import { semanticMap } from '@components/Theme/newBaseColors';
import { ThemeColor } from '@emotion/react';
import { useTheme } from '@hooks/useTheme';
import { IS_CYPRESS } from '@utils/constants';
import { win } from '@utils/win';
import { isNumber, random as randomFn } from 'lodash-es';
import { FC, useEffect, useRef, useState } from 'react';
import { useInterval, useTimeout } from 'react-use';

export interface Props {
  loading: boolean;
  targetMillis: number;
  height?: number;
  background?: string | ThemeColor;
  /** Delay before the component renders - useful for avoiding quick unmount (flickering). Defaults to 0. */
  delay?: number;
  rounded?: boolean;
  /** AI branding version */
  ai?: boolean;
}

const DEFAULT_DESIRED_STEPS = 20;
export const DEFAULT_LOADING_BAR_HEIGHT = 3;

// eslint-disable-next-line mastery/named-colors
const DEFAULT_GRADIENT_SECOND_COLOR = '#0FABFF';

const random = IS_CYPRESS
  ? (min: number, max: number): number => {
      return min + (max - min) / 2;
    }
  : randomFn;

interface LoadingPercentProps {
  loading: boolean;
  targetMillis: number;
  /** Delay before the component renders - useful for avoiding quick unmount (flickering). Defaults to 0. */
  delay?: number;
  desiredSteps?: number;
}

export const useLoadingPercent = (
  props: LoadingPercentProps
): {
  percent: number;
  isReady: () => boolean | null;
} => {
  const {
    loading,
    targetMillis: targetMillisProp,
    delay: delayProp = 0,
    desiredSteps = DEFAULT_DESIRED_STEPS,
  } = props;
  const [isReady, , resetReady] = useTimeout(delayProp);
  const isReadyNow = isReady();
  const targetMillis = targetMillisProp - delayProp;
  const intervalMillis = targetMillis / desiredSteps;
  const [percentState, setPercent] = useState(0);
  const isRunning =
    isReadyNow && loading && isNumber(percentState) && percentState < 99;
  const cancelTimeout = useRef(0);
  useInterval(
    () => {
      setPercent((p) => {
        if (p === 0) {
          return 1;
        }
        const toGo = 100 - p;
        const toAdd = random(toGo / 10, toGo / 6, true);
        return p + toAdd;
      });
    },
    isRunning ? intervalMillis : null
  );
  useEffect(() => {
    if (!isReadyNow) {
      return;
    }
    if (!loading && percentState > 0) {
      setPercent(100);
      win.clearTimeout(cancelTimeout.current);
      cancelTimeout.current = win.setTimeout(() => {
        resetReady();
        setPercent(0);
      }, 300);
    } else if (loading) {
      win.clearTimeout(cancelTimeout.current);
    } else if (percentState !== 0) {
      resetReady();
      setPercent(0);
    }
  }, [loading, percentState, isReadyNow, resetReady]);
  const percent = isReadyNow ? percentState : 0;
  return { percent, isReady };
};

export const LoadingBar: FC<Props> = ({
  loading,
  targetMillis: targetMillisProp,
  height = DEFAULT_LOADING_BAR_HEIGHT,
  background: backgroundProp,
  delay: delayProp = 0,
  rounded,
  ai,
  ...rest
}) => {
  const { colors } = useTheme();
  const { percent } = useLoadingPercent({
    loading,
    targetMillis: targetMillisProp,
    delay: delayProp,
  });
  let firstColor = colors.primary;
  let secondColor = DEFAULT_GRADIENT_SECOND_COLOR;
  if (ai) {
    firstColor = semanticMap.primary[40];
    secondColor = semanticMap.cyan[70];
  }
  const background =
    colors[backgroundProp as ThemeColor] ||
    backgroundProp ||
    `linear-gradient(90deg, ${firstColor} 0%, ${secondColor} 100%)`;
  return (
    <div
      data-testid="component-loadingbar"
      data-percent={Math.round(percent)}
      css={{
        width: '100%',
        height,
        '&:after': {
          content: '""',
          display: 'block',
          opacity: percent === 100 ? '0' : '1',
          width: `${percent}%`,
          maxWidth: '100%',
          height: '100%',
          borderRadius: rounded ? 5 : 0,
          background,
          transition:
            percent === 0 || percent === 100
              ? 'opacity 300ms'
              : `all ${Math.round(targetMillisProp / 10)}ms ease-in-out`,
        },
      }}
      {...rest}
    />
  );
};
