import * as TP from '../subscriptionTypes';

import { gql } from '@apollo/client';
export type AvailableCapacityEventPayloadFragment = (
  { readonly __typename?: 'AvailableCapacityEvent' }
  & Pick<TP.AvailableCapacityEvent, 'id' | 'type' | 'capacityType' | 'utilizationStatus'>
);

export const AvailableCapacityEventPayloadFragmentDoc = gql`
    fragment AvailableCapacityEventPayload on AvailableCapacityEvent {
  id
  type
  capacityType
  utilizationStatus
}
    `;