import * as TP from '../types';

import { gql } from '@apollo/client';
export type FacilityNoteInfoFragment = (
  { readonly __typename?: 'FacilityNote' }
  & Pick<TP.FacilityNote, 'createdAt' | 'id' | 'noteType' | 'noteableId' | 'noteableType' | 'text' | 'updatedAt'>
);

export const FacilityNoteInfoFragmentDoc = gql`
    fragment FacilityNoteInfo on FacilityNote {
  createdAt
  id
  noteType
  noteableId
  noteableType
  text
  updatedAt
}
    `;