import { FormGroupProps } from '@components/Field';
// eslint-disable-next-line no-restricted-imports
import { PRIVATE_colors } from '@components/Theme/colors';
import { ThemeColors } from '@emotion/react';
import { FieldProps } from 'formik';
import { ReactNode } from 'react';
import { useTheme } from '../useTheme';

function hasStringError(errorMessage: string): boolean {
  return (
    errorMessage !== 'Required' && !errorMessage.match(/is a required field$/)
  );
}

function getFieldErrorMessage(
  formGroupProps: FormGroupProps<anyOk>,
  fieldProps: FieldProps
): [string | undefined, boolean] {
  let error: string | Record<string, unknown> | undefined =
    fieldProps.meta.error;

  /**
   * this check is because some forms are doing required check inside of the
   * schema, and yup writes the error message if you don't pass a custom one in
   */
  let hasError = false;

  if (error) {
    if (typeof error === 'string') {
      hasError = hasStringError(error);
    }
    if (
      typeof error === 'object' &&
      formGroupProps.status?.validationPropertyName
    ) {
      // when error is an object, it must have a status.validationPropertyName
      // to define which of its subproperties is being validated against
      error = error[formGroupProps.status.validationPropertyName];
      hasError = hasStringError(error);
    }
  }

  return [error, hasError];
}

export type PopupType =
  | keyof Pick<ThemeColors, 'success' | 'error' | 'warningDark'>
  | 'action';

export const getInputTheme = (
  formGroupProps: FormGroupProps<anyOk>,
  fieldProps: FieldProps
): {
  popupType: PopupType | undefined;
  info: string | ReactNode;
  hasError: boolean;
} => {
  const { warning, extraInfo, showSuccessIcon, actionTooltip } = formGroupProps;
  const warningText = warning ? warning(fieldProps.field.value) : undefined;
  const actionText = actionTooltip
    ? actionTooltip(fieldProps.field.value)
    : undefined;

  const [error, hasError] = getFieldErrorMessage(formGroupProps, fieldProps);

  const showSuccess = !error && !extraInfo && showSuccessIcon && !warningText;

  let popupType: PopupType | undefined;
  let info: ReactNode = '';
  if (showSuccess) {
    popupType = 'success';
  }
  if (actionText) {
    popupType = 'action';
    info = actionText;
  }
  if (warningText) {
    popupType = 'warningDark';
    info = warningText;
  }
  // The error should always take precendence over info and success messages
  if (hasError) {
    popupType = 'error';
    info = error || '';
  }
  return {
    popupType,
    info,
    hasError,
  };
};

export function useInputTheme(
  props: FormGroupProps<anyOk>,
  fieldProps: FieldProps
): {
  popupType: PopupType | undefined;
  info: string | ReactNode;
  borderColor: string;
  hasError: boolean;
  backgroundColor: string;
} {
  const { gray } = useTheme();

  const { popupType, info, hasError } = getInputTheme(props, fieldProps);
  const { disabled, readOnly } = props;

  const borderColor = popupType
    ? PRIVATE_colors[popupType as keyof typeof PRIVATE_colors]
    : gray[80];

  const backgroundColor = disabled || readOnly ? gray[95] : gray.white;

  return {
    popupType,
    info,
    borderColor,
    hasError,
    backgroundColor,
  };
}
