import * as TP from '../subscriptionTypes';

import { gql } from '@apollo/client';
export type RouteVendorEventPayloadFragment = (
  { readonly __typename?: 'RouteVendorEvent' }
  & Pick<TP.RouteVendorEvent, 'id' | 'loadId' | 'routeId' | 'type'>
);

export const RouteVendorEventPayloadFragmentDoc = gql`
    fragment RouteVendorEventPayload on RouteVendorEvent {
  id
  loadId
  routeId
  type
}
    `;