import { win } from '@utils/win';

export const getGlobalVariable = <T>(symbol: anyOk): T => {
  return win[symbol] as unknown as T;
};

// ts-unused-exports:disable-next-line
export const setGlobalVariable = (key: anyOk, value: anyOk): anyOk => {
  win[key] = value;
};
