/* eslint-disable mastery/named-colors */
import { BaseColors } from '@emotion/react';

const blue: BaseColors = {
  10: '#001A41',
  20: '#002E69',
  30: '#004493',
  40: '#005BC0',
  50: '#1E73E8',
  60: '#4B8EFF',
  70: '#80ABFF',
  80: '#ADC6FF',
  90: '#D8E2FF',
  95: '#EDF0FF',
  97: '#F5F7FF',
  99: '#FCFCFF',
};

const aqua: BaseColors = {
  10: '#001F26',
  20: '#003640',
  30: '#004E5C',
  40: '#00687A',
  50: '#008399',
  60: '#009FB9',
  70: '#2DBAD7',
  80: '#55D6F4',
  90: '#ABEDFF',
  95: '#D8F6FF',
  97: '#F8FDFF',
  99: '#ECFBFF',
};

const purple: BaseColors = {
  10: '#30004A',
  20: '#500076',
  30: '#7100A5',
  40: '#9216D0',
  50: '#AE3EEB',
  60: '#C762FF',
  70: '#D78DFF',
  80: '#E7B4FF',
  90: '#F5D9FF',
  95: '#FCEBFF',
  97: '#FDF4FF',
  99: '#FEFBFF',
};

const green: BaseColors = {
  10: '#002113',
  20: '#003823',
  30: '#005235',
  40: '#006C47',
  50: '#00885B',
  60: '#20A471',
  70: '#46C08A',
  80: '#65DCA4',
  90: '#B0EFD0',
  95: '#CCFFE2',
  97: '#DCFFEB',
  99: '#F4FFF5',
};

const yellow: BaseColors = {
  10: '#291800',
  20: '#442B00',
  30: '#614000',
  40: '#815600',
  50: '#C38400',
  60: '#F5A700',
  70: '#FFCB5C',
  80: '#FFD885',
  90: '#FFD885',
  95: '#FFECC2',
  97: '#FFF6E1',
  99: '#FFFCF6',
};

const red: BaseColors = {
  10: '#410002',
  20: '#690004',
  30: '#930009',
  40: '#BA1A1A',
  50: '#DE372F',
  60: '#FB6156',
  70: '#FF897D',
  80: '#FFB4AB',
  90: '#FFDAD5',
  95: '#FEE7EB',
  97: '#FFF3F5',
  99: '#FFFBFF',
};

const neutral: BaseColors = {
  0: '#000000',
  10: '#1B1B1F',
  20: '#303033',
  30: '#46464A',
  40: '#5E5E62',
  50: '#77777A',
  60: '#919094',
  70: '#ABABAF',
  80: '#C7C6CA',
  90: '#E3E2E6',
  95: '#F2F0F4',
  99: '#FEFBFF',
  100: '#FFFFFF',
};

const neutralVariant: BaseColors = {
  0: '#000000',
  10: '#191C22',
  20: '#2D3038',
  30: '#44474F',
  40: '#5C5E66',
  50: '#74777F',
  60: '#8E9099',
  70: '#A9ABB4',
  80: '#C4C6D0',
  90: '#E1E2EC',
  95: '#EFF0FA',
  99: '#FEFBFF',
  100: '#FFFFFF',
};

export const baseColors = {
  blue,
  aqua,
  purple,
  green,
  yellow,
  red,
  neutral,
  neutralVariant,
};
