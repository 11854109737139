import { BODY_FONT_SIZE, INPUT_HEIGHT } from '@components/Theme/constants';
import { useTheme } from '@hooks/useTheme';

export const useInputStyle = (): Record<string, anyOk> => {
  const { colors, gray, fonts, formElement } = useTheme();
  return {
    // we are listing out individual attributes instead of using the "border" shorthand because of a difference in how it is rendered in cypress tests.
    borderWidth: 1,
    borderStyle: 'solid',
    borderTopColor: colors.text,
    borderRightColor: colors.text,
    borderBottomColor: colors.text,
    borderLeftColor: colors.text,
    borderRadius: 3,
    color: 'inherit',
    fontSize: BODY_FONT_SIZE,
    fontFamily: fonts.main,
    padding: 8,
    width: '100%',
    display: 'block',
    lineHeight: 'normal',
    borderColor: formElement.borderColor,
    background: formElement.background,
    height: INPUT_HEIGHT,
    '&:focus': formElement.focused,
    '&.validation-error:focus': formElement.hasError,
    '&.validation-error': formElement.hasError,
    '&::placeholder': {
      color: gray[70],
    },
    '&::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    '&::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    '&[type=number]': {
      MozAppearance: 'textfield',
    },
  };
};
