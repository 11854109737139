import { mapKeys, pickBy } from 'lodash-es';
import { FC, memo } from 'react';
import { Padding, PaddingProps } from '../Padding';

interface Props extends PaddingProps {
  /** Size */
  s1?: boolean;
  s2?: boolean;
  s3?: boolean;
  s4?: boolean;
  s5?: boolean;
  s6?: boolean;
}

const SpacerComp: FC<Props> = ({ s1, s2, s3, s4, s5, ...props }) => {
  const collection = pickBy({ s1, s2, s3, s4, s5 });
  let mapped = mapKeys(collection, (v, k) => `t${k[1]}`);
  if (!Object.values(mapped).length) {
    mapped = { t1: true };
  }
  return <Padding {...props} {...mapped} />;
};

export const Spacer = memo(SpacerComp);
