import * as TP from '../types';

import { gql } from '@apollo/client';
import { KeyValueInfoFragmentDoc } from './keyValueInfo';
export type EmployeeGroupsInfoV2Fragment = (
  { readonly __typename?: 'EmployeeV2' }
  & { readonly employeeGroups?: TP.Maybe<ReadonlyArray<(
    { readonly __typename?: 'KeyValue' }
    & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
  )>> }
);

export const EmployeeGroupsInfoV2FragmentDoc = gql`
    fragment EmployeeGroupsInfoV2 on EmployeeV2 {
  employeeGroups {
    ...KeyValueInfo
  }
}
    ${KeyValueInfoFragmentDoc}`;