import * as TP from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SeerMainPageSideBarSummaryCountsQueryVariables = TP.Exact<{
  filter?: TP.InputMaybe<TP.MainPageSideBarSummaryCountsFilterInput>;
}>;


export type SeerMainPageSideBarSummaryCountsQuery = (
  { readonly __typename?: 'Query' }
  & { readonly seerMainPageSideBarSummaryCounts: (
    { readonly __typename?: 'MainPageSideBarSummaryCounts' }
    & Pick<TP.MainPageSideBarSummaryCounts, 'id' | 'pendingCount' | 'schedulingCount' | 'incidentsCount' | 'trackingCount' | 'orderListCount' | 'routeListCount'>
  ) }
);


export const SeerMainPageSideBarSummaryCountsDocument = gql`
    query SeerMainPageSideBarSummaryCounts($filter: MainPageSideBarSummaryCountsFilterInput) {
  seerMainPageSideBarSummaryCounts(filter: $filter) {
    id
    pendingCount
    schedulingCount
    incidentsCount
    trackingCount
    orderListCount
    routeListCount
  }
}
    `;

/**
 * __useSeerMainPageSideBarSummaryCountsQuery__
 *
 * To run a query within a React component, call `useSeerMainPageSideBarSummaryCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSeerMainPageSideBarSummaryCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSeerMainPageSideBarSummaryCountsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSeerMainPageSideBarSummaryCountsQuery(baseOptions?: Apollo.QueryHookOptions<SeerMainPageSideBarSummaryCountsQuery, SeerMainPageSideBarSummaryCountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SeerMainPageSideBarSummaryCountsQuery, SeerMainPageSideBarSummaryCountsQueryVariables>(SeerMainPageSideBarSummaryCountsDocument, options);
      }
export function useSeerMainPageSideBarSummaryCountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SeerMainPageSideBarSummaryCountsQuery, SeerMainPageSideBarSummaryCountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SeerMainPageSideBarSummaryCountsQuery, SeerMainPageSideBarSummaryCountsQueryVariables>(SeerMainPageSideBarSummaryCountsDocument, options);
        }
export type SeerMainPageSideBarSummaryCountsQueryHookResult = ReturnType<typeof useSeerMainPageSideBarSummaryCountsQuery>;
export type SeerMainPageSideBarSummaryCountsLazyQueryHookResult = ReturnType<typeof useSeerMainPageSideBarSummaryCountsLazyQuery>;
export type SeerMainPageSideBarSummaryCountsQueryResult = Apollo.QueryResult<SeerMainPageSideBarSummaryCountsQuery, SeerMainPageSideBarSummaryCountsQueryVariables>;