export enum UnitOfDensityEnum {
  Lbft3 = 'lb/ft3',
  Lbin3 = 'lb/in3',
  Cwtft3 = 'cwt/ft3',
  Kgcm3 = 'kg/cm3',
  Kgm3 = 'kg/m3',
}

export enum VolumeUnitTypeEnumV2 {
  Cm3 = 'cm3',
  Ft3 = 'ft3',
  In3 = 'in3',
  M3 = 'm3',
}

export enum MassUnitTypeEnumV2 {
  Mt = 'MT',
  Cwt = 'cwt',
  G = 'g',
  Kg = 'kg',
  Lb = 'lb',
  Lbs = 'lbs',
  Oz = 'oz',
  St = 'st',
  T = 't',
  Tonnes = 'tonnes',
  Tons = 'tons',
}

export enum TemperatureUnitTypeEnumV2 {
  C = 'c',
  F = 'f',
}

export enum UnitOfLengthEnumV2 {
  Cm = 'cm',
  Ft = 'ft',
  In = 'in',
  Km = 'km',
  M = 'm',
  Mi = 'mi',
}

export enum UnitOfTimeEnum {
  Hr = 'hr',
  Hours = 'hours',
  Mn = 'min',
  Minutes = 'minutes',
  Days = 'days',
}
