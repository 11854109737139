import * as TP from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SecureDataTokenizationConfigurationQueryVariables = TP.Exact<{
  input: TP.SecureDataTokenizationConfigurationInput;
}>;


export type SecureDataTokenizationConfigurationQuery = (
  { readonly __typename?: 'Query' }
  & { readonly secureDataTokenizationConfiguration: (
    { readonly __typename?: 'SecureDataTokenizationConfigurationModel' }
    & Pick<TP.SecureDataTokenizationConfigurationModel, 'iFrameApiKey'>
    & { readonly tokenRequestFields?: TP.Maybe<(
      { readonly __typename?: 'SecureDataTokenRequestFieldsModel' }
      & Pick<TP.SecureDataTokenRequestFieldsModel, 'containers' | 'fingerprintExpression' | 'id' | 'mask' | 'searchIndexes' | 'type'>
    )> }
  ) }
);


export const SecureDataTokenizationConfigurationDocument = gql`
    query secureDataTokenizationConfiguration($input: SecureDataTokenizationConfigurationInput!) {
  secureDataTokenizationConfiguration(input: $input) {
    iFrameApiKey
    tokenRequestFields {
      containers
      fingerprintExpression
      id
      mask
      searchIndexes
      type
    }
  }
}
    `;

/**
 * __useSecureDataTokenizationConfigurationQuery__
 *
 * To run a query within a React component, call `useSecureDataTokenizationConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useSecureDataTokenizationConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSecureDataTokenizationConfigurationQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSecureDataTokenizationConfigurationQuery(baseOptions: Apollo.QueryHookOptions<SecureDataTokenizationConfigurationQuery, SecureDataTokenizationConfigurationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SecureDataTokenizationConfigurationQuery, SecureDataTokenizationConfigurationQueryVariables>(SecureDataTokenizationConfigurationDocument, options);
      }
export function useSecureDataTokenizationConfigurationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SecureDataTokenizationConfigurationQuery, SecureDataTokenizationConfigurationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SecureDataTokenizationConfigurationQuery, SecureDataTokenizationConfigurationQueryVariables>(SecureDataTokenizationConfigurationDocument, options);
        }
export type SecureDataTokenizationConfigurationQueryHookResult = ReturnType<typeof useSecureDataTokenizationConfigurationQuery>;
export type SecureDataTokenizationConfigurationLazyQueryHookResult = ReturnType<typeof useSecureDataTokenizationConfigurationLazyQuery>;
export type SecureDataTokenizationConfigurationQueryResult = Apollo.QueryResult<SecureDataTokenizationConfigurationQuery, SecureDataTokenizationConfigurationQueryVariables>;