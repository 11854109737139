import * as TP from '../types';

import { gql } from '@apollo/client';
export type CarrierInfoSidebarV2Fragment = (
  { readonly __typename?: 'CarrierV2' }
  & Pick<TP.CarrierV2, 'id' | 'name' | 'code'>
  & { readonly mainAddress?: TP.Maybe<(
    { readonly __typename?: 'CarrierAddress' }
    & Pick<TP.CarrierAddress, 'id' | 'city' | 'state'>
  )> }
);

export const CarrierInfoSidebarV2FragmentDoc = gql`
    fragment CarrierInfoSidebarV2 on CarrierV2 {
  id
  name
  code
  mainAddress {
    id
    city
    state
  }
}
    `;