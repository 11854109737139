import * as TP from '../types';

import { gql } from '@apollo/client';
import { AddressBriefFragmentDoc } from './addressBrief';
import { KeyValueInfoFragmentDoc } from './keyValueInfo';
import { ContactInfoFragmentDoc } from './contactInfo';
import { NoteInfoFragmentDoc } from './noteInfo';
import { ScheduleInfoFragmentDoc } from './scheduleInfo';
import { FacilityAddressBriefFragmentDoc } from './FacilityAddressBrief';
import { FacilityContactInfoFragmentDoc } from './FacilityContactInfo';
import { FacilityNoteInfoFragmentDoc } from './FacilityNoteInfo';
import { ScheduleInfoV2FragmentDoc } from './scheduleInfoV2';
export type FacilityInfoForFacilityPickerFragment = (
  { readonly __typename?: 'Facility' }
  & Pick<TP.Facility, 'code' | 'facilityNote' | 'id' | 'name' | 'phoneNumber' | 'scaleNote' | 'schedulingContact' | 'sourceType' | 'status' | 'timezone' | 'taxExempt'>
  & { readonly addresses: ReadonlyArray<(
    { readonly __typename?: 'Address' }
    & Pick<TP.Address, 'id' | 'addressableId' | 'addressableType' | 'addressTypeId' | 'city' | 'country' | 'main' | 'postalCode' | 'state' | 'street1' | 'street2' | 'isVerified' | 'riskInfoSource' | 'thirdPartyId'>
    & { readonly addressType?: TP.Maybe<(
      { readonly __typename?: 'KeyValue' }
      & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
    )>, readonly geopoint: (
      { readonly __typename?: 'Geopoint' }
      & Pick<TP.Geopoint, 'lon' | 'lat'>
    ) }
  )>, readonly mainAddress?: TP.Maybe<(
    { readonly __typename?: 'Address' }
    & Pick<TP.Address, 'id' | 'addressableId' | 'addressableType' | 'addressTypeId' | 'city' | 'country' | 'main' | 'postalCode' | 'state' | 'street1' | 'street2' | 'isVerified' | 'riskInfoSource' | 'thirdPartyId'>
    & { readonly addressType?: TP.Maybe<(
      { readonly __typename?: 'KeyValue' }
      & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
    )>, readonly geopoint: (
      { readonly __typename?: 'Geopoint' }
      & Pick<TP.Geopoint, 'lon' | 'lat'>
    ) }
  )>, readonly contacts: ReadonlyArray<(
    { readonly __typename?: 'Contact' }
    & Pick<TP.Contact, 'id' | 'name' | 'contactTypeId' | 'main' | 'phoneNumber' | 'extension' | 'faxNumber' | 'emailAddress' | 'chatUsername' | 'chatTypeId' | 'riskInfoSource' | 'thirdPartyId'>
    & { readonly chatType?: TP.Maybe<(
      { readonly __typename?: 'KeyValue' }
      & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
    )> }
  )>, readonly externalNotes?: TP.Maybe<ReadonlyArray<(
    { readonly __typename?: 'Note' }
    & Pick<TP.Note, 'createdAt' | 'id' | 'noteType' | 'noteableId' | 'noteableType' | 'text' | 'updatedAt'>
  )>>, readonly facilityType?: TP.Maybe<(
    { readonly __typename?: 'KeyValue' }
    & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
  )>, readonly loadFromType?: TP.Maybe<(
    { readonly __typename?: 'KeyValue' }
    & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
  )>, readonly schedulingSystemType?: TP.Maybe<(
    { readonly __typename?: 'KeyValue' }
    & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
  )>, readonly schedules: ReadonlyArray<(
    { readonly __typename?: 'Schedule' }
    & Pick<TP.Schedule, 'appointmentType' | 'dropType' | 'serviceType' | 'trailerTypeIdNullable' | 'id' | 'modeType' | 'scheduleTypeNullable' | 'workType' | 'loadSizeId' | 'routeTransportModeId'>
    & { readonly trailerTypeNullable?: TP.Maybe<(
      { readonly __typename?: 'KeyValue' }
      & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
    )>, readonly loadSize?: TP.Maybe<(
      { readonly __typename?: 'KeyValue' }
      & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
    )>, readonly routeTransportMode?: TP.Maybe<(
      { readonly __typename?: 'KeyValue' }
      & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
    )>, readonly sunday: (
      { readonly __typename?: 'ScheduleEntry' }
      & Pick<TP.ScheduleEntry, 'closed' | 'id'>
      & { readonly hours: ReadonlyArray<(
        { readonly __typename?: 'ScheduleRange' }
        & Pick<TP.ScheduleRange, 'endOffsetMs' | 'id' | 'startOffsetMs'>
      )> }
    ), readonly monday: (
      { readonly __typename?: 'ScheduleEntry' }
      & Pick<TP.ScheduleEntry, 'closed' | 'id'>
      & { readonly hours: ReadonlyArray<(
        { readonly __typename?: 'ScheduleRange' }
        & Pick<TP.ScheduleRange, 'endOffsetMs' | 'id' | 'startOffsetMs'>
      )> }
    ), readonly tuesday: (
      { readonly __typename?: 'ScheduleEntry' }
      & Pick<TP.ScheduleEntry, 'closed' | 'id'>
      & { readonly hours: ReadonlyArray<(
        { readonly __typename?: 'ScheduleRange' }
        & Pick<TP.ScheduleRange, 'endOffsetMs' | 'id' | 'startOffsetMs'>
      )> }
    ), readonly wednesday: (
      { readonly __typename?: 'ScheduleEntry' }
      & Pick<TP.ScheduleEntry, 'closed' | 'id'>
      & { readonly hours: ReadonlyArray<(
        { readonly __typename?: 'ScheduleRange' }
        & Pick<TP.ScheduleRange, 'endOffsetMs' | 'id' | 'startOffsetMs'>
      )> }
    ), readonly thursday: (
      { readonly __typename?: 'ScheduleEntry' }
      & Pick<TP.ScheduleEntry, 'closed' | 'id'>
      & { readonly hours: ReadonlyArray<(
        { readonly __typename?: 'ScheduleRange' }
        & Pick<TP.ScheduleRange, 'endOffsetMs' | 'id' | 'startOffsetMs'>
      )> }
    ), readonly friday: (
      { readonly __typename?: 'ScheduleEntry' }
      & Pick<TP.ScheduleEntry, 'closed' | 'id'>
      & { readonly hours: ReadonlyArray<(
        { readonly __typename?: 'ScheduleRange' }
        & Pick<TP.ScheduleRange, 'endOffsetMs' | 'id' | 'startOffsetMs'>
      )> }
    ), readonly saturday: (
      { readonly __typename?: 'ScheduleEntry' }
      & Pick<TP.ScheduleEntry, 'closed' | 'id'>
      & { readonly hours: ReadonlyArray<(
        { readonly __typename?: 'ScheduleRange' }
        & Pick<TP.ScheduleRange, 'endOffsetMs' | 'id' | 'startOffsetMs'>
      )> }
    ) }
  )>, readonly unloadFromType?: TP.Maybe<(
    { readonly __typename?: 'KeyValue' }
    & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
  )>, readonly customerFacilities?: TP.Maybe<ReadonlyArray<(
    { readonly __typename?: 'CustomerFacility' }
    & Pick<TP.CustomerFacility, 'id' | 'customerId' | 'facilityId' | 'schedulingSystem' | 'drop' | 'isAutoScheduleEligible' | 'isAutoScheduleDefault'>
  )>>, readonly facilityLoadDefaults?: TP.Maybe<(
    { readonly __typename?: 'FacilityLoadDefaults' }
    & Pick<TP.FacilityLoadDefaults, 'id' | 'facilityId' | 'schedSystem' | 'autoSchedEligible' | 'autoSchedDefault'>
  )> }
);

export type FacilityInfoV2ForFacilityPickerFragment = (
  { readonly __typename?: 'FacilityV2' }
  & Pick<TP.FacilityV2, 'code' | 'facilityNote' | 'id' | 'name' | 'phoneNumber' | 'scaleNote' | 'schedulingContact' | 'sourceType' | 'status' | 'timezone' | 'taxExempt'>
  & { readonly addresses: ReadonlyArray<(
    { readonly __typename?: 'FacilityAddress' }
    & Pick<TP.FacilityAddress, 'id' | 'addressTypeId' | 'city' | 'country' | 'main' | 'postalCode' | 'state' | 'street1' | 'street2' | 'county' | 'riskInfoSource' | 'thirdPartyId' | 'isVerified'>
    & { readonly addressType?: TP.Maybe<(
      { readonly __typename?: 'KeyValue' }
      & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
    )>, readonly addressTypes?: TP.Maybe<ReadonlyArray<(
      { readonly __typename?: 'KeyValue' }
      & Pick<TP.KeyValue, 'id'>
    )>>, readonly geopoint: (
      { readonly __typename?: 'Geopoint' }
      & Pick<TP.Geopoint, 'lon' | 'lat'>
    ), readonly geopointNullable?: TP.Maybe<(
      { readonly __typename?: 'Geopoint' }
      & Pick<TP.Geopoint, 'lat' | 'lon'>
    )>, readonly customGeopoint?: TP.Maybe<(
      { readonly __typename?: 'Geopoint' }
      & Pick<TP.Geopoint, 'lat' | 'lon'>
    )> }
  )>, readonly mainAddress?: TP.Maybe<(
    { readonly __typename?: 'FacilityAddress' }
    & Pick<TP.FacilityAddress, 'id' | 'addressTypeId' | 'city' | 'country' | 'main' | 'postalCode' | 'state' | 'street1' | 'street2' | 'county' | 'riskInfoSource' | 'thirdPartyId' | 'isVerified'>
    & { readonly addressType?: TP.Maybe<(
      { readonly __typename?: 'KeyValue' }
      & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
    )>, readonly addressTypes?: TP.Maybe<ReadonlyArray<(
      { readonly __typename?: 'KeyValue' }
      & Pick<TP.KeyValue, 'id'>
    )>>, readonly geopoint: (
      { readonly __typename?: 'Geopoint' }
      & Pick<TP.Geopoint, 'lon' | 'lat'>
    ), readonly geopointNullable?: TP.Maybe<(
      { readonly __typename?: 'Geopoint' }
      & Pick<TP.Geopoint, 'lat' | 'lon'>
    )>, readonly customGeopoint?: TP.Maybe<(
      { readonly __typename?: 'Geopoint' }
      & Pick<TP.Geopoint, 'lat' | 'lon'>
    )> }
  )>, readonly contacts: ReadonlyArray<(
    { readonly __typename?: 'FacilityContact' }
    & Pick<TP.FacilityContact, 'id' | 'name' | 'contactTypeId' | 'main' | 'phoneNumber' | 'extension' | 'faxNumber' | 'emailAddress' | 'chatUsername' | 'chatTypeId' | 'riskInfoSource' | 'thirdPartyId'>
    & { readonly chatType?: TP.Maybe<(
      { readonly __typename?: 'KeyValue' }
      & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
    )> }
  )>, readonly externalNotes?: TP.Maybe<ReadonlyArray<(
    { readonly __typename?: 'FacilityNote' }
    & Pick<TP.FacilityNote, 'createdAt' | 'id' | 'noteType' | 'noteableId' | 'noteableType' | 'text' | 'updatedAt'>
  )>>, readonly facilityType?: TP.Maybe<(
    { readonly __typename?: 'KeyValue' }
    & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
  )>, readonly loadFromType?: TP.Maybe<(
    { readonly __typename?: 'KeyValue' }
    & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
  )>, readonly schedulingSystemType?: TP.Maybe<(
    { readonly __typename?: 'KeyValue' }
    & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
  )>, readonly schedules: ReadonlyArray<(
    { readonly __typename?: 'ScheduleV2' }
    & Pick<TP.ScheduleV2, 'appointmentType' | 'dropType' | 'serviceType' | 'trailerTypeId' | 'trailerTypeIdNullable' | 'id' | 'modeType' | 'scheduleType' | 'scheduleTypeNullable' | 'workType' | 'loadSizeId' | 'temperatureId' | 'routeTransportModeId'>
    & { readonly appointmentTypes?: TP.Maybe<ReadonlyArray<(
      { readonly __typename?: 'KeyValue' }
      & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
    )>>, readonly dropTypes?: TP.Maybe<ReadonlyArray<(
      { readonly __typename?: 'KeyValue' }
      & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
    )>>, readonly trailerType: (
      { readonly __typename?: 'KeyValue' }
      & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
    ), readonly trailerTypeNullable?: TP.Maybe<(
      { readonly __typename?: 'KeyValue' }
      & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
    )>, readonly trailerTypes?: TP.Maybe<ReadonlyArray<(
      { readonly __typename?: 'KeyValue' }
      & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
    )>>, readonly scheduleTypes?: TP.Maybe<ReadonlyArray<(
      { readonly __typename?: 'KeyValue' }
      & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
    )>>, readonly workTypes?: TP.Maybe<ReadonlyArray<(
      { readonly __typename?: 'KeyValue' }
      & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
    )>>, readonly loadSize?: TP.Maybe<(
      { readonly __typename?: 'KeyValue' }
      & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
    )>, readonly loadSizes?: TP.Maybe<ReadonlyArray<(
      { readonly __typename?: 'KeyValue' }
      & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
    )>>, readonly temperatures?: TP.Maybe<ReadonlyArray<(
      { readonly __typename?: 'KeyValue' }
      & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
    )>>, readonly routeTransportMode?: TP.Maybe<(
      { readonly __typename?: 'KeyValue' }
      & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
    )>, readonly sunday: (
      { readonly __typename?: 'ScheduleEntryV2' }
      & Pick<TP.ScheduleEntryV2, 'closed' | 'id'>
      & { readonly hours: ReadonlyArray<(
        { readonly __typename?: 'ScheduleRangeV2' }
        & Pick<TP.ScheduleRangeV2, 'endOffsetMs' | 'id' | 'startOffsetMs'>
      )> }
    ), readonly monday: (
      { readonly __typename?: 'ScheduleEntryV2' }
      & Pick<TP.ScheduleEntryV2, 'closed' | 'id'>
      & { readonly hours: ReadonlyArray<(
        { readonly __typename?: 'ScheduleRangeV2' }
        & Pick<TP.ScheduleRangeV2, 'endOffsetMs' | 'id' | 'startOffsetMs'>
      )> }
    ), readonly tuesday: (
      { readonly __typename?: 'ScheduleEntryV2' }
      & Pick<TP.ScheduleEntryV2, 'closed' | 'id'>
      & { readonly hours: ReadonlyArray<(
        { readonly __typename?: 'ScheduleRangeV2' }
        & Pick<TP.ScheduleRangeV2, 'endOffsetMs' | 'id' | 'startOffsetMs'>
      )> }
    ), readonly wednesday: (
      { readonly __typename?: 'ScheduleEntryV2' }
      & Pick<TP.ScheduleEntryV2, 'closed' | 'id'>
      & { readonly hours: ReadonlyArray<(
        { readonly __typename?: 'ScheduleRangeV2' }
        & Pick<TP.ScheduleRangeV2, 'endOffsetMs' | 'id' | 'startOffsetMs'>
      )> }
    ), readonly thursday: (
      { readonly __typename?: 'ScheduleEntryV2' }
      & Pick<TP.ScheduleEntryV2, 'closed' | 'id'>
      & { readonly hours: ReadonlyArray<(
        { readonly __typename?: 'ScheduleRangeV2' }
        & Pick<TP.ScheduleRangeV2, 'endOffsetMs' | 'id' | 'startOffsetMs'>
      )> }
    ), readonly friday: (
      { readonly __typename?: 'ScheduleEntryV2' }
      & Pick<TP.ScheduleEntryV2, 'closed' | 'id'>
      & { readonly hours: ReadonlyArray<(
        { readonly __typename?: 'ScheduleRangeV2' }
        & Pick<TP.ScheduleRangeV2, 'endOffsetMs' | 'id' | 'startOffsetMs'>
      )> }
    ), readonly saturday: (
      { readonly __typename?: 'ScheduleEntryV2' }
      & Pick<TP.ScheduleEntryV2, 'closed' | 'id'>
      & { readonly hours: ReadonlyArray<(
        { readonly __typename?: 'ScheduleRangeV2' }
        & Pick<TP.ScheduleRangeV2, 'endOffsetMs' | 'id' | 'startOffsetMs'>
      )> }
    ) }
  )>, readonly unloadFromType?: TP.Maybe<(
    { readonly __typename?: 'KeyValue' }
    & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
  )>, readonly customerFacilities?: TP.Maybe<ReadonlyArray<(
    { readonly __typename?: 'CustomerFacility' }
    & Pick<TP.CustomerFacility, 'id' | 'customerId' | 'facilityId' | 'schedulingSystem' | 'drop' | 'isAutoScheduleEligible' | 'isAutoScheduleDefault'>
  )>>, readonly facilityLoadDefaults?: TP.Maybe<(
    { readonly __typename?: 'FacilityLoadDefaults' }
    & Pick<TP.FacilityLoadDefaults, 'id' | 'facilityId' | 'schedSystem' | 'autoSchedEligible' | 'autoSchedDefault'>
  )> }
);

export const FacilityInfoForFacilityPickerFragmentDoc = gql`
    fragment FacilityInfoForFacilityPicker on Facility {
  addresses {
    ...AddressBrief
  }
  mainAddress {
    ...AddressBrief
  }
  contacts {
    ...ContactInfo
  }
  code
  externalNotes {
    ...NoteInfo
  }
  facilityNote
  facilityType {
    ...KeyValueInfo
  }
  id
  loadFromType {
    ...KeyValueInfo
  }
  name
  phoneNumber
  scaleNote
  schedulingSystemType {
    ...KeyValueInfo
  }
  schedules {
    ...ScheduleInfo
  }
  schedulingContact
  sourceType
  status
  timezone
  taxExempt
  unloadFromType {
    ...KeyValueInfo
  }
  customerFacilities(customerIds: $customerIds) {
    id
    customerId
    facilityId
    schedulingSystem
    drop
    isAutoScheduleEligible
    isAutoScheduleDefault
  }
  facilityLoadDefaults {
    id
    facilityId
    schedSystem
    autoSchedEligible
    autoSchedDefault
  }
}
    ${AddressBriefFragmentDoc}
${ContactInfoFragmentDoc}
${NoteInfoFragmentDoc}
${KeyValueInfoFragmentDoc}
${ScheduleInfoFragmentDoc}`;
export const FacilityInfoV2ForFacilityPickerFragmentDoc = gql`
    fragment FacilityInfoV2ForFacilityPicker on FacilityV2 {
  addresses {
    ...FacilityAddressBrief
  }
  mainAddress {
    ...FacilityAddressBrief
  }
  contacts {
    ...FacilityContactInfo
  }
  code
  externalNotes {
    ...FacilityNoteInfo
  }
  facilityNote
  facilityType {
    ...KeyValueInfo
  }
  id
  loadFromType {
    ...KeyValueInfo
  }
  name
  phoneNumber
  scaleNote
  schedulingSystemType {
    ...KeyValueInfo
  }
  schedules {
    ...ScheduleInfoV2
  }
  schedulingContact
  sourceType
  status
  timezone
  taxExempt
  unloadFromType {
    ...KeyValueInfo
  }
  customerFacilities(customerIds: $customerIds) {
    id
    customerId
    facilityId
    schedulingSystem
    drop
    isAutoScheduleEligible
    isAutoScheduleDefault
  }
  facilityLoadDefaults {
    id
    facilityId
    schedSystem
    autoSchedEligible
    autoSchedDefault
  }
}
    ${FacilityAddressBriefFragmentDoc}
${FacilityContactInfoFragmentDoc}
${FacilityNoteInfoFragmentDoc}
${KeyValueInfoFragmentDoc}
${ScheduleInfoV2FragmentDoc}`;