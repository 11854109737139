import * as TP from '../types';

import { gql } from '@apollo/client';
import { ScheduleRangeInfoV2FragmentDoc } from './scheduleRangeInfoV2';
export type ScheduleEntryInfoV2Fragment = (
  { readonly __typename?: 'ScheduleEntryV2' }
  & Pick<TP.ScheduleEntryV2, 'closed' | 'id'>
  & { readonly hours: ReadonlyArray<(
    { readonly __typename?: 'ScheduleRangeV2' }
    & Pick<TP.ScheduleRangeV2, 'endOffsetMs' | 'id' | 'startOffsetMs'>
  )> }
);

export const ScheduleEntryInfoV2FragmentDoc = gql`
    fragment ScheduleEntryInfoV2 on ScheduleEntryV2 {
  closed
  hours {
    ...ScheduleRangeInfoV2
  }
  id
}
    ${ScheduleRangeInfoV2FragmentDoc}`;