import { SystemAlertType } from '@components/SystemAlerts/shared/constants';
import { win } from '@utils/win';
import { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import {
  ActionProps,
  DismissProps,
  StatusDialog,
  useStatusDialog,
} from '../StatusDialogComponents';
import { GLOBAL_DIALOG_EVENT_NAME } from '../constants';

interface GlobalStatusDialogStateProps {
  dismiss: DismissProps;
  action: ActionProps;
  type: SystemAlertType;
  title?: string | undefined;
  content: ReactNode;
}

export const GlobalStatusDialog: FC = () => {
  const globalDialogProps = useStatusDialog({});
  const [modalState, setModalState] = useState<GlobalStatusDialogStateProps>();

  const handleGlobalDialog = useCallback(
    (event: CustomEventInit<anyOk> & { type: 'globalDialog' }) => {
      const { detail } = event;

      setModalState(detail);

      globalDialogProps.state.toggle();
    },
    [globalDialogProps.state]
  );

  useEffect(() => {
    win.addEventListener(
      GLOBAL_DIALOG_EVENT_NAME,
      handleGlobalDialog as EventListener
    );

    return () => {
      win.removeEventListener(
        GLOBAL_DIALOG_EVENT_NAME,
        handleGlobalDialog as EventListener
      );
    };
  }, [globalDialogProps.state, handleGlobalDialog]);

  return (
    <StatusDialog
      {...globalDialogProps}
      action={modalState?.action}
      title={modalState?.title}
      dismiss={modalState?.dismiss}
      type={modalState?.type ?? 'warning'}
    >
      {modalState?.content}
    </StatusDialog>
  );
};
