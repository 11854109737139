/* eslint-disable no-restricted-imports */
import { ParameterizedRoutePath } from '@app/routes';
import { ReactElement } from 'react';
import {
  Route as RawRoute,
  RouteProps as RawRouteProps,
} from 'react-router-dom';

export interface RouteProps extends Omit<RawRouteProps, 'path'> {
  path?: ParameterizedRoutePath | ParameterizedRoutePath[];
}

export const Route = (props: RouteProps): ReactElement => {
  return <RawRoute {...props} />;
};
