import * as TP from '../types';

import { gql } from '@apollo/client';
import { CustomerAddressInfoV2FragmentDoc } from '../fragments/customerAddressInfoV2';
import { KeyValueInfoFragmentDoc } from '../fragments/keyValueInfo';
import { EmployeeInfoV2FragmentDoc, EmployeeAdditionalDivisionInfoFragmentDoc } from '../fragments/employeeInfoV2';
import { EmployeeGroupsInfoV2FragmentDoc } from '../fragments/employeeGroupsInfoV2';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SearchCustomersV2QueryVariables = TP.Exact<{
  filter?: TP.InputMaybe<TP.CustomersFilterV2>;
  first?: TP.InputMaybe<TP.Scalars['Int']>;
  last?: TP.InputMaybe<TP.Scalars['Int']>;
  before?: TP.InputMaybe<TP.Scalars['String']>;
  after?: TP.InputMaybe<TP.Scalars['String']>;
}>;


export type SearchCustomersV2Query = (
  { readonly __typename?: 'Query' }
  & { readonly allCustomersV2?: TP.Maybe<(
    { readonly __typename?: 'CustomerConnectionV2' }
    & { readonly pageInfo: (
      { readonly __typename?: 'PageInfo' }
      & Pick<TP.PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'>
    ), readonly edges: ReadonlyArray<(
      { readonly __typename?: 'CustomerEdgeV2' }
      & Pick<TP.CustomerEdgeV2, 'cursor'>
      & { readonly node: (
        { readonly __typename?: 'CustomerV2' }
        & Pick<TP.CustomerV2, 'id' | 'code' | 'name'>
        & { readonly mainAddress?: TP.Maybe<(
          { readonly __typename?: 'CustomerAddress' }
          & Pick<TP.CustomerAddress, 'addressTypeId' | 'addressableId' | 'addressableType' | 'city' | 'country' | 'id' | 'main' | 'postalCode' | 'state' | 'street1' | 'street2' | 'isVerified'>
          & { readonly addressType?: TP.Maybe<(
            { readonly __typename?: 'KeyValue' }
            & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
          )>, readonly geopoint: (
            { readonly __typename?: 'Geopoint' }
            & Pick<TP.Geopoint, 'lat' | 'lon'>
          ) }
        )>, readonly customerStatusType?: TP.Maybe<(
          { readonly __typename?: 'KeyValue' }
          & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
        )>, readonly customerLevelType?: TP.Maybe<(
          { readonly __typename?: 'KeyValue' }
          & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
        )>, readonly mainRep?: TP.Maybe<(
          { readonly __typename?: 'CustomerRepV2' }
          & Pick<TP.CustomerRepV2, 'id'>
          & { readonly employee: (
            { readonly __typename?: 'EmployeeV2' }
            & Pick<TP.EmployeeV2, 'id' | 'businessUnitId' | 'companyEmail' | 'divisionId' | 'email' | 'emergencyContact' | 'emergencyPhone' | 'employeeCompanyLevelId' | 'employeeDepartmentId' | 'employeeDepartmentLevelId' | 'employeeDisplayName' | 'employeeGenderId' | 'employeeGroupId' | 'employeeId' | 'employeeOfficeId' | 'employeeRoleId' | 'employeeShirtSizeId' | 'employeeSuffixId' | 'employeeTimeId' | 'employeeTypeId' | 'ext' | 'firstName' | 'firstNameDotLastName' | 'fullName' | 'hireDate' | 'lastName' | 'managerId' | 'middleName' | 'mobilePhone' | 'nickname' | 'personalEmail' | 'phoneNumber' | 'projectId' | 'relationship' | 'slackName' | 'subRegion' | 'termDate' | 'title' | 'userId'>
            & { readonly businessUnit?: TP.Maybe<(
              { readonly __typename?: 'KeyValue' }
              & Pick<TP.KeyValue, 'id' | 'name' | 'active'>
            )>, readonly division?: TP.Maybe<(
              { readonly __typename?: 'KeyValue' }
              & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
            )>, readonly employeeDivisions?: TP.Maybe<ReadonlyArray<(
              { readonly __typename?: 'KeyValue' }
              & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
            )>>, readonly employeeAdditionalDivisions?: TP.Maybe<ReadonlyArray<TP.Maybe<(
              { readonly __typename?: 'EmployeeAdditionalDivisions' }
              & Pick<TP.EmployeeAdditionalDivisions, 'id' | 'additionalDivisionId' | 'employeeId'>
            )>>>, readonly employeeGroup?: TP.Maybe<(
              { readonly __typename?: 'KeyValue' }
              & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
            )>, readonly employeeOffice?: TP.Maybe<(
              { readonly __typename?: 'KeyValue' }
              & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
            )>, readonly employeeStatus: (
              { readonly __typename?: 'KeyValue' }
              & Pick<TP.KeyValue, 'id' | 'active'>
            ), readonly manager?: TP.Maybe<(
              { readonly __typename?: 'EmployeeV2' }
              & Pick<TP.EmployeeV2, 'companyEmail' | 'email' | 'emergencyContact' | 'emergencyPhone' | 'employeeDepartmentId' | 'employeeDisplayName' | 'employeeGenderId' | 'employeeGroupId' | 'employeeId' | 'employeeOfficeId' | 'employeeSuffixId' | 'ext' | 'firstName' | 'fullName' | 'id' | 'lastName' | 'middleName' | 'nickname' | 'personalEmail' | 'phoneNumber' | 'relationship' | 'title' | 'userId' | 'managerId'>
            )>, readonly project?: TP.Maybe<(
              { readonly __typename?: 'KeyValue' }
              & Pick<TP.KeyValue, 'id' | 'name' | 'active'>
            )>, readonly employeeProjects?: TP.Maybe<ReadonlyArray<TP.Maybe<(
              { readonly __typename?: 'EmployeeProject' }
              & Pick<TP.EmployeeProject, 'id'>
              & { readonly employeeProject?: TP.Maybe<(
                { readonly __typename?: 'Project' }
                & Pick<TP.Project, 'id' | 'name'>
              )> }
            )>>>, readonly employeeGroups?: TP.Maybe<ReadonlyArray<(
              { readonly __typename?: 'KeyValue' }
              & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
            )>> }
          ) }
        )> }
      ) }
    )> }
  )> }
);


export const SearchCustomersV2Document = gql`
    query searchCustomersV2($filter: CustomersFilterV2, $first: Int, $last: Int, $before: String, $after: String) {
  allCustomersV2(
    filter: $filter
    first: $first
    last: $last
    before: $before
    after: $after
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        id
        code
        name
        mainAddress {
          ...CustomerAddressInfoV2
        }
        customerStatusType {
          ...KeyValueInfo
        }
        customerLevelType {
          ...KeyValueInfo
        }
        mainRep {
          id
          employee {
            ...EmployeeInfoV2
            ...EmployeeGroupsInfoV2
          }
        }
      }
    }
  }
}
    ${CustomerAddressInfoV2FragmentDoc}
${KeyValueInfoFragmentDoc}
${EmployeeInfoV2FragmentDoc}
${EmployeeGroupsInfoV2FragmentDoc}`;

/**
 * __useSearchCustomersV2Query__
 *
 * To run a query within a React component, call `useSearchCustomersV2Query` and pass it any options that fit your needs.
 * When your component renders, `useSearchCustomersV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchCustomersV2Query({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useSearchCustomersV2Query(baseOptions?: Apollo.QueryHookOptions<SearchCustomersV2Query, SearchCustomersV2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchCustomersV2Query, SearchCustomersV2QueryVariables>(SearchCustomersV2Document, options);
      }
export function useSearchCustomersV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchCustomersV2Query, SearchCustomersV2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchCustomersV2Query, SearchCustomersV2QueryVariables>(SearchCustomersV2Document, options);
        }
export type SearchCustomersV2QueryHookResult = ReturnType<typeof useSearchCustomersV2Query>;
export type SearchCustomersV2LazyQueryHookResult = ReturnType<typeof useSearchCustomersV2LazyQuery>;
export type SearchCustomersV2QueryResult = Apollo.QueryResult<SearchCustomersV2Query, SearchCustomersV2QueryVariables>;