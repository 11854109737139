import { Grid } from '@components/Grid';
import { EmployeeSimpleV2Fragment } from '@generated/fragments/employeeSimpleV2';
import { useBroadcastChannel } from '@hooks/useBroadcastChannel';
import { MASTERLINK } from '@hooks/useConnectedWindows';
import { useMinionFlag } from '@hooks/useMinionFlag';
import { useTheme } from '@hooks/useTheme';
import { MinionConfigTypes } from '@minion/types';
import { AUTOCOMPLETE } from '@utils/zIndex';
import { RepSearch } from '@views/Common/RepSearch';
import { FC, ReactNode } from 'react';
import { EmployeeTreeNodeValue } from '../util';
import { CarrierSearch } from './CarrierSearch';
import { CustomerSearch } from './CustomerSearch';
import { SearchTypeDropDown } from './Dropdown';
import { FacilitySearch } from './FacilitySearch';
import { GroupSearch } from './GroupSearch';
import { LoadSearchV2 } from './LoadSearchV2';
import { SIDEBAR_SEARCH_STYLE } from './style';
import { SearchType } from './types';

export interface Props {
  setSearchType: (_: SearchType) => void;
  setEmployee: (_: EmployeeSimpleV2Fragment | null) => void;
  searchType: SearchType;
}

export const Search: FC<Props> = ({
  setEmployee,
  setSearchType,
  searchType,
}) => {
  let SearchComponent: ReactNode;
  const { postMessage } = useBroadcastChannel<EmployeeTreeNodeValue>({
    id: MASTERLINK,
  });
  const { gray } = useTheme();
  const isfilterByOrgEnabled = useMinionFlag(
    MinionConfigTypes.ViewUserOrganizations
  );
  const tooltipAttribute = isfilterByOrgEnabled
    ? {
        title: `Rep Search disabled`,
        style: {
          backgroundColor: gray[90],
        },
      }
    : {};
  switch (searchType) {
    case SearchType.carrier:
      SearchComponent = <CarrierSearch />;
      break;
    case SearchType.rep:
      SearchComponent = (
        <RepSearch
          css={{ height: '100%' }}
          showSearchIcon
          inputProps={{
            placeholder: 'Search',
            'data-testid': 'sidebar-rep-search-input',
            css: SIDEBAR_SEARCH_STYLE,
            ...tooltipAttribute,
          }}
          sort={{ field: 'fullName', direction: 'asc' }}
          renderDropdownInPopper
          name="rep-search"
          disabled={isfilterByOrgEnabled}
          onChange={(item): void => {
            if (item?.value) {
              setEmployee(item?.value as EmployeeSimpleV2Fragment);
              postMessage({
                id: item?.id?.toString() || '',
                userId: item?.value?.userId?.toString() || '',
                fullName: item?.value?.fullName || '',
                employeeRoleId: undefined,
                employeeDivisions: undefined,
              });
            }
          }}
        />
      );
      break;
    case SearchType.group:
      SearchComponent = <GroupSearch />;
      break;
    case SearchType.customer:
      SearchComponent = <CustomerSearch />;
      break;
    case SearchType.facility:
      SearchComponent = <FacilitySearch />;
      break;
    case SearchType.load:
      SearchComponent = <LoadSearchV2 />;
      break;
    default:
      SearchComponent = null;
  }

  return (
    <Grid
      cols={2}
      xs="7em 1fr"
      gap={0.5}
      css={{
        padding: '0px 8px',
        zIndex: AUTOCOMPLETE,
      }}
      data-testid="sidebar-search-input"
    >
      <SearchTypeDropDown
        setSearchType={setSearchType}
        searchType={searchType}
      />
      <div css={{ position: 'relative' }}>{SearchComponent}</div>
    </Grid>
  );
};
