import { AuthStatus, useKeycloakAuthContext } from '@components/AuthContext';
import { ToggleMenu } from '@components/ToggleMenu';
import { useFlagappAllowDebug } from '@generated/flags/app-allow-debug';
import { FULLSTORY_DEBUG_ENABLED } from '@hooks/useFullstoryDebug';
import { MenuItem } from '@reach/menu-button';
import { IS_NOT_PREVIEW_OR_PROD, SKIP_AUTH } from '@utils/constants';
import { assertNever } from '@utils/exhaustivenessCheck';
import { FS_UNMASK } from '@utils/fullstory';
import { history } from '@utils/history';
import { compact } from 'lodash-es';
import { FC } from 'react';
import { useLogin } from '../hooks/useLogin';

export const UserNav: FC = () => {
  const { status, user } = useKeycloakAuthContext();
  const { logout } = useLogin();
  const debugEnabled = useFlagappAllowDebug();

  let userEl: string, authTxt: string;

  switch (status) {
    case AuthStatus.unitialized:
    case AuthStatus.loading:
    case AuthStatus.errored:
      userEl = '';
      break;
    case AuthStatus.authenticated:
      authTxt = '';
      if (SKIP_AUTH) {
        authTxt = 'Skip Auth';
      }
      userEl =
        compact([user?.firstName, user?.lastName]).join(' ').trim() || '';
      if (IS_NOT_PREVIEW_OR_PROD && authTxt) {
        userEl += ` (${authTxt})`;
      }
      break;
    default:
      userEl = assertNever(status);
  }

  if (FULLSTORY_DEBUG_ENABLED) {
    userEl = '***';
  }

  return (
    <ToggleMenu text={userEl}>
      {debugEnabled && (
        <MenuItem
          onSelect={(): void => history.push('/__debug')}
          className={FS_UNMASK}
        >
          Debug
        </MenuItem>
      )}
      {/*
        // TODO: we may want to re-enable this if the window manager is important to us
        {debugEnabled && (
          <MenuItem onSelect={(): void => history.push('/windows')}>
            Windows
          </MenuItem>
        )} */}
      <MenuItem onSelect={logout} className={FS_UNMASK}>
        Log Out
      </MenuItem>
    </ToggleMenu>
  );
};
