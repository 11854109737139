import * as TP from '../subscriptionTypes';

import { gql } from '@apollo/client';
export type RateConfirmationChangeEventPayloadFragment = (
  { readonly __typename?: 'RateConfirmationChangeEvent' }
  & Pick<TP.RateConfirmationChangeEvent, 'id' | 'type' | 'routeVendorId' | 'success'>
  & { rateConRouteId: TP.RateConfirmationChangeEvent['routeId'] }
);

export const RateConfirmationChangeEventPayloadFragmentDoc = gql`
    fragment RateConfirmationChangeEventPayload on RateConfirmationChangeEvent {
  id
  type
  routeVendorId
  rateConRouteId: routeId
  success
}
    `;