import { LOGIN_INIT_KEY } from '@app/auth/AuthWrapper';
import { useShortcut } from '@components/Shortcut';
import { IS_ELECTRON } from '@utils/constants';
import { history } from '@utils/history';
import { win } from '@utils/win';
import { FC } from 'react';

const NavShortcutsComp: FC = () => {
  useShortcut({
    cmd: true,
    keys: 'left',
    debounceMillis: 20,
    onEvent: () => {
      const isStartPage = Boolean(
        win.location.search.match(`${LOGIN_INIT_KEY}=true`)
      );
      if (!isStartPage) {
        history.goBack();
      }
    },
  });
  useShortcut({
    cmd: true,
    keys: 'right',
    debounceMillis: 20,
    onEvent: () => {
      history.goForward();
    },
  });
  return null;
};

export const NavShortcuts: FC = () => {
  if (IS_ELECTRON) {
    return <NavShortcutsComp />;
  }
  return null;
};
