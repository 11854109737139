import * as TP from '../types';

import { gql } from '@apollo/client';
import { ScheduleRangeInfoFragmentDoc } from './scheduleRangeInfo';
export type ScheduleEntryInfoFragment = (
  { readonly __typename?: 'ScheduleEntry' }
  & Pick<TP.ScheduleEntry, 'closed' | 'id'>
  & { readonly hours: ReadonlyArray<(
    { readonly __typename?: 'ScheduleRange' }
    & Pick<TP.ScheduleRange, 'endOffsetMs' | 'id' | 'startOffsetMs'>
  )> }
);

export const ScheduleEntryInfoFragmentDoc = gql`
    fragment ScheduleEntryInfo on ScheduleEntry {
  closed
  hours {
    ...ScheduleRangeInfo
  }
  id
}
    ${ScheduleRangeInfoFragmentDoc}`;