import * as TP from '../subscriptionTypes';

import { gql } from '@apollo/client';
export type LoadStatusEventPayloadFragment = (
  { readonly __typename?: 'LoadStatusEvent' }
  & Pick<TP.LoadStatusEvent, 'id' | 'type'>
);

export const LoadStatusEventPayloadFragmentDoc = gql`
    fragment LoadStatusEventPayload on LoadStatusEvent {
  id
  type
}
    `;