import { isString, noop } from 'lodash-es';
import {
  ChangeEvent,
  Dispatch,
  HTMLProps,
  RefObject,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { useDebounce } from 'react-use';

type InputValue = string | string[] | number;

interface WeakSupportedElement<Element> extends HTMLProps<Element> {
  value?: anyOk;
}

export interface SupportedElementWithDebounce<Element>
  extends WeakSupportedElement<Element> {
  /** Number in milliseconds to debounce the onChange callback, defaults to 0 */
  debounce?: number;
  /** Optional key that will reset the hook's state value with props.value. Helpful for when the props.value is reset by other means, but doesn't actually change. */
  debounceCacheKey?: number;
}

interface Props<Element> extends SupportedElementWithDebounce<Element> {
  onChange?: (e: ChangeEvent<Element>) => void | string;
  ref: RefObject<Element>;
}

interface Ret<Element> {
  val: InputValue;
  setVal: Dispatch<SetStateAction<InputValue>>;
  onChange: (e: ChangeEvent<Element>) => void;
  cancel: () => void;
}

export function useDebouncedOnChange<Element = HTMLInputElement>(
  props: Props<Element>,
  disable?: boolean
): Ret<Element> {
  const isDisabled = Boolean(disable);
  const [val, setVal] = useState<InputValue>(props.value || '');

  const [, cancel] = useDebounce(
    () => {
      if (isDisabled) {
        return;
      }

      if (props.onChange && props.value !== val) {
        const onChangeResp = props.onChange({
          target: {
            ...props,
            ...(props.ref.current || {}),
            value: val,
          },
        } as fixMe);
        if (isString(onChangeResp) && onChangeResp !== val) {
          setVal(onChangeResp);
        }
      }
    },
    props.debounce || 0,
    [val]
  );

  const debounceCacheKey = props.debounceCacheKey || 0;

  // reset state if prop value changes
  useEffect(() => {
    if (isDisabled) {
      return;
    }
    setVal(props.value);
  }, [props.value, isDisabled, debounceCacheKey]);

  const onChange = ({ target }: ChangeEvent<Element>): void => {
    setVal(target && (target as fixMe).value);
  };

  if (isDisabled) {
    return {
      val: props.value,
      onChange: props.onChange || noop,
      setVal: noop,
      cancel: noop,
    };
  }

  return { val, setVal, onChange, cancel };
}
