import * as TP from '../types';

import { gql } from '@apollo/client';
export type KeyValueInfoFragment = (
  { readonly __typename?: 'KeyValue' }
  & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
);

export const KeyValueInfoFragmentDoc = gql`
    fragment KeyValueInfo on KeyValue {
  active
  id
  metadataJson
  name
}
    `;