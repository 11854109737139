import { useCallback, useState } from 'react';

type Maybe<T> = T | null;

export interface ModalProps<T> {
  isModalActive: boolean;
  openModal(modalMeta?: T): void;
  closeModal(modalMeta?: T): void;
  modalMeta: Maybe<T>;
}

/** An optional convenience hook for managing your <Modal /> state */
export const useModalState = <T = never>(
  initiallyVisible = false
): ModalProps<T> => {
  const [state, setState] = useState<{
    isModalActive: boolean;
    modalMeta: Maybe<T>;
  }>({
    isModalActive: initiallyVisible,
    modalMeta: null,
  });

  const openModal = useCallback((metaArg: Maybe<T> = null): void => {
    setState({ isModalActive: true, modalMeta: metaArg });
  }, []);

  const closeModal = useCallback(
    (metaArg: Maybe<T> = null): void => {
      setState({
        isModalActive: false,
        modalMeta: metaArg !== null ? metaArg : state.modalMeta,
      });
    },
    [state.modalMeta]
  );

  return {
    ...state,
    openModal,
    closeModal,
  };
};
