import * as TP from '../subscriptionTypes';

import { gql } from '@apollo/client';
export type DocumentGenerateEventPayloadFragment = (
  { readonly __typename?: 'DocumentGenerateEvent' }
  & Pick<TP.DocumentGenerateEvent, 'id' | 'documentStatusType' | 'documentStatusMessage' | 'documentGenerationRequestId' | 'userInfo' | 'documentType' | 'type'>
);

export const DocumentGenerateEventPayloadFragmentDoc = gql`
    fragment DocumentGenerateEventPayload on DocumentGenerateEvent {
  id
  documentStatusType
  documentStatusMessage
  documentGenerationRequestId
  userInfo
  documentType
  type
}
    `;