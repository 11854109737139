export type Maybe<T> = T | null | undefined;
export type InputMaybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
};

export type Query = {
  readonly __typename?: 'Query';
  readonly health: Scalars['Boolean'];
};

export type Subscription = {
  readonly __typename?: 'Subscription';
  readonly subscribe: Events;
  readonly subscribeForAslRoutes: AslRouteChangedEvent;
  readonly subscribeForTasks: TaskEvent;
  readonly subscribeForClientExceptionRecords: ClientExceptionRecordFilteredEvent;
};


export type SubscriptionSubscribeArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  types?: InputMaybe<ReadonlyArray<EventType>>;
};


export type SubscriptionSubscribeForAslRoutesArgs = {
  routeOrLoadIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  routeLifeCycleStates?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  inboundRegions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  outboundRegions?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  trailerTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  transportModes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  repIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
};


export type SubscriptionSubscribeForTasksArgs = {
  idsToOverrideFilter?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  name?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  type?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  note?: InputMaybe<Scalars['String']>;
  neededBy?: InputMaybe<TaskEventsDateRangeInput>;
  closedAt?: InputMaybe<TaskEventsDateRangeInput>;
  requestedAt?: InputMaybe<TaskEventsDateRangeInput>;
  loadId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  routeId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  requestedBy?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  assignedTo?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  carrierId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  status?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  loadCode?: InputMaybe<Scalars['String']>;
  routeCode?: InputMaybe<Scalars['String']>;
  showClosed?: InputMaybe<Scalars['Boolean']>;
  assignedToGroupId?: InputMaybe<Scalars['String']>;
  groupId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  externalId?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};


export type SubscriptionSubscribeForClientExceptionRecordsArgs = {
  idsToOverrideFilter?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  types?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  statuses?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  sourceTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  sourceCode?: InputMaybe<Scalars['String']>;
  metadataIDs?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  assignedToIDs?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  assignedToGroups?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type BaseEvent = {
  readonly __typename?: 'BaseEvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
};

export type RouteLockingEvent = {
  readonly __typename?: 'RouteLockingEvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
  readonly expirationTimestamp?: Maybe<Scalars['DateTime']>;
  readonly userIdentity?: Maybe<Scalars['String']>;
  readonly adminIdentity?: Maybe<Scalars['String']>;
  readonly lockEventType: LockEventType;
};

export type LinkedRouteEvent = {
  readonly __typename?: 'LinkedRouteEvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
  readonly linkedRouteID: Scalars['ID'];
  readonly linkType: LinkedRouteLinkType;
};

export type LoadStatusEvent = {
  readonly __typename?: 'LoadStatusEvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
};

export type RouteStatusEvent = {
  readonly __typename?: 'RouteStatusEvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
  readonly routeLifecycleStatus?: Maybe<Scalars['String']>;
  readonly capacityNeed?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly routeActivationStatus?: Maybe<Scalars['String']>;
};

export type TaskEvent = {
  readonly __typename?: 'TaskEvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
  readonly taskEventType: TaskEventType;
  readonly details?: Maybe<TaskEventDetails>;
};

export type TaskEventDetails = {
  readonly __typename?: 'TaskEventDetails';
  readonly id: Scalars['String'];
  readonly name: Scalars['String'];
  readonly type: Scalars['String'];
  readonly status: Scalars['String'];
  readonly routeId: Scalars['ID'];
  readonly loadId: Scalars['ID'];
  readonly requestedAt: Scalars['DateTime'];
  readonly requestedBy: Scalars['ID'];
  readonly carrierId: Scalars['ID'];
  readonly neededBy?: Maybe<Scalars['DateTime']>;
  readonly assignedTo: Scalars['String'];
  readonly closedAt?: Maybe<Scalars['DateTime']>;
  readonly latestNote?: Maybe<Scalars['String']>;
  readonly loadCode?: Maybe<Scalars['String']>;
  readonly routeCode?: Maybe<Scalars['String']>;
  readonly assignedToGroupId?: Maybe<Scalars['String']>;
  readonly groupId?: Maybe<Scalars['String']>;
  readonly externalId?: Maybe<Scalars['String']>;
  readonly comment?: Maybe<Scalars['String']>;
};

export type TasksByLoadIdEvent = {
  readonly __typename?: 'TasksByLoadIdEvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
  readonly taskID: Scalars['ID'];
  readonly taskEventType: TaskEventType;
};

export type TrackingStatusEvent = {
  readonly __typename?: 'TrackingStatusEvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
  readonly trackingStatus: TrackingStatus;
};

export type DriverAssignmentEvent = {
  readonly __typename?: 'DriverAssignmentEvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
  readonly driverAssignmentID: Scalars['ID'];
  readonly eventTimestamp: Scalars['DateTime'];
};

export type SubStopExecutionEvent = {
  readonly __typename?: 'SubStopExecutionEvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
  readonly stopID: Scalars['ID'];
  readonly stopEventIDs: ReadonlyArray<Scalars['String']>;
  readonly stopEventTypeID: Scalars['String'];
  readonly actionType: StopEventActionType;
};

export type TruckPostingMatchEvent = {
  readonly __typename?: 'TruckPostingMatchEvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
};

export type CarrierMatchEvent = {
  readonly __typename?: 'CarrierMatchEvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
};

export type UserMatchEvent = {
  readonly __typename?: 'UserMatchEvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
};

export type RouteMatchEvent = {
  readonly __typename?: 'RouteMatchEvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
};

export type RouteVendorEvent = {
  readonly __typename?: 'RouteVendorEvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
  readonly loadId: Scalars['ID'];
  readonly routeId: Scalars['ID'];
};

export type BookingEvent = {
  readonly __typename?: 'BookingEvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
  readonly routeVendorId?: Maybe<Scalars['String']>;
  readonly capacityId?: Maybe<Scalars['String']>;
  readonly bookingSourceId?: Maybe<Scalars['String']>;
};

export type BookingFailureEvent = {
  readonly __typename?: 'BookingFailureEvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
  readonly errors?: Maybe<ReadonlyArray<BookingError>>;
  readonly carrierId?: Maybe<Scalars['ID']>;
};

export type BookingError = {
  readonly __typename?: 'BookingError';
  readonly code: Scalars['String'];
  readonly message: Scalars['String'];
};

export type CommissionsRecalculatedEvent = {
  readonly __typename?: 'CommissionsRecalculatedEvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
  readonly loadId: Scalars['ID'];
};

export type TenderPlanEvent = {
  readonly __typename?: 'TenderPlanEvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
};

export type TenderPlanRouteEvent = {
  readonly __typename?: 'TenderPlanRouteEvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
};

export type TrackingUpdateEvent = {
  readonly __typename?: 'TrackingUpdateEvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
  readonly trackingUpdateEventType: TrackingUpdateEventType;
  readonly trackingUpdateId: Scalars['String'];
};

export type ToastEvent = {
  readonly __typename?: 'ToastEvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
  readonly toastType: ToastType;
  readonly message: Scalars['String'];
};

export type OfferEvent = {
  readonly __typename?: 'OfferEvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
  readonly offerId: Scalars['ID'];
  readonly carrierId: Scalars['ID'];
};

export type RouteMaxCostEvent = {
  readonly __typename?: 'RouteMaxCostEvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
  readonly routeMaxCost?: Maybe<Scalars['Float']>;
};

export type CostLineItemEvent = {
  readonly __typename?: 'CostLineItemEvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
  readonly costLineItemId: Scalars['String'];
  readonly action: Scalars['String'];
};

export type RateLineItemEvent = {
  readonly __typename?: 'RateLineItemEvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
  readonly rateLineItemId: Scalars['String'];
  readonly action: Scalars['String'];
};

export type PricingRateEvent = {
  readonly __typename?: 'PricingRateEvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
  readonly rateType?: Maybe<Scalars['String']>;
};

export type MinionRefreshEvent = {
  readonly __typename?: 'MinionRefreshEvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
  readonly message: Scalars['String'];
};

export type ClientExceptionRecordEvent = {
  readonly __typename?: 'ClientExceptionRecordEvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
  readonly clientExceptionRecordId: Scalars['String'];
  readonly clientExceptionRecordEventType: ClientExceptionRecordEventType;
};

export type ClientExceptionRecordFilteredEvent = {
  readonly __typename?: 'ClientExceptionRecordFilteredEvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
  readonly clientExceptionRecordEventType: ClientExceptionRecordEventType;
  readonly details?: Maybe<ClientExceptionRecordFilteredEventDetails>;
};

export type ClientExceptionRecordFilteredEventDetails = {
  readonly __typename?: 'ClientExceptionRecordFilteredEventDetails';
  readonly id: Scalars['String'];
  readonly type: Scalars['String'];
  readonly status: Scalars['String'];
  readonly sourceID: Scalars['String'];
  readonly sourceType: Scalars['String'];
  readonly sourceCode?: Maybe<Scalars['String']>;
  readonly metadataIDs?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly assignedToIDs?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly assignedToGroups?: Maybe<ReadonlyArray<Scalars['String']>>;
};

export type IncidentEvent = {
  readonly __typename?: 'IncidentEvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
  readonly incidentEventType: IncidentEventType;
  readonly incidentID: Scalars['ID'];
};

export type MessagingNotificationStatusEvent = {
  readonly __typename?: 'MessagingNotificationStatusEvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
  readonly messageId: Scalars['String'];
  readonly status: MessagingNotificationStatus;
  readonly serverType: MessagingNotificationServerType;
  readonly errorMessage: Scalars['String'];
};

export type PatternOrderApplicationStatusEvent = {
  readonly __typename?: 'PatternOrderApplicationStatusEvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
  readonly patternOrderApplicationId: Scalars['String'];
};

export type ProcurementMatchActionTakenEvent = {
  readonly __typename?: 'ProcurementMatchActionTakenEvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
  readonly actionsTaken: ReadonlyArray<Scalars['String']>;
};

export type RepeatLoadEvent = {
  readonly __typename?: 'RepeatLoadEvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
  readonly rowUniqueId: Scalars['ID'];
  readonly loadId?: Maybe<Scalars['ID']>;
  readonly orderCode?: Maybe<Scalars['Float']>;
  readonly orderStatus: Scalars['Float'];
  readonly message: Scalars['String'];
  readonly activationStatus?: Maybe<Scalars['String']>;
  readonly activationStatusReason?: Maybe<Scalars['String']>;
  readonly routes?: Maybe<ReadonlyArray<RepeatedRoute>>;
};

export type RepeatedRoute = {
  readonly __typename?: 'RepeatedRoute';
  readonly id: Scalars['ID'];
  readonly code: Scalars['String'];
  readonly sequence: Scalars['Float'];
};

export type PostingChangeEvent = {
  readonly __typename?: 'PostingChangeEvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
  readonly loadId: Scalars['ID'];
  readonly routeId: Scalars['ID'];
};

export type DocumentGenerateEvent = {
  readonly __typename?: 'DocumentGenerateEvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
  readonly documentStatusType: StatusType;
  readonly userInfo: Scalars['String'];
  readonly documentType: Scalars['String'];
  readonly documentGenerationRequestId: Scalars['String'];
  readonly documentStatusMessage?: Maybe<Scalars['String']>;
};

export type RateConfirmationChangeEvent = {
  readonly __typename?: 'RateConfirmationChangeEvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
  readonly routeVendorId: Scalars['ID'];
  readonly routeId?: Maybe<Scalars['String']>;
  readonly success?: Maybe<Scalars['Boolean']>;
  readonly hasAppendedDocumentation?: Maybe<Scalars['Boolean']>;
};

export type CapacityUtilizationStatusEvent = {
  readonly __typename?: 'CapacityUtilizationStatusEvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
  readonly capacityId: Scalars['String'];
  readonly capacityType: CapacityType;
  readonly utilizationStatus: CapacityUtilizationStatusType;
};

export type EdiTransactionStorageEvent = {
  readonly __typename?: 'EdiTransactionStorageEvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
  readonly ediTransactionId: Scalars['String'];
};

export type AvailableCapacityEvent = {
  readonly __typename?: 'AvailableCapacityEvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
  readonly capacityId: Scalars['String'];
  readonly capacityType: CapacityType;
  readonly utilizationStatus: Scalars['String'];
};

export type PreplanEvent = {
  readonly __typename?: 'PreplanEvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
  readonly driverId: Scalars['ID'];
  readonly routeId: Scalars['ID'];
};

export type PatternOrderMatchEvent = {
  readonly __typename?: 'PatternOrderMatchEvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
  readonly patternCount: Scalars['Float'];
};

export type DriverMessagingNewMessageEvent = {
  readonly __typename?: 'DriverMessagingNewMessageEvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
  readonly conversationId: Scalars['String'];
  readonly sentByUserId: Scalars['String'];
};

export type DriverMessagingNewMessageEventV2 = {
  readonly __typename?: 'DriverMessagingNewMessageEventV2';
  readonly id: Scalars['String'];
  readonly type: EventType;
  readonly conversation: Conversation;
  readonly drivers: ReadonlyArray<Scalars['String']>;
};

export type Conversation = {
  readonly __typename?: 'Conversation';
  readonly classification: Scalars['String'];
  readonly value?: Maybe<Scalars['String']>;
};

export type DriverMessagingAssignmentUpdatedSuccessEvent = {
  readonly __typename?: 'DriverMessagingAssignmentUpdatedSuccessEvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
  readonly drivers: ReadonlyArray<Scalars['String']>;
};

export type DriverMessagingAssignmentUpdatedFailureEvent = {
  readonly __typename?: 'DriverMessagingAssignmentUpdatedFailureEvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
  readonly drivers: ReadonlyArray<Scalars['String']>;
  readonly errors: ReadonlyArray<Scalars['String']>;
};

export type LoadEmrEvent = {
  readonly __typename?: 'LoadEMREvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
  readonly driverId: Scalars['String'];
  readonly routeId: Scalars['String'];
  readonly message: LoadEmrMessage;
};

export type LoadEmrMessage = {
  readonly __typename?: 'LoadEMRMessage';
  readonly type: LoadEmrMessageType;
  readonly message: Scalars['String'];
};

export type AssetDriverCurrentHosEvent = {
  readonly __typename?: 'AssetDriverCurrentHosEvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
  readonly driverId: Scalars['String'];
  readonly latestHosEvent: AssetDriverHosEvent;
};

export type AssetDriverHosEvent = {
  readonly __typename?: 'AssetDriverHosEvent';
  readonly id: Scalars['String'];
  readonly driveClock: HoursOfServiceClock;
  readonly shiftClock: HoursOfServiceClock;
  readonly cycleClock: HoursOfServiceClock;
  readonly breakClock: HoursOfServiceClock;
  readonly latestDutyStatusTerm: Scalars['String'];
  readonly latestDutyStatusChangeDate: Scalars['DateTime'];
  readonly hosRuleTerm: Scalars['String'];
};

export type HoursOfServiceClock = {
  readonly __typename?: 'HoursOfServiceClock';
  readonly timeRemainingSeconds: Scalars['Int'];
  readonly nextResetDateTime?: Maybe<Scalars['DateTime']>;
};

export type BookingOrchestrationStatusEvent = {
  readonly __typename?: 'BookingOrchestrationStatusEvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
  readonly routeVendorId?: Maybe<Scalars['String']>;
  readonly vendorId?: Maybe<Scalars['String']>;
  readonly driverId?: Maybe<Scalars['String']>;
  readonly capacityId?: Maybe<Scalars['String']>;
  readonly bookingSourceId?: Maybe<Scalars['String']>;
  readonly bookingProgress?: Maybe<ReadonlyArray<BookingProgress>>;
};

export type BookingProgress = {
  readonly __typename?: 'BookingProgress';
  readonly step?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['String']>;
  readonly info?: Maybe<Scalars['String']>;
  readonly errors?: Maybe<ReadonlyArray<BookingError>>;
};

export type LoadTenderOrchestrationEvent = {
  readonly __typename?: 'LoadTenderOrchestrationEvent';
  readonly id: Scalars['String'];
  readonly type: EventType;
  readonly routeVendorId: Scalars['ID'];
  readonly routeId?: Maybe<Scalars['String']>;
  readonly orchestrationResult?: Maybe<LoadTenderOrchestrationEventType>;
  readonly messages: ReadonlyArray<LoadTenderOrchestrationEventResultDetail>;
};

export type LoadTenderOrchestrationEventResultDetail = {
  readonly __typename?: 'LoadTenderOrchestrationEventResultDetail';
  readonly title: Scalars['String'];
  readonly type: Scalars['String'];
};

export type AslRouteChangedEvent = {
  readonly __typename?: 'AslRouteChangedEvent';
  readonly scopes?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly routeId: Scalars['ID'];
  readonly loadId?: Maybe<Scalars['String']>;
  readonly inboundSuperRegions?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly inboundRegions?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly inboundSubRegions?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly outboundSuperRegions?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly outboundRegions?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly outboundSubRegions?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly trailerTypes?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly transportMode?: Maybe<Scalars['String']>;
  readonly orderRepIds?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly routeRepIds?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly startDate?: Maybe<Scalars['DateTime']>;
  readonly endDate?: Maybe<Scalars['DateTime']>;
  readonly routeLifeCycleStatus?: Maybe<Scalars['String']>;
};

export type Events = BaseEvent | RouteLockingEvent | LinkedRouteEvent | LoadStatusEvent | RouteStatusEvent | TaskEvent | TasksByLoadIdEvent | TrackingStatusEvent | DriverAssignmentEvent | SubStopExecutionEvent | TruckPostingMatchEvent | CarrierMatchEvent | UserMatchEvent | RouteMatchEvent | RouteVendorEvent | BookingEvent | BookingFailureEvent | CommissionsRecalculatedEvent | TenderPlanEvent | TenderPlanRouteEvent | TrackingUpdateEvent | ToastEvent | OfferEvent | RouteMaxCostEvent | CostLineItemEvent | RateLineItemEvent | PricingRateEvent | MinionRefreshEvent | ClientExceptionRecordEvent | ClientExceptionRecordFilteredEvent | IncidentEvent | MessagingNotificationStatusEvent | PatternOrderApplicationStatusEvent | ProcurementMatchActionTakenEvent | RepeatLoadEvent | PostingChangeEvent | DocumentGenerateEvent | RateConfirmationChangeEvent | CapacityUtilizationStatusEvent | EdiTransactionStorageEvent | AvailableCapacityEvent | PreplanEvent | PatternOrderMatchEvent | DriverMessagingNewMessageEvent | DriverMessagingNewMessageEventV2 | DriverMessagingAssignmentUpdatedSuccessEvent | DriverMessagingAssignmentUpdatedFailureEvent | LoadEmrEvent | AssetDriverCurrentHosEvent | BookingOrchestrationStatusEvent | LoadTenderOrchestrationEvent;

/** Events that are available from this service for realtime notifications */
export enum EventType {
  Unknown = 'Unknown',
  RouteLockingEvent = 'RouteLockingEvent',
  LinkedRouteEvent = 'LinkedRouteEvent',
  LoadStatusEvent = 'LoadStatusEvent',
  RouteStatusEvent = 'RouteStatusEvent',
  TrackingStatusEvent = 'TrackingStatusEvent',
  DriverAssignmentEvent = 'DriverAssignmentEvent',
  SubStopExecutionEvent = 'SubStopExecutionEvent',
  TaskEvent = 'TaskEvent',
  TasksByLoadIdEvent = 'TasksByLoadIdEvent',
  TruckPostingMatchEvent = 'TruckPostingMatchEvent',
  CarrierMatchEvent = 'CarrierMatchEvent',
  UserMatchEvent = 'UserMatchEvent',
  RouteMatchEvent = 'RouteMatchEvent',
  BookingEvent = 'BookingEvent',
  CommissionsRecalculatedEvent = 'CommissionsRecalculatedEvent',
  TenderPlanEvent = 'TenderPlanEvent',
  TenderPlanRouteEvent = 'TenderPlanRouteEvent',
  TrackingUpdateEvent = 'TrackingUpdateEvent',
  ToastEvent = 'ToastEvent',
  OfferEvent = 'OfferEvent',
  RouteMaxCostEvent = 'RouteMaxCostEvent',
  CostLineItemEvent = 'CostLineItemEvent',
  RateLineItemEvent = 'RateLineItemEvent',
  PricingRateEvent = 'PricingRateEvent',
  MinionRefreshEvent = 'MinionRefreshEvent',
  ClientExceptionRecordEvent = 'ClientExceptionRecordEvent',
  ClientExceptionRecordFilteredEvent = 'ClientExceptionRecordFilteredEvent',
  IncidentEvent = 'IncidentEvent',
  MessagingNotificationStatusEvent = 'MessagingNotificationStatusEvent',
  PatternOrderApplicationStatusEvent = 'PatternOrderApplicationStatusEvent',
  ProcurementMatchActionTakenEvent = 'ProcurementMatchActionTakenEvent',
  RouteVendorEvent = 'RouteVendorEvent',
  RepeatLoadEvent = 'RepeatLoadEvent',
  PostingChangeEvent = 'PostingChangeEvent',
  DocumentGenerateEvent = 'DocumentGenerateEvent',
  RateConfirmationChangeEvent = 'RateConfirmationChangeEvent',
  CapacityUtilizationStatusEvent = 'CapacityUtilizationStatusEvent',
  EdiTransactionStorageEvent = 'EdiTransactionStorageEvent',
  AvailableCapacityEvent = 'AvailableCapacityEvent',
  BookingFailureEvent = 'BookingFailureEvent',
  PreplanEvent = 'PreplanEvent',
  PatternOrderMatchEvent = 'PatternOrderMatchEvent',
  DriverMessagingNewMessageEvent = 'DriverMessagingNewMessageEvent',
  DriverMessagingNewMessageV2Event = 'DriverMessagingNewMessageV2Event',
  DriverMessagingAssignmentUpdatedSuccessEvent = 'DriverMessagingAssignmentUpdatedSuccessEvent',
  DriverMessagingAssignmentUpdatedFailureEvent = 'DriverMessagingAssignmentUpdatedFailureEvent',
  LoadEmrEvent = 'LoadEMREvent',
  AssetDriverCurrentHosEvent = 'AssetDriverCurrentHosEvent',
  BookingOrchestrationStatusEvent = 'BookingOrchestrationStatusEvent',
  LoadTenderOrchestrationEvent = 'LoadTenderOrchestrationEvent'
}

export enum LockEventType {
  LockAcquired = 'lockAcquired',
  LockReleased = 'lockReleased',
  LockRefreshed = 'lockRefreshed',
  LockExpired = 'lockExpired',
  LockOverridden = 'lockOverridden',
  LockExternallyAcquired = 'lockExternallyAcquired',
  LockSent = 'lockSent'
}

export enum LinkedRouteLinkType {
  Previous = 'Previous',
  Next = 'Next'
}

export enum TaskEventType {
  Create = 'Create',
  Update = 'Update',
  Delete = 'Delete'
}

/** Current state of electronic tracking for a Load */
export enum TrackingStatus {
  AppInstallRequired = 'AppInstallRequired',
  CompletedSuccessfully = 'CompletedSuccessfully',
  DeniedByDriver = 'DeniedByDriver',
  ExpiredRequest = 'ExpiredRequest',
  HiddenByDriver = 'HiddenByDriver',
  InvalidMobile = 'InvalidMobile',
  InvalidTractorNumber = 'InvalidTractorNumber',
  ReadyToTrack = 'ReadyToTrack',
  StoppedByTms = 'StoppedByTMS',
  TrackingNow = 'TrackingNow',
  WaitingForUpdate = 'WaitingForUpdate'
}

export enum StopEventActionType {
  Created = 'Created',
  Updated = 'Updated',
  Deleted = 'Deleted'
}

export enum TrackingUpdateEventType {
  Create = 'Create',
  Update = 'Update',
  Delete = 'Delete'
}

export enum ToastType {
  Error = 'Error',
  Info = 'Info',
  Success = 'Success'
}

export enum ClientExceptionRecordEventType {
  Create = 'Create',
  Update = 'Update',
  Delete = 'Delete'
}

export enum IncidentEventType {
  Create = 'Create',
  Update = 'Update',
  Delete = 'Delete'
}

export enum MessagingNotificationStatus {
  Success = 'Success',
  Error = 'Error'
}

export enum MessagingNotificationServerType {
  Slack = 'Slack',
  MsTeams = 'MSTeams'
}

export enum StatusType {
  Error = 'Error',
  Success = 'Success'
}

export enum CapacityType {
  Driver = 'Driver',
  GateReservation = 'GateReservation',
  Trailer = 'Trailer'
}

export enum CapacityUtilizationStatusType {
  Available = 'Available',
  Deleted = 'Deleted'
}

export enum LoadEmrMessageType {
  Warning = 'warning',
  Error = 'error',
  Success = 'success'
}

export enum LoadTenderOrchestrationEventType {
  DocumentGenerated = 'DOCUMENT_GENERATED',
  EmailRequested = 'EMAIL_REQUESTED',
  EmailRequestedFailed = 'EMAIL_REQUESTED_FAILED'
}

export type TaskEventsDateRangeInput = {
  readonly startDate?: InputMaybe<Scalars['DateTime']>;
  readonly endDate?: InputMaybe<Scalars['DateTime']>;
};
