import { KeyValueInfoFragment } from '@generated/fragments/keyValueInfo';
import { KeyValue } from '@generated/types';
import { camelCase } from 'lodash-es';
import { TCName } from '.';

export enum DEPRECATED_DO_NOT_MODIFY_ExtraTypesEnum {
  LoadMode = 'loadMode',
  LoadRelationship = 'loadRelationship',
  LoadPower = 'loadPower',
  LoadTrailer = 'loadTrailer',
  LoadCondition = 'loadCondition',
  MileageProvider = 'mileageProvider',
  MileMakerRouteType = 'mileMakerRouteType',
  Distance = 'distance',
}

const opt = (name: string, kwargs?: Partial<KeyValue>): KeyValue => {
  return {
    active: true,
    id: camelCase(name),
    metadataJson: '{}',
    name,
    sort: 1,
    systemDefined: true,
    description: '',
    ...kwargs,
  };
};

export const DEPRECATED_DO_NOT_MODIFY_ExtraTypes: Array<{
  options: KeyValueInfoFragment[];
  name: TCName;
}> = [
  {
    name: DEPRECATED_DO_NOT_MODIFY_ExtraTypesEnum.LoadMode,
    options: ['TL', 'LTL', 'IMDL', 'Air', 'Ocean'].map((s) => opt(s)),
  },
  {
    name: DEPRECATED_DO_NOT_MODIFY_ExtraTypesEnum.LoadRelationship,
    options: ['Transact', 'Managed', 'Market'].map((s) => opt(s)),
  },
  {
    name: DEPRECATED_DO_NOT_MODIFY_ExtraTypesEnum.LoadPower,
    options: ['Brokered', 'Managed', 'Own'].map((s) => opt(s)),
  },
  {
    name: DEPRECATED_DO_NOT_MODIFY_ExtraTypesEnum.LoadTrailer,
    options: ['Brokered', 'Managed', 'Own'].map((s) => opt(s)),
  },
  {
    name: DEPRECATED_DO_NOT_MODIFY_ExtraTypesEnum.LoadCondition,
    options: ['Active', 'Hold', 'Pending', 'Cancelled'].map((s) => opt(s)),
  },
  {
    name: DEPRECATED_DO_NOT_MODIFY_ExtraTypesEnum.MileageProvider,
    options: [
      opt('PC Miler', { id: 'pcmiler' }),
      opt('MileMaker', { id: 'milemaker' }),
      opt('Azure', { id: 'azure' }),
    ],
  },
  {
    name: DEPRECATED_DO_NOT_MODIFY_ExtraTypesEnum.MileMakerRouteType,
    options: [
      opt('Practical', { id: 'p' }),
      opt('Household Goods', { id: 'h' }),
    ],
  },
  {
    name: DEPRECATED_DO_NOT_MODIFY_ExtraTypesEnum.Distance,
    options: [
      opt('Customer Defaults', { id: 'customerdefaults' }),
      opt('PCMiler Defaults', { id: 'pcmilerdefaults' }),
      opt('Milemaker Defaults', { id: 'milemakerdefaults' }),
    ],
  },
];
