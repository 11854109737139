import * as TP from '../types';

import { gql } from '@apollo/client';
import { EmployeeInfoV2FragmentDoc, EmployeeAdditionalDivisionInfoFragmentDoc } from '../fragments/employeeInfoV2';
import { KeyValueInfoFragmentDoc } from '../fragments/keyValueInfo';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WhoAmIDataV2Fragment = (
  { readonly __typename?: 'WhoAmIV2' }
  & { readonly user?: TP.Maybe<(
    { readonly __typename?: 'UserV2' }
    & Pick<TP.UserV2, 'id' | 'email'>
  )>, readonly employee?: TP.Maybe<(
    { readonly __typename?: 'EmployeeV2' }
    & Pick<TP.EmployeeV2, 'id' | 'businessUnitId' | 'companyEmail' | 'divisionId' | 'email' | 'emergencyContact' | 'emergencyPhone' | 'employeeCompanyLevelId' | 'employeeDepartmentId' | 'employeeDepartmentLevelId' | 'employeeDisplayName' | 'employeeGenderId' | 'employeeGroupId' | 'employeeId' | 'employeeOfficeId' | 'employeeRoleId' | 'employeeShirtSizeId' | 'employeeSuffixId' | 'employeeTimeId' | 'employeeTypeId' | 'ext' | 'firstName' | 'firstNameDotLastName' | 'fullName' | 'hireDate' | 'lastName' | 'managerId' | 'middleName' | 'mobilePhone' | 'nickname' | 'personalEmail' | 'phoneNumber' | 'projectId' | 'relationship' | 'slackName' | 'subRegion' | 'termDate' | 'title' | 'userId'>
    & { readonly employeeDivisions?: TP.Maybe<ReadonlyArray<(
      { readonly __typename?: 'KeyValue' }
      & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
    )>>, readonly employeeAdditionalDivisions?: TP.Maybe<ReadonlyArray<TP.Maybe<(
      { readonly __typename?: 'EmployeeAdditionalDivisions' }
      & Pick<TP.EmployeeAdditionalDivisions, 'id' | 'additionalDivisionId' | 'employeeId'>
    )>>>, readonly businessUnit?: TP.Maybe<(
      { readonly __typename?: 'KeyValue' }
      & Pick<TP.KeyValue, 'id' | 'name' | 'active'>
    )>, readonly division?: TP.Maybe<(
      { readonly __typename?: 'KeyValue' }
      & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
    )>, readonly employeeGroup?: TP.Maybe<(
      { readonly __typename?: 'KeyValue' }
      & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
    )>, readonly employeeOffice?: TP.Maybe<(
      { readonly __typename?: 'KeyValue' }
      & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
    )>, readonly employeeStatus: (
      { readonly __typename?: 'KeyValue' }
      & Pick<TP.KeyValue, 'id' | 'active'>
    ), readonly manager?: TP.Maybe<(
      { readonly __typename?: 'EmployeeV2' }
      & Pick<TP.EmployeeV2, 'companyEmail' | 'email' | 'emergencyContact' | 'emergencyPhone' | 'employeeDepartmentId' | 'employeeDisplayName' | 'employeeGenderId' | 'employeeGroupId' | 'employeeId' | 'employeeOfficeId' | 'employeeSuffixId' | 'ext' | 'firstName' | 'fullName' | 'id' | 'lastName' | 'middleName' | 'nickname' | 'personalEmail' | 'phoneNumber' | 'relationship' | 'title' | 'userId' | 'managerId'>
    )>, readonly project?: TP.Maybe<(
      { readonly __typename?: 'KeyValue' }
      & Pick<TP.KeyValue, 'id' | 'name' | 'active'>
    )>, readonly employeeProjects?: TP.Maybe<ReadonlyArray<TP.Maybe<(
      { readonly __typename?: 'EmployeeProject' }
      & Pick<TP.EmployeeProject, 'id'>
      & { readonly employeeProject?: TP.Maybe<(
        { readonly __typename?: 'Project' }
        & Pick<TP.Project, 'id' | 'name'>
      )> }
    )>>> }
  )>, readonly permissions: ReadonlyArray<(
    { readonly __typename?: 'UserPermission' }
    & Pick<TP.UserPermission, 'resource' | 'scopes'>
  )> }
);

export type WhoAmIv2QueryVariables = TP.Exact<{ [key: string]: never; }>;


export type WhoAmIv2Query = (
  { readonly __typename?: 'Query' }
  & { readonly whoAmIV2?: TP.Maybe<(
    { readonly __typename?: 'WhoAmIV2' }
    & { readonly user?: TP.Maybe<(
      { readonly __typename?: 'UserV2' }
      & Pick<TP.UserV2, 'id' | 'email'>
    )>, readonly employee?: TP.Maybe<(
      { readonly __typename?: 'EmployeeV2' }
      & Pick<TP.EmployeeV2, 'id' | 'businessUnitId' | 'companyEmail' | 'divisionId' | 'email' | 'emergencyContact' | 'emergencyPhone' | 'employeeCompanyLevelId' | 'employeeDepartmentId' | 'employeeDepartmentLevelId' | 'employeeDisplayName' | 'employeeGenderId' | 'employeeGroupId' | 'employeeId' | 'employeeOfficeId' | 'employeeRoleId' | 'employeeShirtSizeId' | 'employeeSuffixId' | 'employeeTimeId' | 'employeeTypeId' | 'ext' | 'firstName' | 'firstNameDotLastName' | 'fullName' | 'hireDate' | 'lastName' | 'managerId' | 'middleName' | 'mobilePhone' | 'nickname' | 'personalEmail' | 'phoneNumber' | 'projectId' | 'relationship' | 'slackName' | 'subRegion' | 'termDate' | 'title' | 'userId'>
      & { readonly employeeDivisions?: TP.Maybe<ReadonlyArray<(
        { readonly __typename?: 'KeyValue' }
        & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
      )>>, readonly employeeAdditionalDivisions?: TP.Maybe<ReadonlyArray<TP.Maybe<(
        { readonly __typename?: 'EmployeeAdditionalDivisions' }
        & Pick<TP.EmployeeAdditionalDivisions, 'id' | 'additionalDivisionId' | 'employeeId'>
      )>>>, readonly businessUnit?: TP.Maybe<(
        { readonly __typename?: 'KeyValue' }
        & Pick<TP.KeyValue, 'id' | 'name' | 'active'>
      )>, readonly division?: TP.Maybe<(
        { readonly __typename?: 'KeyValue' }
        & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
      )>, readonly employeeGroup?: TP.Maybe<(
        { readonly __typename?: 'KeyValue' }
        & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
      )>, readonly employeeOffice?: TP.Maybe<(
        { readonly __typename?: 'KeyValue' }
        & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
      )>, readonly employeeStatus: (
        { readonly __typename?: 'KeyValue' }
        & Pick<TP.KeyValue, 'id' | 'active'>
      ), readonly manager?: TP.Maybe<(
        { readonly __typename?: 'EmployeeV2' }
        & Pick<TP.EmployeeV2, 'companyEmail' | 'email' | 'emergencyContact' | 'emergencyPhone' | 'employeeDepartmentId' | 'employeeDisplayName' | 'employeeGenderId' | 'employeeGroupId' | 'employeeId' | 'employeeOfficeId' | 'employeeSuffixId' | 'ext' | 'firstName' | 'fullName' | 'id' | 'lastName' | 'middleName' | 'nickname' | 'personalEmail' | 'phoneNumber' | 'relationship' | 'title' | 'userId' | 'managerId'>
      )>, readonly project?: TP.Maybe<(
        { readonly __typename?: 'KeyValue' }
        & Pick<TP.KeyValue, 'id' | 'name' | 'active'>
      )>, readonly employeeProjects?: TP.Maybe<ReadonlyArray<TP.Maybe<(
        { readonly __typename?: 'EmployeeProject' }
        & Pick<TP.EmployeeProject, 'id'>
        & { readonly employeeProject?: TP.Maybe<(
          { readonly __typename?: 'Project' }
          & Pick<TP.Project, 'id' | 'name'>
        )> }
      )>>> }
    )>, readonly permissions: ReadonlyArray<(
      { readonly __typename?: 'UserPermission' }
      & Pick<TP.UserPermission, 'resource' | 'scopes'>
    )> }
  )> }
);

export const WhoAmIDataV2FragmentDoc = gql`
    fragment WhoAmIDataV2 on WhoAmIV2 {
  user {
    id
    email
  }
  employee {
    ...EmployeeInfoV2
    employeeDivisions {
      ...KeyValueInfo
    }
    employeeAdditionalDivisions {
      ...EmployeeAdditionalDivisionInfo
    }
  }
  permissions {
    resource
    scopes
  }
}
    ${EmployeeInfoV2FragmentDoc}
${KeyValueInfoFragmentDoc}
${EmployeeAdditionalDivisionInfoFragmentDoc}`;
export const WhoAmIv2Document = gql`
    query whoAmIV2 {
  whoAmIV2 {
    ...WhoAmIDataV2
  }
}
    ${WhoAmIDataV2FragmentDoc}`;

/**
 * __useWhoAmIv2Query__
 *
 * To run a query within a React component, call `useWhoAmIv2Query` and pass it any options that fit your needs.
 * When your component renders, `useWhoAmIv2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWhoAmIv2Query({
 *   variables: {
 *   },
 * });
 */
export function useWhoAmIv2Query(baseOptions?: Apollo.QueryHookOptions<WhoAmIv2Query, WhoAmIv2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WhoAmIv2Query, WhoAmIv2QueryVariables>(WhoAmIv2Document, options);
      }
export function useWhoAmIv2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WhoAmIv2Query, WhoAmIv2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WhoAmIv2Query, WhoAmIv2QueryVariables>(WhoAmIv2Document, options);
        }
export type WhoAmIv2QueryHookResult = ReturnType<typeof useWhoAmIv2Query>;
export type WhoAmIv2LazyQueryHookResult = ReturnType<typeof useWhoAmIv2LazyQuery>;
export type WhoAmIv2QueryResult = Apollo.QueryResult<WhoAmIv2Query, WhoAmIv2QueryVariables>;