import { getAddressDisplayString } from '@components/AddressDisplay';
import { AutoComplete, Shell } from '@components/AutoComplete';
import { CarrierInfoSidebarV2Fragment } from '@generated/fragments/carrierInfoSidebarV2';
import { useSearchCarriersCarrierInfoSidebarV2LazyQuery } from '@generated/queries/searchCarriersCarrierInfoSidebarV2';
import { useDebouncedFn } from '@hooks/useDebouncedFn';
import { Actions } from 'downshift';
import { Component, FC, ReactNode } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { SIDEBAR_SEARCH_STYLE } from '../style';

const CarrierSearchBase: FC<RouteComponentProps> = ({ history: { push } }) => {
  const carrierSelectHandler = (
    item: Shell<CarrierInfoSidebarV2Fragment> | null,
    helpers?: Actions<Shell<CarrierInfoSidebarV2Fragment>>
  ): void => {
    if (item) {
      helpers?.clearSelection();
      helpers?.closeMenu();

      push(`/carriers/${item.id}/capacity-manager`);
    }
  };
  const [searchCarriersV2, { loading: loadingV2, data: dataV2 }] =
    useSearchCarriersCarrierInfoSidebarV2LazyQuery();
  const data = dataV2?.carriersNullableV2;

  const debouncedSearchCarriers = useDebouncedFn(searchCarriersV2, 500, []);

  const carriers = data?.edges ?? [];
  const items = carriers.map(({ node }) => ({
    value: node,
    label: node.name,
    id: node.id,
  }));

  interface CarrierItem {
    id: string;
    name: string;
    code: string;
    mainAddress?: {
      city?: string | null;
      state?: string | null;
    } | null;
  }

  const renderCarrierItem = (value: CarrierItem): ReactNode => {
    const addressDisplayString = getAddressDisplayString({
      value: {
        city: value?.mainAddress?.city ?? undefined,
        state: value?.mainAddress?.state ?? undefined,
      },
      city: true,
      state: true,
    });
    return (
      <div key={value.id}>
        <strong>{value?.name}</strong>
        <br />
        <span style={{ display: 'none' }}>{' | '}</span>
        <span>
          {value?.code}{' '}
          {addressDisplayString.length ? `| ${addressDisplayString}` : ''}
        </span>
      </div>
    );
  };

  return (
    <AutoComplete
      onInputValueChange={(inputValue): void => {
        debouncedSearchCarriers({
          variables: { filter: { text: inputValue }, first: 15 },
        });
      }}
      onChange={carrierSelectHandler}
      loading={loadingV2}
      items={items.map((item) => ({
        ...item,
        label: renderCarrierItem,
      }))}
      renderDropdownInPopper
      showSearchIcon
      css={{ height: '100%' }}
      inputProps={{
        placeholder: 'Search',
        'data-testid': 'sidebar-carrier-search-input',
        'aria-label': 'Search Carriers',
        css: SIDEBAR_SEARCH_STYLE,
      }}
    />
  );
};

export const CarrierSearch: typeof Component = withRouter(CarrierSearchBase);
