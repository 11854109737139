import * as TP from '../types';

import { gql } from '@apollo/client';
import { CustomerPickerItemV2FragmentDoc } from '../fragments/customerPickerItemV2';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AllCustomersForCustomerPickerV2QueryVariables = TP.Exact<{
  filter?: TP.InputMaybe<TP.CustomersFilterV2>;
  first?: TP.InputMaybe<TP.Scalars['Int']>;
}>;


export type AllCustomersForCustomerPickerV2Query = (
  { readonly __typename?: 'Query' }
  & { readonly allCustomersV2?: TP.Maybe<(
    { readonly __typename?: 'CustomerConnectionV2' }
    & { readonly pageInfo: (
      { readonly __typename?: 'PageInfo' }
      & Pick<TP.PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'>
    ), readonly edges: ReadonlyArray<(
      { readonly __typename?: 'CustomerEdgeV2' }
      & Pick<TP.CustomerEdgeV2, 'cursor'>
      & { readonly node: (
        { readonly __typename?: 'CustomerV2' }
        & Pick<TP.CustomerV2, 'code' | 'id' | 'name' | 'currencyId'>
        & { readonly mainAddress?: TP.Maybe<(
          { readonly __typename?: 'CustomerAddress' }
          & Pick<TP.CustomerAddress, 'id' | 'city' | 'state'>
        )>, readonly customerStatusType?: TP.Maybe<(
          { readonly __typename?: 'KeyValue' }
          & Pick<TP.KeyValue, 'id' | 'name'>
        )> }
      ) }
    )> }
  )> }
);


export const AllCustomersForCustomerPickerV2Document = gql`
    query allCustomersForCustomerPickerV2($filter: CustomersFilterV2, $first: Int) {
  allCustomersV2(filter: $filter, first: $first) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        ...CustomerPickerItemV2
      }
    }
  }
}
    ${CustomerPickerItemV2FragmentDoc}`;

/**
 * __useAllCustomersForCustomerPickerV2Query__
 *
 * To run a query within a React component, call `useAllCustomersForCustomerPickerV2Query` and pass it any options that fit your needs.
 * When your component renders, `useAllCustomersForCustomerPickerV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCustomersForCustomerPickerV2Query({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useAllCustomersForCustomerPickerV2Query(baseOptions?: Apollo.QueryHookOptions<AllCustomersForCustomerPickerV2Query, AllCustomersForCustomerPickerV2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllCustomersForCustomerPickerV2Query, AllCustomersForCustomerPickerV2QueryVariables>(AllCustomersForCustomerPickerV2Document, options);
      }
export function useAllCustomersForCustomerPickerV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllCustomersForCustomerPickerV2Query, AllCustomersForCustomerPickerV2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllCustomersForCustomerPickerV2Query, AllCustomersForCustomerPickerV2QueryVariables>(AllCustomersForCustomerPickerV2Document, options);
        }
export type AllCustomersForCustomerPickerV2QueryHookResult = ReturnType<typeof useAllCustomersForCustomerPickerV2Query>;
export type AllCustomersForCustomerPickerV2LazyQueryHookResult = ReturnType<typeof useAllCustomersForCustomerPickerV2LazyQuery>;
export type AllCustomersForCustomerPickerV2QueryResult = Apollo.QueryResult<AllCustomersForCustomerPickerV2Query, AllCustomersForCustomerPickerV2QueryVariables>;