import { PRIVATE_themeObj } from '@components/Theme';
import { resolveThemeValueFromNamedColor, useTheme } from '@hooks/useTheme';
import { IconDefinition } from '@masterysystems/free-solid-svg-icons';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@masterysystems/react-fontawesome';
import { FC, memo } from 'react';
import { iconMap } from './iconMap';

// sizes are in pixels
export const IconSize = {
  sm: 13,
  md: 16,
  lg: 20,
  xl: 24,
};

export type IconProp = keyof typeof iconMap;
export type IconSizeProp = keyof typeof IconSize;
export type IconColorProp =
  | keyof typeof PRIVATE_themeObj.colors
  | (typeof PRIVATE_themeObj.gray)[keyof typeof PRIVATE_themeObj.gray]
  | 'inherit';

interface WeakIcon extends Omit<FontAwesomeIconProps, 'icon' | 'color'> {
  size?: anyOk;
}

export interface Props extends WeakIcon {
  i: IconProp;
  /** Size correlates to the font size of the svg */
  size: IconSizeProp;
  /** Color is a color key from the useTheme() hook, or any valid css color value */
  color: IconColorProp;
}

export const Icon: FC<Props> = memo(
  ({ i, color: colorProp, size, ...rest }) => {
    const theme = useTheme();
    const resolvedColor = resolveThemeValueFromNamedColor(theme, colorProp);
    return (
      <FontAwesomeIcon
        icon={iconMap[i] as IconDefinition}
        css={{
          fontSize: IconSize[size],
          color: resolvedColor || 'inherit',
        }}
        data-color={colorProp}
        {...rest}
      />
    );
  }
);

interface IconBgProps {
  i: IconProp;
}

export const IconBg: FC<IconBgProps> = memo(({ i }) => {
  return (
    <FontAwesomeIcon
      icon={iconMap?.[i] as IconDefinition}
      css={{ fontSize: 10 }}
    />
  );
});
