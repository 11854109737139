import { Grid } from '@components/Grid';
import { useTheme } from '@hooks/useTheme';
import { ZonedDateTime } from '@internationalized/date';
import { FC } from 'react';
import { DateField } from '../DateField';
import { DateType } from '../defs';

interface ValueType {
  start: Maybe<DateType>;
  end: Maybe<DateType>;
  value?: Maybe<DateType>;
}

interface Props {
  onChange: (val: ValueType) => void;
  value: Maybe<ValueType>;
  singleDate?: boolean;
  fsSearchElement?: string;
}

export const DateFieldRange: FC<Props> = ({
  value,
  onChange,
  singleDate,
  fsSearchElement,
}) => {
  const start = singleDate ? (value as Maybe<ZonedDateTime>) : value?.start;
  const end = value?.end;
  const { gray } = useTheme();

  if (singleDate) {
    return (
      <Grid
        css={{
          border: `1px solid ${gray[80]}`,
          borderRadius: 3,
          padding: '1px 6px',
        }}
        gap={0.5}
      >
        <DateField
          singleDate
          value={start}
          onChange={(val): void => {
            onChange({ start: val, end: null });
          }}
          nonClearable
          fsSearchElement={fsSearchElement}
        />
      </Grid>
    );
  }

  return (
    <Grid
      xs="1fr min-content min-content min-content 1fr"
      css={{
        border: `1px solid ${gray[80]}`,
        borderRadius: 3,
        padding: '1px 6px',
      }}
      gap={0.5}
    >
      <div />
      <DateField
        value={start}
        onChange={(val): void => {
          onChange({ start: val, end });
        }}
        nonClearable
        fsSearchElement={fsSearchElement}
      />
      <div css={{ paddingTop: 4 }}>-</div>
      <DateField
        value={end}
        onChange={(val): void => {
          onChange({ start, end: val });
        }}
        nonClearable
        fsSearchElement={fsSearchElement}
      />
      <div />
    </Grid>
  );
};
