const noop = (): void => {
  //
};

const MyProxy = (): anyOk =>
  new Proxy(noop, {
    get: (): anyOk => MyProxy(),
  });

const winProxy = MyProxy();

export interface Fullstory {
  identify(uid: string, opts: { displayName?: string; email?: string }): void;
  setUserVars(vars: Record<string, anyOk>): void;
  getCurrentSessionURL(): string;
  event(name: string, properties: Record<string, anyOk>): void;
  restart(): void;
}

interface SpeechRecognition extends EventTarget {
  grammars: SpeechGrammarList;
  lang: string;
  continuous: boolean;
  interimResults: boolean;
  maxAlternatives: number;
  serviceURI: string;

  start(): void;
  stop(): void;
  abort(): void;

  onaudiostart: (this: SpeechRecognition, ev: Event) => anyOk;
  onsoundstart: (this: SpeechRecognition, ev: Event) => anyOk;
  onspeechstart: (this: SpeechRecognition, ev: Event) => anyOk;
  onspeechend: (this: SpeechRecognition, ev: Event) => anyOk;
  onsoundend: (this: SpeechRecognition, ev: Event) => anyOk;
  onaudioend: (this: SpeechRecognition, ev: Event) => anyOk;
  onresult: (this: SpeechRecognition, ev: SpeechRecognitionEvent) => anyOk;
  onnomatch: (this: SpeechRecognition, ev: SpeechRecognitionEvent) => anyOk;
  onerror: (this: SpeechRecognition, ev: SpeechRecognitionErrorEvent) => anyOk;
  onstart: (this: SpeechRecognition, ev: Event) => anyOk;
  onend: (this: SpeechRecognition, ev: Event) => anyOk;
}

interface webkitSpeechRecognition extends SpeechRecognition {}

interface SpeechGrammarList {
  length: number;
  addFromURI(src: string, weight?: number): void;
  addFromString(string: string, weight?: number): void;
}

export interface SpeechRecognitionEvent extends Event {
  results: SpeechRecognitionResultList;
  resultIndex: number;
  interpretation: anyOk;
  emma: Document;
}

interface SpeechRecognitionErrorEvent extends Event {
  error:
    | 'no-speech'
    | 'aborted'
    | 'audio-capture'
    | 'network'
    | 'not-allowed'
    | 'service-not-allowed'
    | 'bad-grammar'
    | 'language-not-supported';
  message: string;
}

interface SpeechRecognitionResultList {
  length: number;
  item(index: number): SpeechRecognitionResult;
}

interface SpeechRecognitionResult {
  length: number;
  isFinal: boolean;
  item(index: number): SpeechRecognitionAlternative;
}

interface SpeechRecognitionAlternative {
  transcript: string;
  confidence: number;
}

declare const webkitSpeechRecognition: {
  prototype: webkitSpeechRecognition;
  new (): webkitSpeechRecognition;
};

interface MasteryEnv {
  agentId: string;
  aliases: string[];
  applicationId: string;
  bucket: string;
  corsAllowedOrigins: string;
  hostname: string;
  id: string;
  isProd: boolean;
  origin: string;
  subscription: string;
}

interface IndexedDBColumn {
  name: string;
  keyPath: string;
  options?: IDBIndexParameters;
}

interface IndexedDBStore {
  name: string;
  id: IDBObjectStoreParameters;
  indices: IndexedDBColumn[];
}

export interface IndexedDBConfig {
  databaseName: string;
  version: number;
  stores: IndexedDBStore[];
}

interface MasteryIDB {
  config: {
    databaseName: string;
    version: number;
    stores: IndexedDBStore[];
  };
  init: number;
}

export const IDB_KEY = '__idb';

interface MasteryWindow extends Window {
  Cypress?: unknown;
  masteryWalkmeVariables: Record<string, string | undefined>;
  React: {
    Fragment: fixMe;
  };
  electron?: {
    ipc: {
      send: (s: string, d: unknown) => void;
      sendSync: <D extends unknown>(s: string, ...args: unknown[]) => D;
      on: (s: string, cb: (evt: unknown, data: fixMe) => void) => void;
      invoke: (s: string, d: unknown) => void;
    };
    registerWindow: (id: string) => void;
    openWindows: Set<string>;
  };
  FS?: Fullstory;
  masteryFullstoryInit: (orgId: string) => void;
  masteryBoot: {
    BASE_HEADER_HEIGHT: number;
    IS_HEADER_ALLOWED: boolean;
    IS_SIDEBAR_ALLOWED: boolean;
    IS_SIDEBAR_SHOWN_ON_PAGE_LOAD: boolean;
    SIDEBAR_QUERY_PARAM: null | boolean;
  };
  MERCATOR_ENV?: string;
  mastermindFeatureFlags?: Record<string, anyOk>;
  webkitSpeechRecognition: {
    prototype: webkitSpeechRecognition;
    new (): webkitSpeechRecognition;
  };
  SpeechRecognition: {
    prototype: SpeechRecognition;
    new (): SpeechRecognition;
  };
  masteryEnv?: MasteryEnv;
  [IDB_KEY]?: MasteryIDB;
}

/** When this variable evaluates to true, it means we have a valid window object. We are probably running inside of a browser (vs Node). */
// eslint-disable-next-line no-restricted-globals
export const HAS_WINDOW = typeof window !== 'undefined';

// eslint-disable-next-line no-restricted-globals
export const win = (HAS_WINDOW ? window : winProxy) as MasteryWindow;

//This code will add a random name to the parent (opener) window and open empty url.
//This is used to focus on the parent tab as win.opener.focus() function does not work.
export const getParentWinObj = (): Window => {
  if (win.opener) {
    let parentWin = win.opener;
    while (parentWin.opener) {
      parentWin = parentWin.opener;
    }
    const randomName = `w-${Date.now().toString()}`;
    parentWin.name = randomName;
    win.open('', randomName);
    return parentWin;
  } else {
    return win;
  }
};
