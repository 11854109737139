import { useKeycloakAuthContext } from '@components/AuthContext';
import {
  BoolFlags,
  JsonFlags,
  NumberFlags,
  StringFlags,
} from '@generated/flags/defs';
import {
  IS_CYPRESS,
  IS_MERCATOR,
  IS_NOT_PREVIEW_OR_PROD,
  IS_PR_PREVIEW,
  KnownDomains,
  QUERY_PARAM_FLAGS_ENABLED,
  USE_LD,
  devDomains,
} from '@utils/constants';
import { jsonParse } from '@utils/json';
import { HAS_WINDOW, win } from '@utils/win';
import { useFlags as ldUseFlags } from 'launchdarkly-react-client-sdk';
import {
  fromPairs,
  isBoolean,
  isNil,
  isObjectLike,
  isUndefined,
  pick,
  set,
} from 'lodash-es';
import { parse } from 'query-string';
import {
  FC,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { JsonObject } from 'type-fest';
import { RESOLVED_TENANT_HOSTNAME, configAliases } from '../../config';
import { flagDefaultsForLocalDev } from './flags';
import { JsonFlagKeys } from './jsonFlags';

const FLAG_GLOBAL_EVENT_KEY = 'globalFlagChange';

type BoolFlagsObject = Record<BoolFlags, boolean>;
type JsonFlagsObject = Record<JsonFlags, Record<string, anyOk>>;
type StringFlagsObject = Record<StringFlags, string>;
type NumberFlagsObject = Record<NumberFlags, number>;

type FlagsObject = BoolFlagsObject &
  JsonFlagsObject &
  StringFlagsObject &
  NumberFlagsObject;

type NamesWithoutJson = BoolFlags | StringFlags | NumberFlags;

type UseFlag = <T extends NamesWithoutJson>(
  name: T,
  config?: {
    localDefault?: FlagsObject[T];
  },
  waitUntilUserIdentify?: boolean
) => FlagsObject[T];

type UseFlagJSONData = <T extends JsonFlagKeys>(
  name: T,
  config?: {
    localDefault?: FlagsObject[T];
  }
) => FlagsObject[T];

type UseFlagsType = <T extends NamesWithoutJson>(
  name?: T,
  config?: {
    localDefault?: FlagsObject[T];
  }
) => FlagsObject;

const getAllLocationSearchFlags = (): Record<string, anyOk> => {
  const returnObj: Record<string, anyOk> = {};
  const searchStr = IS_MERCATOR
    ? win.location.hash?.split('?')[1] || ''
    : win.location.search;
  +Object.entries(parse(searchStr)).forEach(([key, val]) => {
    if (key.startsWith('flag-') || key.match(/^ME-\d/)) {
      const flagName = key.replace(/^flag-/, '') as keyof FlagsObject;
      const parsed = jsonParse(val as string, undefined, false);
      if (parsed !== undefined) {
        set(returnObj, flagName, parsed);
      }
    }
  });

  return returnObj;
};

// We only grab this once because it is somewhat expensive to run it contiually. Also it is rare that the query param flags change after page load.
const initialSearchFlags = getAllLocationSearchFlags();

const getQueryParamFlags = (rawFlags: FlagsObject): FlagsObject => {
  // Allow users to set flags via the query string, but ONLY for internal environments
  if (HAS_WINDOW && QUERY_PARAM_FLAGS_ENABLED) {
    return { ...rawFlags, ...initialSearchFlags };
  }
  return rawFlags;
};

const getCookieFlags = (): Partial<FlagsObject> => {
  if (!IS_CYPRESS) {
    return {};
  }
  return fromPairs(
    document.cookie
      .split('; ')
      .filter((str) => str.startsWith('flag-'))
      .map((str) => {
        const [key, val] = str.split('=');
        return [key?.substring(5), jsonParse(val as fixMe)];
      })
  ) as Partial<FlagsObject>;
};

win.mastermindFeatureFlags = undefined;

const RuntimeFlagsContext = createContext({});
export const RuntimeFlagsProvider: FC = ({ children }) => {
  const [runtimeFlagsState, setRuntimeFlagsState] = useState<
    Record<string, anyOk>
  >({});
  const handleEvent = (
    event: CustomEventInit<{ key: string; value: anyOk }>
  ): void => {
    const key = event.detail?.key;
    const value = event.detail?.value;
    if (key) {
      setRuntimeFlagsState((prev) => ({ ...prev, [key]: value }));
    }
  };
  useEffect(() => {
    if (IS_NOT_PREVIEW_OR_PROD) {
      win.addEventListener(FLAG_GLOBAL_EVENT_KEY, handleEvent);
    }
    return () => {
      if (IS_NOT_PREVIEW_OR_PROD) {
        win.removeEventListener(FLAG_GLOBAL_EVENT_KEY, handleEvent);
      }
    };
  });
  return (
    <RuntimeFlagsContext.Provider value={runtimeFlagsState}>
      {children}
    </RuntimeFlagsContext.Provider>
  );
};

export const useFlags: UseFlagsType = (name, config) => {
  const flagsFromLD = ldUseFlags() as FlagsObject;
  const runtimeFlags = useContext(RuntimeFlagsContext);
  if (QUERY_PARAM_FLAGS_ENABLED && !win.mastermindFeatureFlags) {
    win.mastermindFeatureFlags = flagsFromLD;
  }
  // Simply accessing the flags from LD is enough to trigger the LD SDK (via JS Proxy). If we only are trying to get one flag, (presence of name arg), then we construct a single flag object for return;
  let rawFlags = {} as FlagsObject;
  if (name) {
    rawFlags[name] = flagsFromLD[name];
  } else {
    rawFlags = flagsFromLD;
  }
  const flags = {
    ...rawFlags,
    ...getQueryParamFlags(rawFlags),
    ...getCookieFlags(),
    ...runtimeFlags,
  };
  if (!USE_LD && name && isUndefined(flags[name])) {
    if (typeof config?.localDefault !== 'undefined') {
      flags[name] = config.localDefault;
    } else {
      const fromLocalDevMap = flagDefaultsForLocalDev[name];
      flags[name] = (
        isUndefined(fromLocalDevMap) ? true : fromLocalDevMap
      ) as fixMe;
    }
  }
  return flags as FlagsObject;
};

/** @deprecated Do not use this hook directly. Instead, use the individually generated flag files in `@generated/flags` */
// ts-unused-exports:disable-next-line
export const useINTERNALFlag: UseFlag = (name, config) =>
  useFlags(name, config)[name];

/** JSON flags are not recommended. If you must, you'll need to add to the jsonFlagArr variable in the `src/components/Flag/jsonFlags.ts` file and get an Atlas review */
export const useJSONFlagRawData: UseFlagJSONData = (name, config) =>
  useFlags(name as anyOk, config)[name];

const findKeyUsingAlias = <D extends unknown>(
  flagData: Record<string, D>,
  originalKey: string,
  rawAliasMap: Record<string, string[]>
): D | undefined => {
  if (!isNil(flagData[originalKey])) {
    return flagData[originalKey];
  } else if (!isNil(flagData['*'])) {
    return flagData['*'];
  }
  const rawAliases = Object.entries(rawAliasMap).map(([k, v]) => v.concat(k));
  const aliases = rawAliases.find((arr) => arr.includes(originalKey)) ?? [
    originalKey,
  ];
  const picked = pick(flagData, aliases);
  return Object.values(picked)[0];
};

const jsonDomainAliases: Partial<Record<KnownDomains, KnownDomains[]>> = {
  ...configAliases,
  'test.mm100.mastermindtms.com': ['test.td100.mastermindtms.com'],
};

/** @deprecated A more granular flag type, enabling flag differences across tenant domains. Expects a flag that is set up like:
 * ```
 * {
 *   "dev.mm100.mastermindtms.com": true,
 *   "mm.shipmolo.com": false
 * }
 * ```
 */
export const useDEPRECATEDFlagBasedOnJSONDomain = (
  name: JsonFlagKeys,
  config?: {
    localDefault?: boolean;
  }
): boolean => {
  const flagData = useINTERNALFlag(name as anyOk) as unknown as JsonObject;
  if (isObjectLike(flagData)) {
    let value = findKeyUsingAlias(
      flagData,
      RESOLVED_TENANT_HOSTNAME || '',
      jsonDomainAliases
    );
    if (isNil(value) && IS_PR_PREVIEW) {
      value = flagData[devDomains[0]];
    }
    return Boolean(value);
  } else if (!USE_LD && typeof config?.localDefault !== 'undefined') {
    return config?.localDefault;
  } else if (!USE_LD && isBoolean(flagData)) {
    return flagData;
  }
  return false;
};

/** Waits for the user to be identified in LD, thus never receiving the "anonymous" flag default */
// ts-unused-exports:disable-next-line
export const useFlagWaitForIdentify = <T extends NamesWithoutJson>(
  name: T,
  config?: {
    localDefault?: FlagsObject[T];
  }
): FlagsObject[T] | null => {
  const { ldIdentified } = useKeycloakAuthContext();
  const flagVal = useINTERNALFlag(name);
  // We need to return here if we do not use LD, like the case of Cypress tests.
  if (!USE_LD) {
    if (typeof config?.localDefault !== 'undefined') {
      return config.localDefault;
    }
    return flagVal;
  } else if (!ldIdentified) {
    return null;
  }
  return flagVal;
};

interface BoolProps {
  localDefault?: boolean;
}

interface NumberProps {
  localDefault?: number;
}

interface StringProps {
  localDefault?: string;
}

interface BaseProps {
  /** A LaunchDarkly feature flag ID. Add to the TS definition if you are introducing a new one */
  name: NamesWithoutJson;
  children?: ReactNode;
  fallback?: ReactNode;
}

type Props = BaseProps & (BoolProps | NumberProps | StringProps);

// ts-unused-exports:disable-next-line
export type PropsNoName = Omit<Props, 'name'>;

/** @deprecated Use the individually generated flag files in `@generated/flags` */
// ts-unused-exports:disable-next-line
export const INTERNALFlag: FC<Props> = ({
  fallback,
  children,
  name,
  localDefault,
}) => {
  const flagEnabled = useINTERNALFlag(
    name,
    typeof localDefault !== 'undefined' ? { localDefault } : undefined
  );
  if (flagEnabled) {
    return <>{children}</>;
  }
  return <>{fallback}</>;
};
