import * as TP from '../subscriptionTypes';

import { gql } from '@apollo/client';
export type DriverPreplanEventPayloadFragment = (
  { readonly __typename?: 'PreplanEvent' }
  & Pick<TP.PreplanEvent, 'id' | 'routeId'>
);

export const DriverPreplanEventPayloadFragmentDoc = gql`
    fragment DriverPreplanEventPayload on PreplanEvent {
  id
  routeId
}
    `;