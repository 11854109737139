import * as TP from '../subscriptionTypes';

import { gql } from '@apollo/client';
export type CarrierMatchEventPayloadFragment = (
  { readonly __typename?: 'CarrierMatchEvent' }
  & Pick<TP.CarrierMatchEvent, 'id' | 'type'>
);

export const CarrierMatchEventPayloadFragmentDoc = gql`
    fragment CarrierMatchEventPayload on CarrierMatchEvent {
  id
  type
}
    `;