import { omit } from 'lodash-es';
import { FC } from 'react';

interface ReadOnlyFieldProps {
  onClick?: () => void;
  blank?: boolean;
  name?: string;
  extraBottomPadding?: boolean;
  wrap?: boolean;
}

//

export const ReadOnlyField: FC<ReadOnlyFieldProps> = ({
  children,
  blank,
  extraBottomPadding,
  wrap = false,
  ...rest
}) => {
  const title = typeof children === 'string' ? children : '';
  const baseStyle = {
    paddingBottom: extraBottomPadding ? 18 : 0,
    whiteSpace: wrap ? ('pre-wrap' as anyOk) : undefined,
  };
  const style = blank
    ? {
        ...baseStyle,
        border: 'none',
        backgroundColor: 'transparent',
        paddingLeft: 0,
      }
    : baseStyle;

  return (
    <div
      data-readonly
      title={title}
      css={style}
      {...omit(rest, ['name'])}
      data-fieldname={rest.name}
    >
      {children}
    </div>
  );
};
