import {
  DateTimeDuration,
  endOfMonth,
  endOfWeek,
  now,
  startOfMonth,
  startOfWeek,
  ZonedDateTime,
} from '@internationalized/date';
import { IANATimezones, currentTZ as rawCurrentTZ } from '@utils/time';
import { endOfDay, startOfDay } from '@utils/time/util';
import { win } from '@utils/win';
// eslint-disable-next-line mastery/known-imports
import { parse, toString } from 'duration-fns';
import { noop } from 'lodash-es';
noop(parse, toString);

const currentLocale = win.navigator.language;

export const currentTZ = rawCurrentTZ;

export type RelativeUnit =
  | 'T'
  | 'M'
  | 'Y'
  | 'TW'
  | 'LW'
  | 'TM'
  | 'LM'
  | 'L30D'
  | 'N'
  | 'U'
  | 'B'
  | '31-60D'
  | '61-90D'
  | '91-180D';

export type RelativeUnitForDatepickerV2 = 'T' | 'M' | 'Y';

export const relativeNamedMap: Record<
  RelativeUnit,
  {
    label: string;
    startFn: (timezone: IANATimezones) => ZonedDateTime;
    endFn: (timezone: IANATimezones) => ZonedDateTime;
  }
> = {
  T: {
    label: 'Today',
    startFn: (tz: IANATimezones): ZonedDateTime => {
      return startOfDay(now(tz));
    },
    endFn: (tz: IANATimezones): ZonedDateTime => endOfDay(now(tz)),
  },
  M: {
    label: 'Tomorrow',
    startFn: (tz: IANATimezones): ZonedDateTime =>
      startOfDay(now(tz).add({ days: 1 })),
    endFn: (tz: IANATimezones): ZonedDateTime =>
      endOfDay(now(tz).add({ days: 1 })),
  },
  Y: {
    label: 'Yesterday',
    startFn: (tz: IANATimezones): ZonedDateTime =>
      startOfDay(now(tz).subtract({ days: 1 })),
    endFn: (tz: IANATimezones): ZonedDateTime =>
      endOfDay(now(tz).subtract({ days: 1 })),
  },
  TW: {
    label: 'This Week',
    startFn: (tz: IANATimezones): ZonedDateTime =>
      startOfDay(startOfWeek(now(tz), currentLocale)),
    endFn: (tz: IANATimezones): ZonedDateTime =>
      endOfDay(endOfWeek(now(tz), currentLocale)),
  },
  N: {
    label: 'Next Week',
    startFn: (tz: IANATimezones): ZonedDateTime =>
      startOfDay(endOfWeek(now(tz), currentLocale)).add({ days: 1 }),
    endFn: (tz: IANATimezones): ZonedDateTime =>
      endOfDay(endOfWeek(now(tz).add({ days: 7 }), currentLocale)),
  },
  LW: {
    label: 'Last Week',
    startFn: (tz: IANATimezones): ZonedDateTime =>
      startOfDay(startOfWeek(now(tz).subtract({ days: 7 }), currentLocale)),
    endFn: (tz: IANATimezones): ZonedDateTime =>
      endOfDay(endOfWeek(now(tz).subtract({ days: 7 }), currentLocale)),
  },
  TM: {
    label: 'This Month',
    startFn: (tz: IANATimezones): ZonedDateTime =>
      startOfDay(startOfMonth(now(tz))),
    endFn: (tz: IANATimezones): ZonedDateTime => endOfDay(endOfMonth(now(tz))),
  },
  U: {
    label: 'Next Month',
    startFn: (tz: IANATimezones): ZonedDateTime =>
      startOfDay(startOfMonth(now(tz).add({ months: 1 }))),
    endFn: (tz: IANATimezones): ZonedDateTime =>
      endOfDay(endOfMonth(now(tz).add({ months: 1 }))),
  },
  LM: {
    label: 'Last Month',
    startFn: (tz: IANATimezones): ZonedDateTime =>
      startOfDay(startOfMonth(now(tz).subtract({ months: 1 }))),
    endFn: (tz: IANATimezones): ZonedDateTime =>
      endOfDay(endOfMonth(now(tz).subtract({ months: 1 }))),
  },
  B: {
    label: 'Next 30 Days',
    startFn: (tz: IANATimezones): ZonedDateTime => startOfDay(now(tz)),
    endFn: (tz: IANATimezones): ZonedDateTime =>
      endOfDay(now(tz).add({ days: 29 })),
  },
  L30D: {
    label: 'Last 30 Days',
    startFn: (tz: IANATimezones): ZonedDateTime =>
      startOfDay(now(tz).subtract({ days: 30 })),
    endFn: (tz: IANATimezones): ZonedDateTime => endOfDay(now(tz)),
  },
  '31-60D': {
    label: 'Prev 31-60d',
    startFn: (tz: IANATimezones): ZonedDateTime =>
      startOfDay(now(tz).subtract({ days: 60 })),
    endFn: (tz: IANATimezones): ZonedDateTime =>
      endOfDay(now(tz).subtract({ days: 31 })),
  },
  '61-90D': {
    label: 'Prev 61-90d',
    startFn: (tz: IANATimezones): ZonedDateTime =>
      startOfDay(now(tz).subtract({ days: 90 })),
    endFn: (tz: IANATimezones): ZonedDateTime =>
      endOfDay(now(tz).subtract({ days: 61 })),
  },
  '91-180D': {
    label: 'Prev 91-180d',
    startFn: (tz: IANATimezones): ZonedDateTime =>
      startOfDay(now(tz).subtract({ days: 180 })),
    endFn: (tz: IANATimezones): ZonedDateTime =>
      endOfDay(now(tz).subtract({ days: 91 })),
  },
};

export interface RelativeObj {
  base: RelativeUnit | RelativeUnitForDatepickerV2;
  start: DateTimeDuration;
  end: DateTimeDuration;
}

const relativeStringInstance = Symbol('relative-string-type');
type RelativeStringEncoding = `R1:${RelativeUnit}:${
  | '-'
  | ''}P${number}D:P${number}D`;

export type RelativeString = typeof relativeStringInstance;

/** The supplied string should conform to the current supported relative encoded value. Example `R1:T:P0D:P0D` */
export const relativeString = (s: RelativeStringEncoding): RelativeString =>
  s as unknown as RelativeString;

export const relativeStringToObj = (
  string: Maybe<string>
): RelativeObj | undefined => {
  if (!string) {
    return undefined;
  }
  const [version, namedRelative, offsetStartStr, offsetEndStr] =
    string.split(':');
  noop(version);
  if (!namedRelative || !offsetStartStr) {
    return undefined;
  }
  let offsetEndStrCoerced = offsetEndStr;
  if (!offsetEndStrCoerced) {
    offsetEndStrCoerced = 'P0D';
  }
  const offsetStartParsed = parse(offsetStartStr);
  const offsetEndParsed = parse(offsetEndStrCoerced);
  return {
    base: namedRelative as RelativeUnit | RelativeUnitForDatepickerV2,
    start: offsetStartParsed,
    end: offsetEndParsed,
  };
};

export const parseRelativeToNamedKey = (
  string: Maybe<string>
): string | null => {
  //
  const obj = relativeStringToObj(string);
  const relativeObj =
    relativeNamedMap[
      (obj?.base ?? '') as RelativeUnit | RelativeUnitForDatepickerV2
    ];
  return relativeObj?.label ?? null;
};

export const parseSingleDateRelative = (
  string: Maybe<string>
): Date | undefined => {
  const obj = relativeStringToObj(string);
  const relativeObj =
    relativeNamedMap[
      (obj?.base ?? '') as RelativeUnit | RelativeUnitForDatepickerV2
    ];
  if (!relativeObj || !obj) {
    return;
  }
  return relativeObj.startFn(currentTZ).toDate();
};

export const parseRelative = (
  string: Maybe<string>
): [Date, Date] | undefined => {
  //
  const obj = relativeStringToObj(string);
  const relativeObj =
    relativeNamedMap[
      (obj?.base ?? '') as RelativeUnit | RelativeUnitForDatepickerV2
    ];
  if (!relativeObj || !obj) {
    return;
  }
  const startRaw = relativeObj.startFn(currentTZ).add(obj.start);
  const endRaw = relativeObj.endFn(currentTZ).add(obj.end);
  return [startRaw.toDate(), endRaw.toDate()];
};

export const relativeToString = (
  obj: Maybe<RelativeObj>
): RelativeString | undefined => {
  if (!obj) {
    return;
  }
  return `R1:${obj.base}:${toString(obj.start)}:${toString(
    obj.end
  )}` as unknown as RelativeString;
};

const singleDateRelativeSet = new Set<RelativeUnitForDatepickerV2>([
  'T',
  'M',
  'Y',
]);

export const singleDateToRelativeString = (
  start: Date
): RelativeUnitForDatepickerV2 | undefined => {
  let found: RelativeUnitForDatepickerV2 | undefined = undefined;
  for (const [key, value] of Object.entries(relativeNamedMap)) {
    if (!singleDateRelativeSet.has(key as RelativeUnitForDatepickerV2)) {
      continue;
    }
    const startFn = value.startFn(currentTZ);
    if (startFn.toDate().valueOf() === start.valueOf()) {
      found = key as RelativeUnitForDatepickerV2;
      // exit loop so we don't keep searching and match something that has the same startFn
      break;
    }
  }
  return found;
};
