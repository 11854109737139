import { BASE_HEADER_HEIGHT, IS_HEADER_ALLOWED } from '@app/Sidebar/constants';
import { ENV_VARS, MODE } from '@env';

import { UnitOfCurrencyEnumV2 } from '@generated/types';
import { sendSync } from './electron';
import { jsonParse } from './json';
import { win } from './win';

export const IS_CI = ENV_VARS.CI === 'true' || ENV_VARS.VITE_CI === 'true';

export const devDomains = [
  'dev.mm100.mastermindtms.com',
  'mastery-frontend.dev.mm100.mastermindtms.com',
] as const;
type KnownDevDomains = (typeof devDomains)[number];

const pullDomains = [
  'mastery-frontend.pages.dev',
  'azurestaticapps.net',
] as const;
type KnownPullDomains = (typeof pullDomains)[number];

const testDomains = [
  'test.mm100.mastermindtms.com',
  'test.td100.mastermindtms.com',
  'mm100.mastermindtms.com',
] as const;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type KnownTestDomains = (typeof testDomains)[number];

const previewDomains = [
  'test.td100.mastermindtms.com',
  'mm100.mastermindtms.com',
  'test.ml100.mastermindtms.com',
  'sandbox.shipmolo.com',
  'dev.sn100.mastermindtms.com',
  'test.sn100.mastermindtms.com',
  'upsidedown.shipmolo.com',
  'dev.we100.mastermindtms.com',
  'test.we100.mastermindtms.com',
  'dev.tb100.mastermindtms.com',
  'test.ru100.mastermindtms.com',
  'test.tm100.mastermindtms.com',
  'test.pr100.mastermindtms.com',
  'test.ls8fm.mastermindtms.com',
  'test.loadsmith.mastermindtms.com',
  'test.averitt.mastermindtms.com',
  'test.covenant.mastermindtms.com',
  // 'test.kbx.mastermindtms.com',
  'test.cargill.mastermindtms.com',
  'test.cat.mastermindtms.com',
  'test.dollargeneral.mastermindtms.com',
  'test.kdp.mastermindtms.com',
  'test.pepsi.mastermindtms.com',
  'test.usmmg.mastermindtms.com',
] as const;
type KnownPreviewDomains = (typeof previewDomains)[number];

const prodDomains = [
  /** "ml100.mastermindtms.com" below not actually deployed,
   * but we use it here for eventual config resolution */
  'ml100.mastermindtms.com',
  'mm.shipmolo.com',
  'molo.mastermindtms.com',
  'ru100.mastermindtms.com',
  'sn100.mastermindtms.com',
  'tb100.mastermindtms.com',
  'we100.mastermindtms.com',
  'tm100.mastermindtms.com',
  'pr100.mastermindtms.com',
  'loadsmith.mastermindtms.com',
  'covenant.mastermindtms.com',
  'averitt.mastermindtms.com',
  'hirschbach.mastermindtms.com',
  'evans.mastermindtms.com',
  'cargill.mastermindtms.com',
  'cat.mastermindtms.com',
  'kdp.mastermindtms.com',
  'demo.mastermindtms.com',
  'dollargeneral.mastermindtms.com',
  'pepsi.mastermindtms.com',
  'usmmg.mastermindtms.com',
] as const;
type KnownProdDomains = (typeof prodDomains)[number];

type KnownDomainsWithoutProd =
  | KnownDevDomains
  | KnownTestDomains
  | KnownPullDomains
  | KnownPreviewDomains;

export type KnownDomains = KnownDomainsWithoutProd | KnownProdDomains;

export const tenantDomainArr: KnownDomains[] = [
  ...devDomains,
  ...pullDomains,
  ...previewDomains,
  ...prodDomains,
];

enum KnownV1Tenants {
  mm100 = 'mm100',
  sn100 = 'sn100',
  ml100 = 'ml100',
  tb100 = 'tb100',
  we100 = 'we100',
  ru100 = 'ru100',
  td100 = 'td100',
  tm100 = 'tm100',
  pr100 = 'pr100',
}

enum KnownV2Tenants {
  // Loadsmith
  ls8fm = 'ls8fm',
  // Averitt
  ave4u = 'ave4u',
  // Covenant
  co2yt = 'co2yt',
  // kbx84 = 'kbx84',
  cpg = 'cpg',
  // Cargill
  cgl60 = 'cgl60',
  // Evans
  eva9o = 'eva9o',
  // Hirschbach
  hir3j = 'hir3j',
  // CAT
  cat7x = 'cat7x',
  // Dollar General
  dg3nl = 'dg3nl',
  // Keurig Dr Pepper
  kdp5t = 'kdp5t',
  // Pepsi
  pep5c = 'pep5c',
  // mastery demo
  mdem0 = 'mdem0',
  // multimodal group
  usmmg = 'usmmg',
}

export const TENANT_ID_TO_NAME: Record<KnownV2Tenants, string> = {
  ls8fm: 'loadsmith',
  ave4u: 'averitt',
  co2yt: 'covenant',
  cpg: 'cpg',
  cgl60: 'cargill',
  eva9o: 'evans',
  hir3j: 'hirschbach',
  cat7x: 'cat',
  dg3nl: 'dollargeneral',
  kdp5t: 'kdp',
  pep5c: 'pepsi',
  mdem0: 'demo',
  usmmg: 'usmmg',
};

export type KnownTenants = KnownV1Tenants | KnownV2Tenants;

let hostname: fixMe = '';
try {
  hostname = win.location.hostname || '';
} catch {
  // noop
}

export const IS_LOCAL_DEV = MODE === 'development';

export const testForPRPreview = (hn: string): boolean => {
  return Boolean(pullDomains.find((d) => hn.includes(d)));
};

export const IS_PR_PREVIEW = testForPRPreview(hostname);

/** Is Internal Dev or PR Preview */
export const IS_DEV_DOMAIN = devDomains.includes(hostname);
/** Is Internal Test, Thunderdome, or Mastery Demo */
export const IS_TEST_DOMAIN = testDomains.includes(hostname);
export const IS_PROD_DOMAIN = prodDomains.includes(hostname);
/** Is Internal Dev, PR Preview, Internal Test, Thunderdome, or Mastery Demo */
export const IS_DEV_OR_TEST_OR_PR_DOMAIN =
  IS_DEV_DOMAIN || IS_TEST_DOMAIN || IS_PR_PREVIEW;

const IS_PREVIEW_DOMAIN = previewDomains.includes(hostname);

enum EnvType {
  // local dev
  development = 'development',
  // client UAT
  preview = 'preview',
  // production
  production = 'production',
  // dev or test
  staging = 'staging',
  // PR preview
  pull = 'pull',
}

let envStr: EnvType = EnvType.production;

if (MODE !== 'production') {
  envStr = EnvType.development;
}

if (IS_PR_PREVIEW) {
  envStr = EnvType.pull;
} else if (IS_DEV_DOMAIN || IS_TEST_DOMAIN) {
  envStr = EnvType.staging;
} else if (IS_PREVIEW_DOMAIN) {
  envStr = EnvType.preview;
}

if (IS_CI) {
  envStr = EnvType.development;
}

export const IS_UNIT_TEST = MODE === 'test';
// ts-unused-exports:disable-next-line
export const IS_CYPRESS = Boolean((win as anyOk).Cypress);
// ts-unused-exports:disable-next-line
export const IS_MERCATOR = Boolean(
  (document as fixMe).querySelector?.('head')?.attributes?.['data-mercator']
);
export const IS_CY_COMPONENT_TEST =
  (win as anyOk).Cypress && (win as anyOk).Cypress.testingType === 'component';

export const IS_LOCAL_CYPRESS = IS_CYPRESS && IS_LOCAL_DEV;
export const IS_LOCAL_OR_CI_CYPRESS = IS_LOCAL_CYPRESS || (IS_CYPRESS && IS_CI);
export const IS_LOCAL_DEV_NO_CY_NO_EMULATE =
  !IS_CYPRESS &&
  !ENV_VARS.VITE_EMULATE_DOMAIN &&
  (ENV_VARS.VITE_GRAPHQL_URL ?? '').match('localhost');
/** If MODE === 'production' */
export const IS_PRODUCTION_BUILD = MODE === 'production';

export const environment = envStr;

export const IS_NOT_PREVIEW_OR_PROD =
  environment !== 'preview' && environment !== 'production';

export const QUERY_PARAM_FLAGS_ENABLED =
  IS_DEV_OR_TEST_OR_PR_DOMAIN || IS_CYPRESS || IS_UNIT_TEST || IS_LOCAL_DEV;

export const IS_NOT_PROD_ENV = environment !== 'production';

/** ie "en-US" */
export const userLang = navigator.language;

/** The key that is used for localstorage of user prefs */
export const KEY_USER_PREFERENCES = 'USER_PREFERENCES';

export const HAS_MASTERY_CHROME_EXTENSION = Boolean(
  document.querySelector('script#chrome-mastery-marker')
);

export const CHOSEN_ELECTRON_SPACE: undefined | string = sendSync(
  'store.get',
  'space'
);

export const USE_WALKME = Boolean(
  ENV_VARS.VITE_DISABLE_WALKME === 'false' ||
    ENV_VARS.NODE_ENV !== 'development'
);

/** Cypress can't open child windows, so we want to visit in same page. */
export const NEW_WINDOW_TARGET = IS_CYPRESS ? undefined : '_blank';

// ts-unused-exports:disable-next-line
export const IS_NODE = Boolean(
  typeof global !== 'undefined' && global.performance
);

export const IS_ELECTRON = Boolean(win.electron);

let SKIP_AUTH_VAL = false;
if (IS_LOCAL_CYPRESS || IS_CI) {
  SKIP_AUTH_VAL = true;
}

let USE_SENTRY_VAL = environment !== 'development' && environment !== 'pull';
if (environment === 'development') {
  try {
    USE_SENTRY_VAL = jsonParse(ENV_VARS.VITE_USE_SENTRY || 'false') || false;
  } catch (err: anyOk) {
    // noop
  }
}

/** Use the real LaunchDarkly API instead of mocks */
let USE_LD_VAL = Boolean(
  environment !== 'development' || ENV_VARS.VITE_USE_LD === 'true'
);

if (
  environment === 'development' &&
  ENV_VARS.VITE_EMULATE_DOMAIN &&
  !IS_CYPRESS
) {
  USE_LD_VAL = true;
}

/** Using a live instance of Launch Darkly. `false` for local dev and CI */
export const USE_LD = USE_LD_VAL;

export const USE_SENTRY = USE_SENTRY_VAL;
export const SKIP_AUTH = SKIP_AUTH_VAL;

const searchParams = new URLSearchParams(win.location.search);
let MOCK_GEONAMES_VAL =
  ENV_VARS.VITE_MOCK_GEONAMES === 'true' &&
  searchParams.get('mockGeonames') !== 'false';
if (IS_LOCAL_CYPRESS) {
  MOCK_GEONAMES_VAL = true;
}
export const MOCK_GEONAMES = MOCK_GEONAMES_VAL;
export const GEONAMES_TOKEN = '4e9c750f-c8ff-4c82-bb16-2cf7a363e997';
export const GEONAMES_ENDPOINT = 'https://ba-secure.geonames.net';

export const GEOGRAPHY_SOURCE = 'geonames';

let MOCK_DATA_VAL_FOR_MERCATOR = false;
if (IS_MERCATOR) {
  MOCK_DATA_VAL_FOR_MERCATOR = true;
}
export const MOCK_DATA_FOR_MERCATOR = MOCK_DATA_VAL_FOR_MERCATOR;

export const SIDEBAR_SEARCH_HEIGHT = 59.6;

let IS_WINDOWS_OS_VAL = false;
try {
  if ((navigator as fixMe).userAgentData) {
    IS_WINDOWS_OS_VAL =
      (navigator as fixMe).userAgentData.platform === 'Windows';
  } else {
    IS_WINDOWS_OS_VAL = Boolean(navigator.appVersion.includes('Windows'));
  }
} catch {
  //
}

const IS_WINDOWS_OS = IS_WINDOWS_OS_VAL;
export const SCROLLBAR_WIDTH = IS_WINDOWS_OS ? 14 : 10;

export const USE_THEME_DARK = Boolean(
  document.documentElement.classList.contains('theme-dark')
);

export const HEADER_HEIGHT = IS_HEADER_ALLOWED ? BASE_HEADER_HEIGHT : 0;
export const BUILD_VERSION = ENV_VARS.VITE_BUILD_VERSION;
export const ALLOWED_DOC_TYPE_LIST = [
  '.doc',
  '.docx',
  '.pdf',
  '.xls',
  '.xlsx',
  '.ppt',
  '.pptx',
  '.txt',
  '.odt',
  '.ods',
];

export const EMPTY_ACCT_UUID = '00000000-0000-0000-0000-000000000000'; //empty UUID string sent by accounting service

/** `$` symbol */
export const DEFAULT_CURRENCY_SYMBOL = '$';
/** `USD` code */
export const DEFAULT_CURRENCY_CODE = UnitOfCurrencyEnumV2.Usd;

export const EMAIL_REGEX =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$/;

//Ref.: https://www.regextester.com/112803
//01/01/2022 -> accepted 02/31/2022 -> rejected  2021-01-01 --> rejected 1/1/21 ->Rejected 1/1/2021 -> rejected
export const DATE_REGEX =
  /^(?:(?:(?:0[1-9]|1[0-2])\/(?:0[1-9]|1\d|2[0-8])|(?:0[13-9]|1[0-2])\/(?:29|30)|(?:0[13578]|1[02])\/31)\/[1-9]\d{3}|02\/29(?:\/[1-9]\d(?:0[48]|[2468][048]|[13579][26])|(?:[2468][048]|[13579][26])00))$/;

export class KnownChargeTypes {
  public static FuelSurcharge = 'e11874f3-11d1-4170-aee1-95a457dcaded';
  public static BreakThroughFuelSurcharge =
    '86802a56-e022-49d3-bc4f-bfc3fede2f59';
  public static EstimatedFuelSurcharge = 'adb380b9-427d-4989-8436-f3941a0b57ef';
}

export enum EntityType {
  route = 'Route',
  truck = 'Truck',
  match = 'Match',
}

export const TEAMS_MESSAGING_URL = 'https://teams.microsoft.com/share?';
