import * as TP from '../subscriptionTypes';

import { gql } from '@apollo/client';
export type CostLineItemEventPayloadFragment = (
  { readonly __typename?: 'CostLineItemEvent' }
  & Pick<TP.CostLineItemEvent, 'id' | 'action' | 'costLineItemId'>
);

export const CostLineItemEventPayloadFragmentDoc = gql`
    fragment CostLineItemEventPayload on CostLineItemEvent {
  id
  action
  costLineItemId
}
    `;