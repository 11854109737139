import { useFlagMe215873UatEnvironmentIndicator } from '@generated/flags/ME-215873-uat-environment-indicator';
import { useTheme } from '@hooks/useTheme';
import { IS_LOCAL_DEV, IS_PROD_DOMAIN } from '@utils/constants';
import { ReactElement } from 'react';

interface EnvironmentProps {
  children: ReactElement;
}

export function TestEnvironmentIndicator(
  props: EnvironmentProps
): ReactElement {
  const { colors, gray } = useTheme();
  const environmentIndicator = useFlagMe215873UatEnvironmentIndicator();
  if ((IS_LOCAL_DEV || !IS_PROD_DOMAIN) && environmentIndicator) {
    return (
      <div
        style={{
          borderLeft: `40px solid ${colors.primaryDark}`,
          height: '100%',
        }}
      >
        <h3
          style={{
            position: 'fixed',
            top: '50%',
            left: '15px',
            transform: 'translateY(-50%) rotate(-90deg)',
            transformOrigin: 'left top',
            color: gray[99],
            fontSize: 16,
            whiteSpace: 'nowrap',
            userSelect: 'none',
          }}
          data-testid="test-environment"
        >
          Test Environment
        </h3>
        {props.children}
      </div>
    );
  }
  return <>{props.children}</>;
}
