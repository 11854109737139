import { IS_CI } from '@utils/constants';
import { FS_UNMASK } from '@utils/fullstory';
// eslint-disable-next-line no-restricted-imports, mastery/known-imports
import { ToastOptions as TOpts } from 'react-toastify';
import { toaster } from './ToastComponent';

export const toastOptions: TOpts = {
  position: 'top-right',
  autoClose: IS_CI ? 500 : 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  className: FS_UNMASK,
};

export const toast = toaster;
export type ToastOptions = TOpts;
