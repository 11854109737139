import * as TP from '../types';

import { gql } from '@apollo/client';
import { CarrierInfoSidebarV2FragmentDoc } from '../fragments/carrierInfoSidebarV2';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SearchCarriersCarrierInfoSidebarV2QueryVariables = TP.Exact<{
  filter?: TP.InputMaybe<TP.CarriersFilterV2>;
  first?: TP.InputMaybe<TP.Scalars['Int']>;
  last?: TP.InputMaybe<TP.Scalars['Int']>;
  after?: TP.InputMaybe<TP.Scalars['String']>;
  before?: TP.InputMaybe<TP.Scalars['String']>;
}>;


export type SearchCarriersCarrierInfoSidebarV2Query = (
  { readonly __typename?: 'Query' }
  & { readonly carriersNullableV2?: TP.Maybe<(
    { readonly __typename?: 'CarrierConnectionV2' }
    & { readonly pageInfo: (
      { readonly __typename?: 'PageInfo' }
      & Pick<TP.PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'>
    ), readonly edges: ReadonlyArray<(
      { readonly __typename?: 'CarrierEdgeV2' }
      & Pick<TP.CarrierEdgeV2, 'cursor'>
      & { readonly node: (
        { readonly __typename?: 'CarrierV2' }
        & Pick<TP.CarrierV2, 'id' | 'name' | 'code'>
        & { readonly mainAddress?: TP.Maybe<(
          { readonly __typename?: 'CarrierAddress' }
          & Pick<TP.CarrierAddress, 'id' | 'city' | 'state'>
        )> }
      ) }
    )> }
  )> }
);


export const SearchCarriersCarrierInfoSidebarV2Document = gql`
    query searchCarriersCarrierInfoSidebarV2($filter: CarriersFilterV2, $first: Int, $last: Int, $after: String, $before: String) {
  carriersNullableV2(
    filter: $filter
    first: $first
    last: $last
    after: $after
    before: $before
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        ...CarrierInfoSidebarV2
      }
    }
  }
}
    ${CarrierInfoSidebarV2FragmentDoc}`;

/**
 * __useSearchCarriersCarrierInfoSidebarV2Query__
 *
 * To run a query within a React component, call `useSearchCarriersCarrierInfoSidebarV2Query` and pass it any options that fit your needs.
 * When your component renders, `useSearchCarriersCarrierInfoSidebarV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchCarriersCarrierInfoSidebarV2Query({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useSearchCarriersCarrierInfoSidebarV2Query(baseOptions?: Apollo.QueryHookOptions<SearchCarriersCarrierInfoSidebarV2Query, SearchCarriersCarrierInfoSidebarV2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchCarriersCarrierInfoSidebarV2Query, SearchCarriersCarrierInfoSidebarV2QueryVariables>(SearchCarriersCarrierInfoSidebarV2Document, options);
      }
export function useSearchCarriersCarrierInfoSidebarV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchCarriersCarrierInfoSidebarV2Query, SearchCarriersCarrierInfoSidebarV2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchCarriersCarrierInfoSidebarV2Query, SearchCarriersCarrierInfoSidebarV2QueryVariables>(SearchCarriersCarrierInfoSidebarV2Document, options);
        }
export type SearchCarriersCarrierInfoSidebarV2QueryHookResult = ReturnType<typeof useSearchCarriersCarrierInfoSidebarV2Query>;
export type SearchCarriersCarrierInfoSidebarV2LazyQueryHookResult = ReturnType<typeof useSearchCarriersCarrierInfoSidebarV2LazyQuery>;
export type SearchCarriersCarrierInfoSidebarV2QueryResult = Apollo.QueryResult<SearchCarriersCarrierInfoSidebarV2Query, SearchCarriersCarrierInfoSidebarV2QueryVariables>;