import { Icon } from '@components/Icon';
import { useTheme } from '@hooks/useTheme';
import { FC, HTMLProps, memo } from 'react';
import { visuallyHiddenStyle } from '../VisuallyHidden';

// ts-unused-exports:disable-next-line
export interface Props extends Omit<HTMLProps<HTMLInputElement>, 'value'> {
  size?: number;
  value?: string | boolean;
  showPartialSelection?: boolean;
  useDefaultStylesWhenDisabled?: boolean;
  disableClickBubble?: boolean;
  labelProps?: HTMLProps<HTMLLabelElement>;
}

export const DEFAULT_CHECKBOX_SIZE = 28;

export const Checkbox: FC<Props> = memo(
  ({
    size = DEFAULT_CHECKBOX_SIZE,
    useDefaultStylesWhenDisabled,
    labelProps,
    disableClickBubble,
    showPartialSelection = false,
    ...props
  }) => {
    const { gray, colors, formElement, card } = useTheme();
    const { checked, disabled } = props;

    const enableSelectionStyles = checked || showPartialSelection;

    const useDisabledStyles = disabled && !useDefaultStylesWhenDisabled;

    return (
      <label
        css={{
          position: 'relative',
          cursor: useDisabledStyles ? 'default' : 'pointer',
          padding: 0,
          height: size,
          width: size,
          '&:active': { background: gray[90] },
        }}
        className="checkbox"
        onClick={
          disableClickBubble ? (e): void => e.stopPropagation() : undefined
        }
        {...labelProps}
      >
        <input
          {...(props as anyOk)}
          type="checkbox"
          css={{
            ...visuallyHiddenStyle,
            '&:focus ~ span': {
              ...formElement.focused,
            },
          }}
        />
        <span
          css={{
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            background: useDisabledStyles
              ? gray[95]
              : enableSelectionStyles
              ? colors.primary
              : card.background,
          }}
          data-testid="checkboxSpan"
          className="input"
        >
          <Icon
            i={showPartialSelection ? 'partialCheck' : 'check'}
            size={'sm'}
            css={{
              display: enableSelectionStyles ? 'block' : 'none',
            }}
            color={
              enableSelectionStyles
                ? useDisabledStyles
                  ? gray[40]
                  : gray[95]
                : 'inherit'
            }
          />
        </span>
      </label>
    );
  }
);
