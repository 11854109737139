import * as TP from '../subscriptionTypes';

import { gql } from '@apollo/client';
export type TenderPlanEventPayloadFragment = (
  { readonly __typename?: 'TenderPlanEvent' }
  & Pick<TP.TenderPlanEvent, 'id' | 'type'>
);

export const TenderPlanEventPayloadFragmentDoc = gql`
    fragment TenderPlanEventPayload on TenderPlanEvent {
  id
  type
}
    `;