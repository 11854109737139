/* eslint-disable no-restricted-imports */
import { RoutePath, StringSlash } from '@app/routes';
import { ReactElement } from 'react';
import { Link as RawLink, LinkProps as RawLinkProps } from 'react-router-dom';

export type OptionalSearch = `?${string}` | '';

export interface LinkProps<RoutePathType extends string>
  extends Omit<RawLinkProps, 'to'> {
  to: `${RoutePathType}${OptionalSearch}`;
}

export type MMRoutePathType = RoutePath<StringSlash, StringSlash>;

/** MasterMind specific links */
export type MMLinkTo = `${MMRoutePathType}${OptionalSearch}`;

export const GenericLink = <RoutePathType extends string>(
  props: LinkProps<RoutePathType>
): ReactElement => {
  return <RawLink {...props} />;
};

export const Link = (
  props: LinkProps<RoutePath<StringSlash, StringSlash>>
): ReactElement => {
  return <GenericLink {...props} />;
};
