import { EmployeeDetailsEmployeeTreeV2Fragment } from '@generated/fragments/employeeDetailsEmployeeTreeV2';
import { LockInfoFragment } from '@generated/fragments/lockInfo';
import { SendMessageShareModal } from '@views/SendMessages/SendMessageContainer/SendMessageShareModal';
import { RouteLockMap } from '@views/Shared/RouteLock/Lock';
import { Dispatch, FC, SetStateAction } from 'react';
import { LockAndRouteResponse } from '.';

interface Props {
  modalMetaVal: Maybe<string>;
  shareModalTitle?: string;
  objectId?: string;
  closeModal: () => void;
  modalObj?: Maybe<object>;
  entityEnum?: Maybe<string>;
  toButtonWidth?: Maybe<number>;
  selEmpDet?: EmployeeDetailsEmployeeTreeV2Fragment | undefined;
  routeId?: string;
  loadId?: string;
  setLockAndRouteResponse?: Dispatch<
    SetStateAction<LockAndRouteResponse>
  > | null;
  routeLifeCycleStatus?: string;
  routeLock?: RouteLockMap<LockInfoFragment | null | undefined>;
}
export const ModalData: FC<Props> = ({
  modalMetaVal,
  shareModalTitle,
  objectId,
  closeModal,
  modalObj,
  entityEnum,
  toButtonWidth,
  selEmpDet,
  routeId,
  loadId,
  setLockAndRouteResponse,
  routeLifeCycleStatus,
  routeLock,
}) => {
  return modalMetaVal === 'ShareObject' ? (
    <SendMessageShareModal
      title={shareModalTitle}
      objectId={objectId}
      closeModal={closeModal}
      modalObj={modalObj}
      entityEnum={entityEnum}
      toButtonWidth={toButtonWidth}
      selEmpDet={selEmpDet as EmployeeDetailsEmployeeTreeV2Fragment}
      routeId={routeId}
      loadId={loadId}
      setLockAndRouteResponse={setLockAndRouteResponse}
      routeLifeCycleStatus={routeLifeCycleStatus}
      routeLock={routeLock}
    />
  ) : (
    <></>
  );
};
