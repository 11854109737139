import * as TP from '../types';

import { gql } from '@apollo/client';
export type ScheduleRangeInfoV2Fragment = (
  { readonly __typename?: 'ScheduleRangeV2' }
  & Pick<TP.ScheduleRangeV2, 'endOffsetMs' | 'id' | 'startOffsetMs'>
);

export const ScheduleRangeInfoV2FragmentDoc = gql`
    fragment ScheduleRangeInfoV2 on ScheduleRangeV2 {
  endOffsetMs
  id
  startOffsetMs
}
    `;