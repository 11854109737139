import * as TP from '../subscriptionTypes';

import { gql } from '@apollo/client';
export type PatternOrderMatchEventPayloadFragment = (
  { readonly __typename?: 'PatternOrderMatchEvent' }
  & Pick<TP.PatternOrderMatchEvent, 'id' | 'patternCount'>
);

export const PatternOrderMatchEventPayloadFragmentDoc = gql`
    fragment PatternOrderMatchEventPayload on PatternOrderMatchEvent {
  id
  patternCount
}
    `;