import * as TP from '../types';

import { gql } from '@apollo/client';
import { FacilityInfoForFacilityPickerFragmentDoc, FacilityInfoV2ForFacilityPickerFragmentDoc } from '../fragments/facilityInfoForFacilityPicker';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AllFacilitiesForFacilityPickerQueryVariables = TP.Exact<{
  filter?: TP.InputMaybe<TP.FacilitiesFilter>;
  first?: TP.InputMaybe<TP.Scalars['Int']>;
  last?: TP.InputMaybe<TP.Scalars['Int']>;
  before?: TP.InputMaybe<TP.Scalars['String']>;
  after?: TP.InputMaybe<TP.Scalars['String']>;
  customerIds?: TP.InputMaybe<ReadonlyArray<TP.Scalars['ID']> | TP.Scalars['ID']>;
}>;


export type AllFacilitiesForFacilityPickerQuery = (
  { readonly __typename?: 'Query' }
  & { readonly allFacilities: (
    { readonly __typename?: 'FacilityConnection' }
    & { readonly pageInfo: (
      { readonly __typename?: 'PageInfo' }
      & Pick<TP.PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'>
    ), readonly edges: ReadonlyArray<(
      { readonly __typename?: 'FacilityEdge' }
      & Pick<TP.FacilityEdge, 'cursor'>
      & { readonly node: (
        { readonly __typename?: 'Facility' }
        & Pick<TP.Facility, 'code' | 'facilityNote' | 'id' | 'name' | 'phoneNumber' | 'scaleNote' | 'schedulingContact' | 'sourceType' | 'status' | 'timezone' | 'taxExempt'>
        & { readonly addresses: ReadonlyArray<(
          { readonly __typename?: 'Address' }
          & Pick<TP.Address, 'id' | 'addressableId' | 'addressableType' | 'addressTypeId' | 'city' | 'country' | 'main' | 'postalCode' | 'state' | 'street1' | 'street2' | 'isVerified' | 'riskInfoSource' | 'thirdPartyId'>
          & { readonly addressType?: TP.Maybe<(
            { readonly __typename?: 'KeyValue' }
            & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
          )>, readonly geopoint: (
            { readonly __typename?: 'Geopoint' }
            & Pick<TP.Geopoint, 'lon' | 'lat'>
          ) }
        )>, readonly mainAddress?: TP.Maybe<(
          { readonly __typename?: 'Address' }
          & Pick<TP.Address, 'id' | 'addressableId' | 'addressableType' | 'addressTypeId' | 'city' | 'country' | 'main' | 'postalCode' | 'state' | 'street1' | 'street2' | 'isVerified' | 'riskInfoSource' | 'thirdPartyId'>
          & { readonly addressType?: TP.Maybe<(
            { readonly __typename?: 'KeyValue' }
            & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
          )>, readonly geopoint: (
            { readonly __typename?: 'Geopoint' }
            & Pick<TP.Geopoint, 'lon' | 'lat'>
          ) }
        )>, readonly contacts: ReadonlyArray<(
          { readonly __typename?: 'Contact' }
          & Pick<TP.Contact, 'id' | 'name' | 'contactTypeId' | 'main' | 'phoneNumber' | 'extension' | 'faxNumber' | 'emailAddress' | 'chatUsername' | 'chatTypeId' | 'riskInfoSource' | 'thirdPartyId'>
          & { readonly chatType?: TP.Maybe<(
            { readonly __typename?: 'KeyValue' }
            & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
          )> }
        )>, readonly externalNotes?: TP.Maybe<ReadonlyArray<(
          { readonly __typename?: 'Note' }
          & Pick<TP.Note, 'createdAt' | 'id' | 'noteType' | 'noteableId' | 'noteableType' | 'text' | 'updatedAt'>
        )>>, readonly facilityType?: TP.Maybe<(
          { readonly __typename?: 'KeyValue' }
          & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
        )>, readonly loadFromType?: TP.Maybe<(
          { readonly __typename?: 'KeyValue' }
          & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
        )>, readonly schedulingSystemType?: TP.Maybe<(
          { readonly __typename?: 'KeyValue' }
          & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
        )>, readonly schedules: ReadonlyArray<(
          { readonly __typename?: 'Schedule' }
          & Pick<TP.Schedule, 'appointmentType' | 'dropType' | 'serviceType' | 'trailerTypeIdNullable' | 'id' | 'modeType' | 'scheduleTypeNullable' | 'workType' | 'loadSizeId' | 'routeTransportModeId'>
          & { readonly trailerTypeNullable?: TP.Maybe<(
            { readonly __typename?: 'KeyValue' }
            & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
          )>, readonly loadSize?: TP.Maybe<(
            { readonly __typename?: 'KeyValue' }
            & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
          )>, readonly routeTransportMode?: TP.Maybe<(
            { readonly __typename?: 'KeyValue' }
            & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
          )>, readonly sunday: (
            { readonly __typename?: 'ScheduleEntry' }
            & Pick<TP.ScheduleEntry, 'closed' | 'id'>
            & { readonly hours: ReadonlyArray<(
              { readonly __typename?: 'ScheduleRange' }
              & Pick<TP.ScheduleRange, 'endOffsetMs' | 'id' | 'startOffsetMs'>
            )> }
          ), readonly monday: (
            { readonly __typename?: 'ScheduleEntry' }
            & Pick<TP.ScheduleEntry, 'closed' | 'id'>
            & { readonly hours: ReadonlyArray<(
              { readonly __typename?: 'ScheduleRange' }
              & Pick<TP.ScheduleRange, 'endOffsetMs' | 'id' | 'startOffsetMs'>
            )> }
          ), readonly tuesday: (
            { readonly __typename?: 'ScheduleEntry' }
            & Pick<TP.ScheduleEntry, 'closed' | 'id'>
            & { readonly hours: ReadonlyArray<(
              { readonly __typename?: 'ScheduleRange' }
              & Pick<TP.ScheduleRange, 'endOffsetMs' | 'id' | 'startOffsetMs'>
            )> }
          ), readonly wednesday: (
            { readonly __typename?: 'ScheduleEntry' }
            & Pick<TP.ScheduleEntry, 'closed' | 'id'>
            & { readonly hours: ReadonlyArray<(
              { readonly __typename?: 'ScheduleRange' }
              & Pick<TP.ScheduleRange, 'endOffsetMs' | 'id' | 'startOffsetMs'>
            )> }
          ), readonly thursday: (
            { readonly __typename?: 'ScheduleEntry' }
            & Pick<TP.ScheduleEntry, 'closed' | 'id'>
            & { readonly hours: ReadonlyArray<(
              { readonly __typename?: 'ScheduleRange' }
              & Pick<TP.ScheduleRange, 'endOffsetMs' | 'id' | 'startOffsetMs'>
            )> }
          ), readonly friday: (
            { readonly __typename?: 'ScheduleEntry' }
            & Pick<TP.ScheduleEntry, 'closed' | 'id'>
            & { readonly hours: ReadonlyArray<(
              { readonly __typename?: 'ScheduleRange' }
              & Pick<TP.ScheduleRange, 'endOffsetMs' | 'id' | 'startOffsetMs'>
            )> }
          ), readonly saturday: (
            { readonly __typename?: 'ScheduleEntry' }
            & Pick<TP.ScheduleEntry, 'closed' | 'id'>
            & { readonly hours: ReadonlyArray<(
              { readonly __typename?: 'ScheduleRange' }
              & Pick<TP.ScheduleRange, 'endOffsetMs' | 'id' | 'startOffsetMs'>
            )> }
          ) }
        )>, readonly unloadFromType?: TP.Maybe<(
          { readonly __typename?: 'KeyValue' }
          & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
        )>, readonly customerFacilities?: TP.Maybe<ReadonlyArray<(
          { readonly __typename?: 'CustomerFacility' }
          & Pick<TP.CustomerFacility, 'id' | 'customerId' | 'facilityId' | 'schedulingSystem' | 'drop' | 'isAutoScheduleEligible' | 'isAutoScheduleDefault'>
        )>>, readonly facilityLoadDefaults?: TP.Maybe<(
          { readonly __typename?: 'FacilityLoadDefaults' }
          & Pick<TP.FacilityLoadDefaults, 'id' | 'facilityId' | 'schedSystem' | 'autoSchedEligible' | 'autoSchedDefault'>
        )> }
      ) }
    )> }
  ) }
);

export type AllFacilitiesV2ForFacilityPickerQueryVariables = TP.Exact<{
  filter?: TP.InputMaybe<TP.FacilitiesFilterV2>;
  first?: TP.InputMaybe<TP.Scalars['Int']>;
  last?: TP.InputMaybe<TP.Scalars['Int']>;
  before?: TP.InputMaybe<TP.Scalars['String']>;
  after?: TP.InputMaybe<TP.Scalars['String']>;
  customerIds?: TP.InputMaybe<ReadonlyArray<TP.Scalars['ID']> | TP.Scalars['ID']>;
}>;


export type AllFacilitiesV2ForFacilityPickerQuery = (
  { readonly __typename?: 'Query' }
  & { readonly allFacilitiesV2: (
    { readonly __typename?: 'FacilityConnectionV2' }
    & { readonly pageInfo: (
      { readonly __typename?: 'PageInfo' }
      & Pick<TP.PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'>
    ), readonly edges: ReadonlyArray<(
      { readonly __typename?: 'FacilityEdgeV2' }
      & Pick<TP.FacilityEdgeV2, 'cursor'>
      & { readonly node: (
        { readonly __typename?: 'FacilityV2' }
        & Pick<TP.FacilityV2, 'code' | 'facilityNote' | 'id' | 'name' | 'phoneNumber' | 'scaleNote' | 'schedulingContact' | 'sourceType' | 'status' | 'timezone' | 'taxExempt'>
        & { readonly addresses: ReadonlyArray<(
          { readonly __typename?: 'FacilityAddress' }
          & Pick<TP.FacilityAddress, 'id' | 'addressTypeId' | 'city' | 'country' | 'main' | 'postalCode' | 'state' | 'street1' | 'street2' | 'county' | 'riskInfoSource' | 'thirdPartyId' | 'isVerified'>
          & { readonly addressType?: TP.Maybe<(
            { readonly __typename?: 'KeyValue' }
            & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
          )>, readonly addressTypes?: TP.Maybe<ReadonlyArray<(
            { readonly __typename?: 'KeyValue' }
            & Pick<TP.KeyValue, 'id'>
          )>>, readonly geopoint: (
            { readonly __typename?: 'Geopoint' }
            & Pick<TP.Geopoint, 'lon' | 'lat'>
          ), readonly geopointNullable?: TP.Maybe<(
            { readonly __typename?: 'Geopoint' }
            & Pick<TP.Geopoint, 'lat' | 'lon'>
          )>, readonly customGeopoint?: TP.Maybe<(
            { readonly __typename?: 'Geopoint' }
            & Pick<TP.Geopoint, 'lat' | 'lon'>
          )> }
        )>, readonly mainAddress?: TP.Maybe<(
          { readonly __typename?: 'FacilityAddress' }
          & Pick<TP.FacilityAddress, 'id' | 'addressTypeId' | 'city' | 'country' | 'main' | 'postalCode' | 'state' | 'street1' | 'street2' | 'county' | 'riskInfoSource' | 'thirdPartyId' | 'isVerified'>
          & { readonly addressType?: TP.Maybe<(
            { readonly __typename?: 'KeyValue' }
            & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
          )>, readonly addressTypes?: TP.Maybe<ReadonlyArray<(
            { readonly __typename?: 'KeyValue' }
            & Pick<TP.KeyValue, 'id'>
          )>>, readonly geopoint: (
            { readonly __typename?: 'Geopoint' }
            & Pick<TP.Geopoint, 'lon' | 'lat'>
          ), readonly geopointNullable?: TP.Maybe<(
            { readonly __typename?: 'Geopoint' }
            & Pick<TP.Geopoint, 'lat' | 'lon'>
          )>, readonly customGeopoint?: TP.Maybe<(
            { readonly __typename?: 'Geopoint' }
            & Pick<TP.Geopoint, 'lat' | 'lon'>
          )> }
        )>, readonly contacts: ReadonlyArray<(
          { readonly __typename?: 'FacilityContact' }
          & Pick<TP.FacilityContact, 'id' | 'name' | 'contactTypeId' | 'main' | 'phoneNumber' | 'extension' | 'faxNumber' | 'emailAddress' | 'chatUsername' | 'chatTypeId' | 'riskInfoSource' | 'thirdPartyId'>
          & { readonly chatType?: TP.Maybe<(
            { readonly __typename?: 'KeyValue' }
            & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
          )> }
        )>, readonly externalNotes?: TP.Maybe<ReadonlyArray<(
          { readonly __typename?: 'FacilityNote' }
          & Pick<TP.FacilityNote, 'createdAt' | 'id' | 'noteType' | 'noteableId' | 'noteableType' | 'text' | 'updatedAt'>
        )>>, readonly facilityType?: TP.Maybe<(
          { readonly __typename?: 'KeyValue' }
          & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
        )>, readonly loadFromType?: TP.Maybe<(
          { readonly __typename?: 'KeyValue' }
          & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
        )>, readonly schedulingSystemType?: TP.Maybe<(
          { readonly __typename?: 'KeyValue' }
          & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
        )>, readonly schedules: ReadonlyArray<(
          { readonly __typename?: 'ScheduleV2' }
          & Pick<TP.ScheduleV2, 'appointmentType' | 'dropType' | 'serviceType' | 'trailerTypeId' | 'trailerTypeIdNullable' | 'id' | 'modeType' | 'scheduleType' | 'scheduleTypeNullable' | 'workType' | 'loadSizeId' | 'temperatureId' | 'routeTransportModeId'>
          & { readonly appointmentTypes?: TP.Maybe<ReadonlyArray<(
            { readonly __typename?: 'KeyValue' }
            & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
          )>>, readonly dropTypes?: TP.Maybe<ReadonlyArray<(
            { readonly __typename?: 'KeyValue' }
            & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
          )>>, readonly trailerType: (
            { readonly __typename?: 'KeyValue' }
            & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
          ), readonly trailerTypeNullable?: TP.Maybe<(
            { readonly __typename?: 'KeyValue' }
            & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
          )>, readonly trailerTypes?: TP.Maybe<ReadonlyArray<(
            { readonly __typename?: 'KeyValue' }
            & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
          )>>, readonly scheduleTypes?: TP.Maybe<ReadonlyArray<(
            { readonly __typename?: 'KeyValue' }
            & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
          )>>, readonly workTypes?: TP.Maybe<ReadonlyArray<(
            { readonly __typename?: 'KeyValue' }
            & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
          )>>, readonly loadSize?: TP.Maybe<(
            { readonly __typename?: 'KeyValue' }
            & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
          )>, readonly loadSizes?: TP.Maybe<ReadonlyArray<(
            { readonly __typename?: 'KeyValue' }
            & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
          )>>, readonly temperatures?: TP.Maybe<ReadonlyArray<(
            { readonly __typename?: 'KeyValue' }
            & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
          )>>, readonly routeTransportMode?: TP.Maybe<(
            { readonly __typename?: 'KeyValue' }
            & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
          )>, readonly sunday: (
            { readonly __typename?: 'ScheduleEntryV2' }
            & Pick<TP.ScheduleEntryV2, 'closed' | 'id'>
            & { readonly hours: ReadonlyArray<(
              { readonly __typename?: 'ScheduleRangeV2' }
              & Pick<TP.ScheduleRangeV2, 'endOffsetMs' | 'id' | 'startOffsetMs'>
            )> }
          ), readonly monday: (
            { readonly __typename?: 'ScheduleEntryV2' }
            & Pick<TP.ScheduleEntryV2, 'closed' | 'id'>
            & { readonly hours: ReadonlyArray<(
              { readonly __typename?: 'ScheduleRangeV2' }
              & Pick<TP.ScheduleRangeV2, 'endOffsetMs' | 'id' | 'startOffsetMs'>
            )> }
          ), readonly tuesday: (
            { readonly __typename?: 'ScheduleEntryV2' }
            & Pick<TP.ScheduleEntryV2, 'closed' | 'id'>
            & { readonly hours: ReadonlyArray<(
              { readonly __typename?: 'ScheduleRangeV2' }
              & Pick<TP.ScheduleRangeV2, 'endOffsetMs' | 'id' | 'startOffsetMs'>
            )> }
          ), readonly wednesday: (
            { readonly __typename?: 'ScheduleEntryV2' }
            & Pick<TP.ScheduleEntryV2, 'closed' | 'id'>
            & { readonly hours: ReadonlyArray<(
              { readonly __typename?: 'ScheduleRangeV2' }
              & Pick<TP.ScheduleRangeV2, 'endOffsetMs' | 'id' | 'startOffsetMs'>
            )> }
          ), readonly thursday: (
            { readonly __typename?: 'ScheduleEntryV2' }
            & Pick<TP.ScheduleEntryV2, 'closed' | 'id'>
            & { readonly hours: ReadonlyArray<(
              { readonly __typename?: 'ScheduleRangeV2' }
              & Pick<TP.ScheduleRangeV2, 'endOffsetMs' | 'id' | 'startOffsetMs'>
            )> }
          ), readonly friday: (
            { readonly __typename?: 'ScheduleEntryV2' }
            & Pick<TP.ScheduleEntryV2, 'closed' | 'id'>
            & { readonly hours: ReadonlyArray<(
              { readonly __typename?: 'ScheduleRangeV2' }
              & Pick<TP.ScheduleRangeV2, 'endOffsetMs' | 'id' | 'startOffsetMs'>
            )> }
          ), readonly saturday: (
            { readonly __typename?: 'ScheduleEntryV2' }
            & Pick<TP.ScheduleEntryV2, 'closed' | 'id'>
            & { readonly hours: ReadonlyArray<(
              { readonly __typename?: 'ScheduleRangeV2' }
              & Pick<TP.ScheduleRangeV2, 'endOffsetMs' | 'id' | 'startOffsetMs'>
            )> }
          ) }
        )>, readonly unloadFromType?: TP.Maybe<(
          { readonly __typename?: 'KeyValue' }
          & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
        )>, readonly customerFacilities?: TP.Maybe<ReadonlyArray<(
          { readonly __typename?: 'CustomerFacility' }
          & Pick<TP.CustomerFacility, 'id' | 'customerId' | 'facilityId' | 'schedulingSystem' | 'drop' | 'isAutoScheduleEligible' | 'isAutoScheduleDefault'>
        )>>, readonly facilityLoadDefaults?: TP.Maybe<(
          { readonly __typename?: 'FacilityLoadDefaults' }
          & Pick<TP.FacilityLoadDefaults, 'id' | 'facilityId' | 'schedSystem' | 'autoSchedEligible' | 'autoSchedDefault'>
        )> }
      ) }
    )> }
  ) }
);


export const AllFacilitiesForFacilityPickerDocument = gql`
    query allFacilitiesForFacilityPicker($filter: FacilitiesFilter, $first: Int, $last: Int, $before: String, $after: String, $customerIds: [ID!]) {
  allFacilities(
    filter: $filter
    first: $first
    last: $last
    before: $before
    after: $after
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        ...FacilityInfoForFacilityPicker
      }
    }
  }
}
    ${FacilityInfoForFacilityPickerFragmentDoc}`;

/**
 * __useAllFacilitiesForFacilityPickerQuery__
 *
 * To run a query within a React component, call `useAllFacilitiesForFacilityPickerQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllFacilitiesForFacilityPickerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllFacilitiesForFacilityPickerQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      customerIds: // value for 'customerIds'
 *   },
 * });
 */
export function useAllFacilitiesForFacilityPickerQuery(baseOptions?: Apollo.QueryHookOptions<AllFacilitiesForFacilityPickerQuery, AllFacilitiesForFacilityPickerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllFacilitiesForFacilityPickerQuery, AllFacilitiesForFacilityPickerQueryVariables>(AllFacilitiesForFacilityPickerDocument, options);
      }
export function useAllFacilitiesForFacilityPickerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllFacilitiesForFacilityPickerQuery, AllFacilitiesForFacilityPickerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllFacilitiesForFacilityPickerQuery, AllFacilitiesForFacilityPickerQueryVariables>(AllFacilitiesForFacilityPickerDocument, options);
        }
export type AllFacilitiesForFacilityPickerQueryHookResult = ReturnType<typeof useAllFacilitiesForFacilityPickerQuery>;
export type AllFacilitiesForFacilityPickerLazyQueryHookResult = ReturnType<typeof useAllFacilitiesForFacilityPickerLazyQuery>;
export type AllFacilitiesForFacilityPickerQueryResult = Apollo.QueryResult<AllFacilitiesForFacilityPickerQuery, AllFacilitiesForFacilityPickerQueryVariables>;
export const AllFacilitiesV2ForFacilityPickerDocument = gql`
    query allFacilitiesV2ForFacilityPicker($filter: FacilitiesFilterV2, $first: Int, $last: Int, $before: String, $after: String, $customerIds: [ID!]) {
  allFacilitiesV2(
    filter: $filter
    first: $first
    last: $last
    before: $before
    after: $after
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        ...FacilityInfoV2ForFacilityPicker
      }
    }
  }
}
    ${FacilityInfoV2ForFacilityPickerFragmentDoc}`;

/**
 * __useAllFacilitiesV2ForFacilityPickerQuery__
 *
 * To run a query within a React component, call `useAllFacilitiesV2ForFacilityPickerQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllFacilitiesV2ForFacilityPickerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllFacilitiesV2ForFacilityPickerQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      customerIds: // value for 'customerIds'
 *   },
 * });
 */
export function useAllFacilitiesV2ForFacilityPickerQuery(baseOptions?: Apollo.QueryHookOptions<AllFacilitiesV2ForFacilityPickerQuery, AllFacilitiesV2ForFacilityPickerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllFacilitiesV2ForFacilityPickerQuery, AllFacilitiesV2ForFacilityPickerQueryVariables>(AllFacilitiesV2ForFacilityPickerDocument, options);
      }
export function useAllFacilitiesV2ForFacilityPickerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllFacilitiesV2ForFacilityPickerQuery, AllFacilitiesV2ForFacilityPickerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllFacilitiesV2ForFacilityPickerQuery, AllFacilitiesV2ForFacilityPickerQueryVariables>(AllFacilitiesV2ForFacilityPickerDocument, options);
        }
export type AllFacilitiesV2ForFacilityPickerQueryHookResult = ReturnType<typeof useAllFacilitiesV2ForFacilityPickerQuery>;
export type AllFacilitiesV2ForFacilityPickerLazyQueryHookResult = ReturnType<typeof useAllFacilitiesV2ForFacilityPickerLazyQuery>;
export type AllFacilitiesV2ForFacilityPickerQueryResult = Apollo.QueryResult<AllFacilitiesV2ForFacilityPickerQuery, AllFacilitiesV2ForFacilityPickerQueryVariables>;