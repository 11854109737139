/** `(` */
// ts-unused-exports:disable-next-line
export const leftParen = '(';
/** `)` */
// ts-unused-exports:disable-next-line
export const rightParen = ')';
/** Wraps a string in parentheses for display */
export const parenWrap = (str: nullableScalar): string | null => {
  if (!str) {
    return null;
  }
  return `(${str})`;
};

export const pipeSeparator = ' | ';
