import * as TP from '../subscriptionTypes';

import { gql } from '@apollo/client';
export type LinkedRouteEventPayloadFragment = (
  { readonly __typename?: 'LinkedRouteEvent' }
  & Pick<TP.LinkedRouteEvent, 'id' | 'type'>
);

export const LinkedRouteEventPayloadFragmentDoc = gql`
    fragment LinkedRouteEventPayload on LinkedRouteEvent {
  id
  type
}
    `;