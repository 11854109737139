import { Icon } from '@components/Icon';
import { Input, Props as InputProps } from '@components/Input';
import { useTheme } from '@hooks/useTheme';
import { isBoolean, isNil, noop } from 'lodash-es';
import {
  Dispatch,
  FocusEventHandler,
  ReactElement,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { usePrevious } from 'react-use';
import { STATUS_ICON_WRAPPER_ICON_ZINDEX } from '../StatusIconWrapper';

const EDIT_BUTTON_WIDTH = 36;
export const LOCKED_BG_GRAY_NUM = 95;

export interface Props {
  id?: string;
  children?: (kwargs: {
    setLock: Dispatch<SetStateAction<boolean>>;
    fieldProps: (fieldPropKwargs?: {
      onBlur?: FocusEventHandler<fixMe>;
    }) => { onBlur?: FocusEventHandler<fixMe> } | void;
  }) => ReactNode;
  onLockChange: (b: boolean) => void;
  lockedInputProps?: Omit<InputProps, 'ref'>;
  align?: 'left' | 'right';
  initialLocked?: boolean;
  /** Opt out of locking entirely by passing lockable={false} */
  lockable?: boolean;
  mask?: (s: Maybe<string | anyOk>) => string;
  /** Function that is called on blur to determine if the field should lock, defaults to no lock if field is blank */
  lockWhen?: (s: string) => boolean;
  /** Use the component in controlled mode */
  locked?: boolean;
  /** If the unlocked input should be autofocus when the component first renders */
  initialAutofocus?: boolean;
  icon?: 'edit' | 'x';
  readOnly?: boolean;
}

export const LockedField = (rawProps: Props): ReactElement => {
  const {
    align = 'left',
    onLockChange,
    initialLocked,
    lockedInputProps,
    lockable = true,
    mask,
    lockWhen = Boolean,
    locked: lockedProp,
    initialAutofocus = false,
    icon = 'edit',
    readOnly,
    ...props
  } = rawProps;
  const { gray } = useTheme();
  const inputValue =
    mask && lockedInputProps && lockedInputProps.value
      ? mask(lockedInputProps.value)
      : lockedInputProps?.value;
  const initialLockState = lockedProp ?? initialLocked ?? Boolean(inputValue);
  const [isLockedState, setLock] = useState(initialLockState);
  let isLocked = isLockedState;
  if (readOnly) {
    isLocked = true;
  }
  const isControlled = isBoolean(lockedProp);
  const [inputAutofocus, setInputAutofocus] =
    useState<boolean>(initialAutofocus);
  const prevLocked = usePrevious(isLocked);
  useEffect(() => {
    if (!isNil(prevLocked) && isLocked === false) {
      setInputAutofocus(true);
    }
  }, [isLocked]);
  useEffect(() => {
    if (isControlled) {
      setLock(lockedProp as boolean);
    }
  }, [isControlled, lockedProp]);
  if (!lockable) {
    return (
      <>
        {props.children?.({
          setLock: noop,
          fieldProps: noop,
        })}
      </>
    );
  }
  return (
    <div css={{ position: 'relative' }}>
      {isLocked && !readOnly && (
        <button
          title="Edit field"
          css={{
            position: 'absolute',
            height: '100%',
            width: EDIT_BUTTON_WIDTH,
            right: 0,
            top: 0,
            zIndex: STATUS_ICON_WRAPPER_ICON_ZINDEX + 1,
          }}
          type="button"
          onClick={(): void => {
            onLockChange(false);
            return setLock(() => false);
          }}
        >
          <Icon i={icon} size="sm" color="text" />
        </button>
      )}
      {isLocked && (
        <Input
          disabled
          css={{
            background: gray[LOCKED_BG_GRAY_NUM],
            textAlign: align,
            paddingRight: align === 'left' ? 0 : EDIT_BUTTON_WIDTH + 2,
          }}
          {...lockedInputProps}
          value={inputValue}
        />
      )}
      {!isLocked &&
        props.children?.({
          setLock,
          fieldProps: (fp) => ({
            autoFocus: inputAutofocus,
            onBlur: (e): void => {
              if (lockWhen(lockedInputProps?.value || '')) {
                isControlled ? onLockChange(true) : setLock(true);
              }
              fp?.onBlur?.(e);
            },
          }),
        })}
    </div>
  );
};
