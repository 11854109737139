/**
 * Retrieves the value of a CSS variable from the root document. In specific instances, css vars cannot be used. One example is Mapbox point/line rendering.
 *
 * @param {string} varName - The name of the CSS variable to retrieve.
 *                           It should be in the format `var(--variable-name)`.
 * @returns {string} The value of the CSS variable, likely in hex format.
 */
export const getValueFromCssVar = (varName: string): string => {
  return getComputedStyle(document.documentElement)
    .getPropertyValue(varName.replace('var(', '').replace(')', ''))
    .trim();
};

export const getRGBFromHex = (hex: string): string => {
  const hexValue = hex.replace('#', '');
  const r = parseInt(hexValue.substring(0, 2), 16);
  const g = parseInt(hexValue.substring(2, 4), 16);
  const b = parseInt(hexValue.substring(4, 6), 16);
  if (isNaN(r) || isNaN(g) || isNaN(b)) {
    return '';
  }
  return `rgb(${r}, ${g}, ${b})`;
};
