import { FacilityPicker } from '@views/Common/FacilityPicker';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';

export const FacilitySearch: FC = () => {
  const { push } = useHistory();
  return (
    <FacilityPicker
      include="all"
      onChange={(item, helpers): void => {
        if (item) {
          helpers?.clearSelection();
          push(`/facilities/${item.id}`);
        }
      }}
      renderDropdownInPopper
      showSearchIcon
      inputProps={{
        placeholder: 'Search',
        'data-testid': 'facility-search-input',
      }}
    />
  );
};
