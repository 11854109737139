import * as TP from '../subscriptionTypes';

import { gql } from '@apollo/client';
export type PatternOrderApplicationStatusEventPayloadFragment = (
  { readonly __typename?: 'PatternOrderApplicationStatusEvent' }
  & Pick<TP.PatternOrderApplicationStatusEvent, 'id' | 'type'>
);

export const PatternOrderApplicationStatusEventPayloadFragmentDoc = gql`
    fragment PatternOrderApplicationStatusEventPayload on PatternOrderApplicationStatusEvent {
  id
  type
}
    `;