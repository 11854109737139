import { useTenantConfig } from '@app/TenantConfig';
import { MinionConfigTypes } from '@minion/types';
import { QUERY_PARAM_FLAGS_ENABLED } from '@utils/constants';
import { HAS_WINDOW, win } from '@utils/win';
import { isNil } from 'lodash-es';
import { parse } from 'query-string';

// Allow user to toggle Minion Feature Flags via query param in local/dev environments
const getQueryParamFlagOverride = (
  flag: MinionConfigTypes
): boolean | undefined => {
  let flagValue: boolean | undefined;

  if (HAS_WINDOW && QUERY_PARAM_FLAGS_ENABLED) {
    Object.entries(parse(win.location.search)).forEach(([key, val]) => {
      if (key.startsWith('minionflag-')) {
        const flagName = key.replace(/^minionflag-/, '');

        // Use value only if explicitly set to proper boolean val
        if (flagName === flag && typeof val === 'string') {
          if (val.toLowerCase() === 'true') {
            flagValue = true;
          } else if (val.toLowerCase() === 'false') {
            flagValue = false;
          }
        }
      }
    });
  }

  return flagValue;
};

export const useMinionFlag = (
  flag: MinionConfigTypes,
  kwargs?: {
    /** The fallback is only used if the DDT is not present in the environment. */
    fallback?: boolean;
  }
): boolean => {
  const queryParamOverrideVal = getQueryParamFlagOverride(flag);
  const ddt = useTenantConfig(flag);
  const hasOptions = Boolean(ddt.options.length);

  const tenantConfigVal = useTenantConfig(flag).activeDropdownItems.length > 0;

  if (!isNil(queryParamOverrideVal)) {
    return queryParamOverrideVal;
  } else if (!hasOptions && !isNil(kwargs) && !isNil(kwargs.fallback)) {
    return kwargs.fallback;
  }
  return tenantConfigVal;
};
