import cx from 'classnames';
import { ReactElement } from 'react';
import { actedOnPill, defaultPill, displayBlock } from './style.css';

interface PillProps {
  display?: 'block';
  width?: number;
  value: string;
  active?: boolean;
}

export const Pill = ({
  value,
  active,
  display,
  width,
}: PillProps): ReactElement => {
  return (
    <div
      className={cx(
        defaultPill,
        active && actedOnPill,
        display === 'block' && displayBlock
      )}
      style={{ width }}
    >
      {value}
    </div>
  );
};
