import * as TP from '../subscriptionTypes';

import { gql } from '@apollo/client';
export type BookingOrchestrationStatusEventPayloadFragment = (
  { readonly __typename?: 'BookingOrchestrationStatusEvent' }
  & Pick<TP.BookingOrchestrationStatusEvent, 'id' | 'capacityId' | 'bookingSourceId' | 'vendorId'>
  & { bookingRouteVendorId: TP.BookingOrchestrationStatusEvent['routeVendorId'], bookingDriverId: TP.BookingOrchestrationStatusEvent['driverId'] }
  & { readonly bookingProgress?: TP.Maybe<ReadonlyArray<(
    { readonly __typename?: 'BookingProgress' }
    & Pick<TP.BookingProgress, 'step' | 'status' | 'info'>
    & { readonly errors?: TP.Maybe<ReadonlyArray<(
      { readonly __typename?: 'BookingError' }
      & Pick<TP.BookingError, 'code' | 'message'>
    )>> }
  )>> }
);

export const BookingOrchestrationStatusEventPayloadFragmentDoc = gql`
    fragment BookingOrchestrationStatusEventPayload on BookingOrchestrationStatusEvent {
  id
  bookingRouteVendorId: routeVendorId
  capacityId
  bookingSourceId
  vendorId
  bookingDriverId: driverId
  bookingProgress {
    step
    status
    info
    errors {
      code
      message
    }
  }
}
    `;