import * as TP from '../types';

import { gql } from '@apollo/client';
import { KeyValueInfoFragmentDoc } from './keyValueInfo';
export type CustomerAddressInfoV2Fragment = (
  { readonly __typename?: 'CustomerAddress' }
  & Pick<TP.CustomerAddress, 'addressTypeId' | 'addressableId' | 'addressableType' | 'city' | 'country' | 'id' | 'main' | 'postalCode' | 'state' | 'street1' | 'street2' | 'isVerified'>
  & { readonly addressType?: TP.Maybe<(
    { readonly __typename?: 'KeyValue' }
    & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
  )>, readonly geopoint: (
    { readonly __typename?: 'Geopoint' }
    & Pick<TP.Geopoint, 'lat' | 'lon'>
  ) }
);

export const CustomerAddressInfoV2FragmentDoc = gql`
    fragment CustomerAddressInfoV2 on CustomerAddress {
  addressType {
    ...KeyValueInfo
  }
  geopoint {
    lat
    lon
  }
  addressTypeId
  addressableId
  addressableType
  city
  country
  id
  main
  postalCode
  state
  street1
  street2
  isVerified
}
    ${KeyValueInfoFragmentDoc}`;