import * as TP from '../subscriptionTypes';

import { gql } from '@apollo/client';
export type ClientExceptionRecordEventPayloadFragment = (
  { readonly __typename?: 'ClientExceptionRecordEvent' }
  & Pick<TP.ClientExceptionRecordEvent, 'id' | 'clientExceptionRecordId' | 'clientExceptionRecordEventType' | 'type'>
);

export const ClientExceptionRecordEventPayloadFragmentDoc = gql`
    fragment ClientExceptionRecordEventPayload on ClientExceptionRecordEvent {
  id
  clientExceptionRecordId
  clientExceptionRecordEventType
  type
}
    `;