import * as TP from '../types';

import { gql } from '@apollo/client';
import { EmployeeDetailsInfoV2FragmentDoc } from './employeeDetailsInfoV2';
export type EmployeeGeneralInfoFragment = (
  { readonly __typename?: 'EmployeeV2' }
  & Pick<TP.EmployeeV2, 'allDivisions' | 'id' | 'businessUnitId' | 'companyEmail' | 'divisionId' | 'email' | 'emergencyContact' | 'emergencyPhone' | 'employeeCompanyLevelId' | 'employeeDepartmentId' | 'employeeDepartmentLevelId' | 'employeeDisplayName' | 'employeeGenderId' | 'employeeGroupId' | 'employeeId' | 'employeeOfficeId' | 'employeeRoleId' | 'employeeShirtSizeId' | 'employeeSuffixId' | 'employeeTimeId' | 'employeeTypeId' | 'ext' | 'firstName' | 'firstNameDotLastName' | 'fullName' | 'hireDate' | 'lastName' | 'managerId' | 'middleName' | 'mobilePhone' | 'nickname' | 'personalEmail' | 'phoneNumber' | 'projectId' | 'relationship' | 'slackName' | 'subRegion' | 'termDate' | 'title' | 'userId'>
  & { readonly employeeUserDetail?: TP.Maybe<(
    { readonly __typename?: 'UserV2' }
    & Pick<TP.UserV2, 'id'>
    & { readonly memberships?: TP.Maybe<ReadonlyArray<TP.Maybe<(
      { readonly __typename?: 'Membership' }
      & Pick<TP.Membership, 'organizationID'>
      & { readonly organization?: TP.Maybe<(
        { readonly __typename?: 'Organization' }
        & Pick<TP.Organization, 'id' | 'name'>
      )> }
    )>>> }
  )>, readonly businessUnit?: TP.Maybe<(
    { readonly __typename?: 'KeyValue' }
    & Pick<TP.KeyValue, 'id' | 'name' | 'active'>
  )>, readonly division?: TP.Maybe<(
    { readonly __typename?: 'KeyValue' }
    & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
  )>, readonly employeeBusinessUnits?: TP.Maybe<ReadonlyArray<(
    { readonly __typename?: 'KeyValue' }
    & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
  )>>, readonly employeeDivisions?: TP.Maybe<ReadonlyArray<(
    { readonly __typename?: 'KeyValue' }
    & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
  )>>, readonly employeeAdditionalDivisions?: TP.Maybe<ReadonlyArray<TP.Maybe<(
    { readonly __typename?: 'EmployeeAdditionalDivisions' }
    & Pick<TP.EmployeeAdditionalDivisions, 'id' | 'additionalDivisionId' | 'employeeId'>
  )>>>, readonly employeeGroup?: TP.Maybe<(
    { readonly __typename?: 'KeyValue' }
    & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
  )>, readonly employeeOffice?: TP.Maybe<(
    { readonly __typename?: 'KeyValue' }
    & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
  )>, readonly employeeStatus: (
    { readonly __typename?: 'KeyValue' }
    & Pick<TP.KeyValue, 'id' | 'active'>
  ), readonly manager?: TP.Maybe<(
    { readonly __typename?: 'EmployeeV2' }
    & Pick<TP.EmployeeV2, 'companyEmail' | 'email' | 'emergencyContact' | 'emergencyPhone' | 'employeeDepartmentId' | 'employeeDisplayName' | 'employeeGenderId' | 'employeeGroupId' | 'employeeId' | 'employeeOfficeId' | 'employeeSuffixId' | 'ext' | 'firstName' | 'fullName' | 'id' | 'lastName' | 'middleName' | 'nickname' | 'personalEmail' | 'phoneNumber' | 'relationship' | 'title' | 'userId' | 'managerId'>
  )>, readonly project?: TP.Maybe<(
    { readonly __typename?: 'KeyValue' }
    & Pick<TP.KeyValue, 'id' | 'name' | 'active'>
  )>, readonly employeeProjects?: TP.Maybe<ReadonlyArray<TP.Maybe<(
    { readonly __typename?: 'EmployeeProject' }
    & Pick<TP.EmployeeProject, 'id'>
    & { readonly employeeProject?: TP.Maybe<(
      { readonly __typename?: 'Project' }
      & Pick<TP.Project, 'id' | 'name'>
    )> }
  )>>>, readonly employeeGroups?: TP.Maybe<ReadonlyArray<(
    { readonly __typename?: 'KeyValue' }
    & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
  )>> }
);

export const EmployeeGeneralInfoFragmentDoc = gql`
    fragment EmployeeGeneralInfo on EmployeeV2 {
  ...EmployeeDetailsInfoV2
  allDivisions
  employeeUserDetail {
    id
    memberships {
      organizationID
      organization {
        id
        name
      }
    }
  }
}
    ${EmployeeDetailsInfoV2FragmentDoc}`;