import * as TP from '../subscriptionTypes';

import { gql } from '@apollo/client';
export type PostingChangeEventPayloadFragment = (
  { readonly __typename?: 'PostingChangeEvent' }
  & Pick<TP.PostingChangeEvent, 'id' | 'type' | 'loadId' | 'routeId'>
);

export const PostingChangeEventPayloadFragmentDoc = gql`
    fragment PostingChangeEventPayload on PostingChangeEvent {
  id
  type
  loadId
  routeId
}
    `;