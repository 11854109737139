import { useWhoAmI } from '@app/WhoAmI';
import { getLocalStorage } from '@utils/localStorage';

export const USER_ID_LOCAL_STORAGE_KEY = 'mastermind-user-id';

export const getUserIdFromStorage = (): Maybe<string> => {
  return getLocalStorage(USER_ID_LOCAL_STORAGE_KEY);
};

/** The id from the User object, historically derived from the whoAmI query/service. This is NOT the same as the keycloak user id. */
export const useUserId = (): string | null => {
  const obj = useWhoAmI();
  return obj.user?.id ?? null;
};
