import { FC, lazy, Suspense } from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import type { Props } from './Component';

const LazyComp = lazy(() =>
  import('./Component').then((module) => ({
    default: module.SendMessageShareModal,
  }))
);

const ErrFallback: FC<FallbackProps> = () => {
  return <div>Something went wrong, try again.</div>;
};

export const SendMessageShareModal: FC<Props> = (props) => {
  return (
    <ErrorBoundary FallbackComponent={ErrFallback}>
      <Suspense fallback>{<LazyComp {...props} />}</Suspense>
    </ErrorBoundary>
  );
};
