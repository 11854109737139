/* eslint-disable mastery/named-colors */
import {
  GrayMap,
  Theme as ThemeInterface,
  ThemeProvider,
} from '@emotion/react';
import { FC } from 'react';
import { PRIVATE_colors, newColors } from './colors';
import {
  CODE_FONT,
  MAIN_FONT,
  TABLE_ROW_BORDER_HEIGHT,
  TABLE_ROW_MIN_HEIGHT as foo,
} from './constants';
import { appThemeContract } from './pallette.css';

const { gray } = appThemeContract;

const tableCellBackground = gray.white as string;
const tableCellAltBackground = gray[99] as string;
const tableCellBorderColor = gray[80] as string;

const box = {
  borderColor: gray[80],
};

const TABLE_ROW_MIN_HEIGHT = foo - 1;

/** This is a private and internal object. Use the `@hooks/useTheme` React hook instead. */
export const PRIVATE_themeObj: ThemeInterface = {
  colors: PRIVATE_colors,
  newColors,
  gray: gray as unknown as GrayMap,
  button: {
    background: gray.white,
  },
  box,
  card: {
    background: gray.white as anyOk,
    boxShadow: '0 0 5px 3px rgba(0, 0, 0, 0.03)',
    borderRadius: 0,
  },
  contextMenu: {
    background: gray.white,
  },
  fonts: {
    main: MAIN_FONT,
    code: CODE_FONT,
  },
  formElement: {
    background: gray.white,
    borderColor: box.borderColor,
    focused: {
      border: `1px solid ${PRIVATE_colors.primary}`,
    },
    hasError: {
      border: `1px solid ${PRIVATE_colors.error}`,
    },
    hasWarning: {
      border: `1px solid ${PRIVATE_colors.warningDark}`,
    },
    readOnly: {
      background: gray[95],
    },
  },
  hr: {
    background: gray[90],
  },
  dividerStyle: {
    borderRight: `2px solid ${gray[90]}`,
    boxShadow: 'none',
  },
  dialog: {
    overlay: {
      background: 'rgba(0,0,0,.75)',
    },
    content: {
      background: gray.white,
    },
  },
  shell: {
    content: {
      background: 'var(--body-color)',
    },
  },
  table: {
    tbody: {
      backgroundImage: `linear-gradient(to bottom,
        ${tableCellBackground} 0px, ${tableCellBackground} ${
        TABLE_ROW_MIN_HEIGHT + TABLE_ROW_BORDER_HEIGHT
      }px,
        ${tableCellBorderColor} ${
        TABLE_ROW_MIN_HEIGHT + TABLE_ROW_BORDER_HEIGHT
      }px, ${tableCellBorderColor} ${
        TABLE_ROW_MIN_HEIGHT + TABLE_ROW_BORDER_HEIGHT * 2
      }px,
        ${tableCellAltBackground} ${TABLE_ROW_MIN_HEIGHT}px, ${tableCellAltBackground} ${
        TABLE_ROW_MIN_HEIGHT * 2 + TABLE_ROW_BORDER_HEIGHT * 2
      }px,
        ${tableCellBorderColor} ${
        TABLE_ROW_MIN_HEIGHT * 2 + TABLE_ROW_BORDER_HEIGHT * 2
      }px, ${tableCellBorderColor} ${
        TABLE_ROW_MIN_HEIGHT * 2 + TABLE_ROW_BORDER_HEIGHT * 3
      }px,
        ${tableCellBackground} ${
        TABLE_ROW_MIN_HEIGHT * 2 + TABLE_ROW_BORDER_HEIGHT * 3
      }px, ${tableCellBackground} ${
        TABLE_ROW_MIN_HEIGHT * 3 + TABLE_ROW_BORDER_HEIGHT * 3
      }px,
        ${tableCellBorderColor} ${
        TABLE_ROW_MIN_HEIGHT * 3 + TABLE_ROW_BORDER_HEIGHT * 3
      }px, ${tableCellBorderColor} ${
        TABLE_ROW_MIN_HEIGHT * 3 + TABLE_ROW_BORDER_HEIGHT * 4
      }px,
        ${tableCellAltBackground} ${
        TABLE_ROW_MIN_HEIGHT * 3 + TABLE_ROW_BORDER_HEIGHT * 4
      }px, ${tableCellAltBackground} ${
        TABLE_ROW_MIN_HEIGHT * 4 + TABLE_ROW_BORDER_HEIGHT * 4
      }px,
        ${tableCellBorderColor} ${
        TABLE_ROW_MIN_HEIGHT * 4 + TABLE_ROW_BORDER_HEIGHT * 4
      }px, ${tableCellBorderColor} ${
        TABLE_ROW_MIN_HEIGHT * 4 + TABLE_ROW_BORDER_HEIGHT * 5
      }px)`,
      backgroundSize: `${
        TABLE_ROW_MIN_HEIGHT * 4 + TABLE_ROW_BORDER_HEIGHT * 5
      }px ${TABLE_ROW_MIN_HEIGHT * 4 + TABLE_ROW_BORDER_HEIGHT * 5}px`,
      backgroundAttachment: 'local',
    },
    title: {
      background: gray.white,
    },
    header: {
      background: gray[90],
      color: '#17191d',
    },
    row: {
      selectedRowBackground: PRIVATE_colors.primaryLight,
    },
    cell: {
      background: tableCellBackground,
      borderTop: `1px solid ${tableCellBorderColor}`,
      alternateBackground: tableCellAltBackground,
      hoverBackground: gray[95],
    },
  },
  tooltip: {
    background: gray[95],
    color: '#17191d',
    border: `1px solid ${gray[80]}`,
  },
  sidebar: {
    background: gray.white,
    link: {
      marginLeft: '-8px',
      fontSize: 14,
      padding: '8px',
      borderRadius: '3px',
      display: 'block',
      color: gray[10],
      textDecoration: 'none',
    },
    active: {
      background: '#e9f2fc',
      color: PRIVATE_colors.primary,
      fontWeight: 'bold',
    },
  },
};

/** You should not need this component in src/views. It's already set up for you. */
export const Theme: FC = ({ children }) => (
  <ThemeProvider theme={PRIVATE_themeObj}>{children}</ThemeProvider>
);
