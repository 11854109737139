import * as TP from '../subscriptionTypes';

import { gql } from '@apollo/client';
export type EdiTransactionStorageEventPayloadFragment = (
  { readonly __typename?: 'EdiTransactionStorageEvent' }
  & Pick<TP.EdiTransactionStorageEvent, 'id' | 'type' | 'ediTransactionId'>
);

export const EdiTransactionStorageEventPayloadFragmentDoc = gql`
    fragment EdiTransactionStorageEventPayload on EdiTransactionStorageEvent {
  id
  type
  ediTransactionId
}
    `;