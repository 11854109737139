import {
  ApolloQueryResult,
  OperationVariables,
  QueryOptions,
  useApolloClient,
} from '@apollo/client';
import { useFlagMe252642UseMainApolloClientForLazyPromiseUtil } from '@generated/flags/ME-252642-use-main-apollo-client-for-lazy-promise-util';
import { DocumentNode } from 'graphql';
import { useCallback } from 'react';
import { useMasteryApolloClient } from '../../app/client';

/** As of writing, the return value of a useLazyQuery call was `void`. If you need to `await` the lazy query call and use the data somehow, this hook helps.  */
export function useLazyQueryWithDataPromise<
  TData = unknown,
  TVariables = OperationVariables
>(
  query: DocumentNode
): (
  options: Omit<QueryOptions<TVariables, unknown>, 'query'>
) => Promise<ApolloQueryResult<TData>> {
  const mainClient = useApolloClient();
  const newClient = useMasteryApolloClient();
  const mainFlag = useFlagMe252642UseMainApolloClientForLazyPromiseUtil();
  const client = mainFlag ? mainClient : newClient;
  return useCallback(
    (options: Omit<QueryOptions<TVariables, unknown>, 'query'>) => {
      return client.query<TData, TVariables>({
        ...options,
        query: query,
      } as fixMe);
    },
    [client, query]
  );
}
