import { userLang } from '@utils/constants';
import { SpeechRecognitionEvent, win } from '@utils/win';
import { useCallback, useEffect, useRef, useState } from 'react';

interface SpeechToTextHookReturn {
  isListening: boolean;
  startListening: () => void;
  stopListening: () => void;
}

export const canUseSpeechToText = (): boolean => {
  return Boolean(win.webkitSpeechRecognition || win.SpeechRecognition);
};

export const useSpeechToText = (kwargs: {
  onTranscriptUpdate: (str: string) => void;
}): SpeechToTextHookReturn => {
  const { onTranscriptUpdate } = kwargs;
  const [isListening, setIsListening] = useState(false);
  const recognitionRef = useRef<InstanceType<
    typeof win.webkitSpeechRecognition
  > | null>(null);

  const onTranscriptUpdateRef = useRef(onTranscriptUpdate);

  useEffect(() => {
    onTranscriptUpdateRef.current = onTranscriptUpdate;
  }, [onTranscriptUpdate]);

  useEffect(() => {
    const SpeechRecognition =
      win.webkitSpeechRecognition || win.SpeechRecognition;
    if (!SpeechRecognition) {
      return;
    }

    const recognition = new SpeechRecognition();
    recognition.continuous = true;
    recognition.interimResults = false;
    recognition.lang = userLang;

    recognition.onstart = (): void => {
      setIsListening(true);
    };

    recognition.onresult = (event: SpeechRecognitionEvent): void => {
      for (let i = event.resultIndex; i < event.results.length; ++i) {
        const res = (event.results as fixMe)[i][0].transcript;
        onTranscriptUpdateRef.current(res);
      }
    };

    recognition.onend = (): void => {
      setIsListening(false);
    };

    recognition.onerror = (): void => {
      setIsListening(false);
    };

    recognitionRef.current = recognition;
  }, []);

  const startListening = useCallback(() => {
    if (recognitionRef.current && !isListening) {
      recognitionRef.current.start();
    }
  }, [isListening]);

  const stopListening = useCallback(() => {
    if (recognitionRef.current && isListening) {
      recognitionRef.current.stop();
    }
  }, [isListening]);

  return {
    isListening,
    startListening,
    stopListening,
  };
};
