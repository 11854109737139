import { win } from '@utils/win';
import { noop } from 'lodash-es';
import { ReactElement } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { Theme } from '../../../Theme';

/* eslint-disable no-console  */

/** Gets the "innerText" of a React Component, similar to element.innerText */
export const getInnerText = (obj: ReactElement): string => {
  // We want to get rid of console.errors that spring from this particular function
  // They will most likely only happen in local dev
  // We use renderToStaticMarkup in a non-standard way and the errors generated do not apply to us.
  const rawErr = console.error;
  console.error = noop;
  const html = renderToStaticMarkup(<Theme>{obj}</Theme>);
  console.error = rawErr;
  const strippedHTML = html.replace(/<[^>]*>?/gm, '').trim();
  // This solution comes from this page:
  // https://stackoverflow.com/questions/7394748/whats-the-right-way-to-decode-a-string-that-has-special-html-entities-in-it
  // We should probably use this library:
  // https://github.com/mathiasbynens/he
  // But it is rather large, and our browser support list at time of writing probably aligns ok with the createElement solution
  const txt = win.document.createElement('textarea');
  txt.innerHTML = strippedHTML;
  const val = txt.value;
  txt.remove();
  return val;
};
