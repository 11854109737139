import { PermissionsScopeNames } from '@views/Common/Permissions/constants';
import { createContext, useContext } from 'react';
import shiro from 'shiro-trie';

export const PermissionsContext = createContext<shiro.ShiroTrie | undefined>(
  undefined
);

export const usePermissionsContext = (): shiro.ShiroTrie | undefined =>
  useContext(PermissionsContext);

export const HasPermissionContext = createContext<
  [boolean, PermissionsScopeNames]
>([true, 'default:no:scope']);

export const useHasPermissionFromContext = (): [
  boolean,
  PermissionsScopeNames
] => {
  return useContext(HasPermissionContext);
};
