import { systemBanner } from '@components/SystemAlerts/SystemBanner';
import { useKeycloak } from '@react-keycloak/web';
import { SKIP_AUTH } from '@utils/constants';
import { win } from '@utils/win';
import { whoAmIV2Document } from '@views/Common/Permissions';
import { useCallback, useMemo } from 'react';
import { authLogoutRedirectUri } from '../../../config';

interface Ret {
  logout: () => void;
  keycloak: Keycloak.KeycloakInstance;
  initialized: boolean;
}

export const useLogin = (): Ret => {
  const kc = useKeycloak();
  const { keycloak, initialized } = kc;
  const logout = useCallback((): void => {
    if (!SKIP_AUTH) {
      win.localStorage.removeItem(`swr-${whoAmIV2Document}`);
      win.localStorage.removeItem(`swr-err@${whoAmIV2Document}`);
      keycloak?.logout({
        redirectUri: authLogoutRedirectUri,
      }) ??
        systemBanner({
          title: 'Error',
          content: 'There was an error logging out.',
          type: 'error',
        });
    } else {
      win.location.href = authLogoutRedirectUri;
    }
  }, [keycloak]);

  return useMemo(
    () => ({
      logout,
      keycloak,
      initialized,
    }),
    [initialized, keycloak, logout]
  );
};
