import { isFunction } from 'lodash-es';
import { useEffect, useState } from 'react';

type TriggerType = boolean | (() => boolean);

export const useHasHappened = (
  trigger: TriggerType,
  deps?: unknown[]
): boolean => {
  const [hasHappened, setHasHappened] = useState(false);
  useEffect((): void => {
    const result = isFunction(trigger) ? trigger() : trigger;
    if (result && !hasHappened) {
      setHasHappened(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger, hasHappened, setHasHappened, ...(deps || [])]);
  return hasHappened;
};
