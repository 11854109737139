import { MouseEvent, useCallback, useEffect } from 'react';

export const useClickAwayConditional = (kwargs: {
  onClickAway?: () => void;
  enabled?: boolean;
  ref: React.RefObject<HTMLElement | null>;
  determineFunction?: (event: MouseEvent<HTMLElement>) => boolean;
}): void => {
  const { onClickAway, enabled = true, ref, determineFunction } = kwargs;

  const onClickAwayFn = useCallback(
    (event) => {
      const { current: el } = ref;
      const determine =
        determineFunction ??
        ((e: MouseEvent<HTMLElement>): boolean => {
          return e.target !== el && !el?.contains(e.target as fixMe);
        });
      if (el && determine(event)) {
        onClickAway?.();
      }
    },
    [determineFunction, onClickAway, ref]
  );

  useEffect(() => {
    if (enabled && onClickAway) {
      document.addEventListener('mousedown', onClickAwayFn);
    } else {
      document.removeEventListener('mousedown', onClickAwayFn);
    }
    return (): void => {
      document.removeEventListener('mousedown', onClickAwayFn);
    };
  }, [enabled, onClickAway, onClickAwayFn]);
};
