import * as TP from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CsaGetProviderDetailsQueryVariables = TP.Exact<{
  providerId: TP.Scalars['String'];
}>;


export type CsaGetProviderDetailsQuery = (
  { readonly __typename?: 'Query' }
  & { readonly csaGetProviderDetails: (
    { readonly __typename?: 'ProviderResponse' }
    & Pick<TP.ProviderResponse, 'statusOn' | 'providerName' | 'providerId' | 'isAuthenticated'>
    & { readonly credentials?: TP.Maybe<ReadonlyArray<TP.Maybe<(
      { readonly __typename?: 'Credential' }
      & Pick<TP.Credential, 'id' | 'name'>
    )>>> }
  ) }
);


export const CsaGetProviderDetailsDocument = gql`
    query CsaGetProviderDetails($providerId: String!) {
  csaGetProviderDetails(providerId: $providerId) {
    credentials {
      id
      name
    }
    statusOn
    providerName
    providerId
    isAuthenticated
  }
}
    `;

/**
 * __useCsaGetProviderDetailsQuery__
 *
 * To run a query within a React component, call `useCsaGetProviderDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCsaGetProviderDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCsaGetProviderDetailsQuery({
 *   variables: {
 *      providerId: // value for 'providerId'
 *   },
 * });
 */
export function useCsaGetProviderDetailsQuery(baseOptions: Apollo.QueryHookOptions<CsaGetProviderDetailsQuery, CsaGetProviderDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CsaGetProviderDetailsQuery, CsaGetProviderDetailsQueryVariables>(CsaGetProviderDetailsDocument, options);
      }
export function useCsaGetProviderDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CsaGetProviderDetailsQuery, CsaGetProviderDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CsaGetProviderDetailsQuery, CsaGetProviderDetailsQueryVariables>(CsaGetProviderDetailsDocument, options);
        }
export type CsaGetProviderDetailsQueryHookResult = ReturnType<typeof useCsaGetProviderDetailsQuery>;
export type CsaGetProviderDetailsLazyQueryHookResult = ReturnType<typeof useCsaGetProviderDetailsLazyQuery>;
export type CsaGetProviderDetailsQueryResult = Apollo.QueryResult<CsaGetProviderDetailsQuery, CsaGetProviderDetailsQueryVariables>;