import * as TP from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MfPowerItemV2Fragment = (
  { readonly __typename?: 'PowerAutoCompleteOutput' }
  & Pick<TP.PowerAutoCompleteOutput, 'id' | 'code' | 'displayName'>
  & { readonly carrier?: TP.Maybe<(
    { readonly __typename?: 'CarrierV2' }
    & Pick<TP.CarrierV2, 'id' | 'name'>
  )> }
);

export type PowersForMasterfindV2QueryVariables = TP.Exact<{
  queryFilter: TP.PowerAutoCompleteFilter;
}>;


export type PowersForMasterfindV2Query = (
  { readonly __typename?: 'Query' }
  & { readonly powerAutoComplete: ReadonlyArray<(
    { readonly __typename?: 'PowerAutoCompleteOutput' }
    & Pick<TP.PowerAutoCompleteOutput, 'id' | 'code' | 'displayName'>
    & { readonly carrier?: TP.Maybe<(
      { readonly __typename?: 'CarrierV2' }
      & Pick<TP.CarrierV2, 'id' | 'name'>
    )> }
  )> }
);

export const MfPowerItemV2FragmentDoc = gql`
    fragment MFPowerItemV2 on PowerAutoCompleteOutput {
  id
  code
  displayName
  carrier {
    id
    name
  }
}
    `;
export const PowersForMasterfindV2Document = gql`
    query powersForMasterfindV2($queryFilter: PowerAutoCompleteFilter!) {
  powerAutoComplete(queryFilter: $queryFilter) {
    ...MFPowerItemV2
  }
}
    ${MfPowerItemV2FragmentDoc}`;

/**
 * __usePowersForMasterfindV2Query__
 *
 * To run a query within a React component, call `usePowersForMasterfindV2Query` and pass it any options that fit your needs.
 * When your component renders, `usePowersForMasterfindV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePowersForMasterfindV2Query({
 *   variables: {
 *      queryFilter: // value for 'queryFilter'
 *   },
 * });
 */
export function usePowersForMasterfindV2Query(baseOptions: Apollo.QueryHookOptions<PowersForMasterfindV2Query, PowersForMasterfindV2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PowersForMasterfindV2Query, PowersForMasterfindV2QueryVariables>(PowersForMasterfindV2Document, options);
      }
export function usePowersForMasterfindV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PowersForMasterfindV2Query, PowersForMasterfindV2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PowersForMasterfindV2Query, PowersForMasterfindV2QueryVariables>(PowersForMasterfindV2Document, options);
        }
export type PowersForMasterfindV2QueryHookResult = ReturnType<typeof usePowersForMasterfindV2Query>;
export type PowersForMasterfindV2LazyQueryHookResult = ReturnType<typeof usePowersForMasterfindV2LazyQuery>;
export type PowersForMasterfindV2QueryResult = Apollo.QueryResult<PowersForMasterfindV2Query, PowersForMasterfindV2QueryVariables>;