export type MaskFunctionExpr = (rawValue: string) => Array<RegExp | string>;

// Mask for phone extension to match format: 9999999

export const extensionMask = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

// Mask for 24 hour clock
/* eslint-disable multiline-ternary */

// Current: If the first digit is a 3 or higher, assume time is between 3:00am and 9:59am or later and add leading zero.

export const timeMask = (rawValue: string): Array<RegExp | ':' | '0'> => {
  if (parseInt(rawValue.charAt(0)) > 2) {
    return ['0', /[3-9]/, ':', /[0-5]/, /\d/];
  } else if (rawValue.charAt(0) === '2') {
    return [/2/, /[0-3]/, ':', /[0-5]/, /\d/];
  }
  return [/[0-1]/, /\d/, ':', /[0-5]/, /\d/];
};
