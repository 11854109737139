import { CSSObject } from '@emotion/react';
import { forwardRef, HTMLProps } from 'react';

export const visuallyHiddenStyle: CSSObject = {
  display: 'block',
  border: 0,
  clip: 'rect(0 0 0 0)',
  height: '1px',
  width: '1px',
  margin: '-1px',
  padding: 0,
  overflow: 'hidden',
  position: 'absolute',
};

export const VisuallyHidden = forwardRef<
  HTMLSpanElement,
  HTMLProps<HTMLSpanElement>
>((props, ref) => <span css={visuallyHiddenStyle} ref={ref} {...props} />);
