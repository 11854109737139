import * as TP from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MasterfindLoadV3Fragment = (
  { readonly __typename?: 'SeerMasterFindLoadSearch' }
  & Pick<TP.SeerMasterFindLoadSearch, 'id' | 'loadCode' | 'routeCode' | 'orderNumber' | 'puStartDate' | 'puEndDate' | 'puStartTimeZone' | 'puEndTimeZone' | 'originCity' | 'originState' | 'destinationCity' | 'destinationState' | 'customer' | 'carrier' | 'refNumber'>
);

export type LoadsForMasterfindV3QueryVariables = TP.Exact<{
  filter?: TP.InputMaybe<TP.SeerMasterFindLoadSearchFilter>;
  orderBy?: TP.InputMaybe<TP.SeerMasterFindLoadSearchOrderBy>;
  pagination?: TP.InputMaybe<TP.PaginationInput>;
}>;


export type LoadsForMasterfindV3Query = (
  { readonly __typename?: 'Query' }
  & { readonly seerSearchMasterFindLoadSearch: (
    { readonly __typename?: 'SeerMasterFindLoadSearchResult' }
    & { readonly edges: ReadonlyArray<(
      { readonly __typename?: 'SeerMasterFindLoadSearchEdge' }
      & { readonly node: (
        { readonly __typename?: 'SeerMasterFindLoadSearch' }
        & Pick<TP.SeerMasterFindLoadSearch, 'id' | 'loadCode' | 'routeCode' | 'orderNumber' | 'puStartDate' | 'puEndDate' | 'puStartTimeZone' | 'puEndTimeZone' | 'originCity' | 'originState' | 'destinationCity' | 'destinationState' | 'customer' | 'carrier' | 'refNumber'>
      ) }
    )> }
  ) }
);

export const MasterfindLoadV3FragmentDoc = gql`
    fragment MasterfindLoadV3 on SeerMasterFindLoadSearch {
  id
  loadCode
  routeCode
  orderNumber
  puStartDate
  puEndDate
  puStartTimeZone
  puEndTimeZone
  originCity
  originState
  destinationCity
  destinationState
  customer
  carrier
  refNumber
}
    `;
export const LoadsForMasterfindV3Document = gql`
    query loadsForMasterfindV3($filter: SeerMasterFindLoadSearchFilter, $orderBy: SeerMasterFindLoadSearchOrderBy, $pagination: PaginationInput) {
  seerSearchMasterFindLoadSearch(
    filter: $filter
    orderBy: $orderBy
    pagination: $pagination
  ) {
    edges {
      node {
        ...MasterfindLoadV3
      }
    }
  }
}
    ${MasterfindLoadV3FragmentDoc}`;

/**
 * __useLoadsForMasterfindV3Query__
 *
 * To run a query within a React component, call `useLoadsForMasterfindV3Query` and pass it any options that fit your needs.
 * When your component renders, `useLoadsForMasterfindV3Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadsForMasterfindV3Query({
 *   variables: {
 *      filter: // value for 'filter'
 *      orderBy: // value for 'orderBy'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useLoadsForMasterfindV3Query(baseOptions?: Apollo.QueryHookOptions<LoadsForMasterfindV3Query, LoadsForMasterfindV3QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoadsForMasterfindV3Query, LoadsForMasterfindV3QueryVariables>(LoadsForMasterfindV3Document, options);
      }
export function useLoadsForMasterfindV3LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoadsForMasterfindV3Query, LoadsForMasterfindV3QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoadsForMasterfindV3Query, LoadsForMasterfindV3QueryVariables>(LoadsForMasterfindV3Document, options);
        }
export type LoadsForMasterfindV3QueryHookResult = ReturnType<typeof useLoadsForMasterfindV3Query>;
export type LoadsForMasterfindV3LazyQueryHookResult = ReturnType<typeof useLoadsForMasterfindV3LazyQuery>;
export type LoadsForMasterfindV3QueryResult = Apollo.QueryResult<LoadsForMasterfindV3Query, LoadsForMasterfindV3QueryVariables>;