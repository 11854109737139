// eslint-disable-next-line no-restricted-imports
import { dialogIdSelector } from '@components/Table/util/constants';
import { newColors } from '@components/Theme/colors';
import styled from '@emotion/styled';
import { FocusScope } from '@react-aria/focus';
import { DOMProps } from '@react-types/shared';
import { STATUS_DIALOG } from '@utils/zIndex';
import { cloneElement, FC, Fragment, ReactNode, useRef } from 'react';
import {
  AriaButtonProps,
  AriaModalOverlayProps,
  useModalOverlay,
  useOverlayTrigger,
} from 'react-aria';
import {
  OverlayTriggerProps,
  OverlayTriggerState,
  useOverlayTriggerState,
} from 'react-stately';
import {
  AlertActionButton,
  AlertDismissButton,
  SystemAlertHeader,
} from '../../shared/components';
import { SystemAlertType } from '../../shared/constants';

export interface ActionProps {
  label: string;
  onClick: () => void;
}

export interface DismissProps {
  label?: string;
  onClick?: () => void;
}

const ModalUnderlay = styled.div({
  position: 'fixed',
  zIndex: STATUS_DIALOG,
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  background: newColors.system.modal.underlay,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

interface StyledDialogProps {
  type: SystemAlertType;
}

const StyledDialog = styled.div<StyledDialogProps>((props) => ({
  background: newColors.system[props.type].background,
  width: '428px',
  height: 'auto',
  border: `1px solid ${newColors.system[props.type].accent}`,
  borderRadius: '4px',
  borderWidth: '1px 1px 1px 8px',
  zIndex: 1,
  cursor: 'auto',
  padding: '12px 16px',
}));

const Content = styled.div({
  margin: '8px 0 16px 24px',
});

const ButtonGroup = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
});

interface ModalProps {
  state: OverlayTriggerState;
  children: ReactNode;
  title?: string | undefined;
  type: SystemAlertType;
  action?: ActionProps;
  dismiss?: DismissProps;
  props: AriaModalOverlayProps;
}

const Modal: FC<ModalProps> = ({
  state,
  children,
  title,
  type,
  action,
  dismiss,
  props,
}: ModalProps) => {
  const ref = useRef(null);
  const { modalProps, underlayProps } = useModalOverlay(props, state, ref);

  const handleDismissClick = (): void => {
    if (dismiss?.onClick) {
      dismiss.onClick();
    }
    state.close();
  };

  // This code exists because if we are currently in a table expand mode, we are inherently using Spectrum Dialog which has a focus lock on it. If we additionally try to use another dialog which has its own focus lock, they fight and can crash the browser. So we opt out of focus lock in this specific case.
  const tableIsInExpandModeRef = useRef(
    Boolean(document.querySelector(dialogIdSelector))
  );
  const FocusScopeComp = tableIsInExpandModeRef?.current
    ? Fragment
    : FocusScope;

  return (
    <ModalUnderlay {...underlayProps}>
      <FocusScopeComp contain restoreFocus autoFocus>
        <StyledDialog {...modalProps} ref={ref} type={type} role="dialog">
          <SystemAlertHeader type={type} title={title} />
          <Content>{children}</Content>
          <ButtonGroup>
            {action && (
              <AlertActionButton
                type={type}
                action={
                  action && {
                    label: action.label,
                    onClick: async (): Promise<void> => {
                      action.onClick();
                      state.close();
                    },
                  }
                }
              >
                Action
              </AlertActionButton>
            )}
            <AlertDismissButton
              type={type}
              onClick={handleDismissClick}
              dismiss={dismiss}
            >
              {dismiss?.label ? dismiss.label : 'Dismiss'}
            </AlertDismissButton>
          </ButtonGroup>
        </StyledDialog>
      </FocusScopeComp>
    </ModalUnderlay>
  );
};

interface DialogProps {
  state: OverlayTriggerState;
  overlayProps: DOMProps;
  children: (close: () => void) => JSX.Element;
  title?: string | undefined;
  type: SystemAlertType;
  action?: ActionProps;
  dismiss?: DismissProps;
  props: anyOk;
}

const Dialog: FC<DialogProps> = ({
  state,
  overlayProps,
  children,
  title,
  type,
  action,
  dismiss,
  ...props
}: DialogProps) => {
  return (
    <>
      {state.isOpen && (
        <Modal
          state={state}
          type={type}
          action={action}
          dismiss={dismiss}
          title={title}
          {...props}
        >
          {cloneElement(children(state.close), overlayProps)}
        </Modal>
      )}
    </>
  );
};

interface StatusDialogProps {
  dialogProps: {
    overlayProps: DOMProps;
    state: OverlayTriggerState;
  };
  children: ReactNode;
  title?: string | undefined;
  action?: ActionProps;
  dismiss?: DismissProps;
  type: SystemAlertType;
}

export const StatusDialog: FC<StatusDialogProps> = ({
  dialogProps,
  children,
  title,
  action,
  dismiss,
  type,
  ...props
}: StatusDialogProps) => {
  return (
    <Dialog
      {...dialogProps}
      type={type}
      action={action}
      dismiss={dismiss}
      props={props}
      title={title}
    >
      {(): JSX.Element => <div>{children}</div>}
    </Dialog>
  );
};

interface StatusDialogHookProps {
  dialogProps: { overlayProps: DOMProps; state: OverlayTriggerState };
  triggerProps: AriaButtonProps<'button'> & { onClick: anyOk };
  overlayProps: DOMProps;
  state: OverlayTriggerState;
}

export const useStatusDialog = (
  opts: OverlayTriggerProps
): StatusDialogHookProps => {
  const state = useOverlayTriggerState(opts);
  const { triggerProps, overlayProps } = useOverlayTrigger(
    { type: 'dialog' },
    state
  );
  return {
    dialogProps: { overlayProps, state },
    triggerProps: { ...triggerProps, onClick: triggerProps.onPress },
    overlayProps,
    state,
  };
};
