import * as TP from '../subscriptionTypes';

import { gql } from '@apollo/client';
export type OfferEventPayloadFragment = (
  { readonly __typename?: 'OfferEvent' }
  & Pick<TP.OfferEvent, 'id' | 'type' | 'offerId' | 'carrierId'>
);

export const OfferEventPayloadFragmentDoc = gql`
    fragment OfferEventPayload on OfferEvent {
  id
  type
  offerId
  carrierId
}
    `;