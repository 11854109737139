import * as TP from '../subscriptionTypes';

import { gql } from '@apollo/client';
export type AssetDriverCurrentHosEventPayloadFragment = (
  { readonly __typename?: 'AssetDriverCurrentHosEvent' }
  & Pick<TP.AssetDriverCurrentHosEvent, 'id' | 'type' | 'driverId'>
  & { readonly latestHosEvent: (
    { readonly __typename?: 'AssetDriverHosEvent' }
    & Pick<TP.AssetDriverHosEvent, 'latestDutyStatusTerm' | 'latestDutyStatusChangeDate' | 'hosRuleTerm'>
    & { readonly driveClock: (
      { readonly __typename?: 'HoursOfServiceClock' }
      & Pick<TP.HoursOfServiceClock, 'timeRemainingSeconds' | 'nextResetDateTime'>
    ), readonly shiftClock: (
      { readonly __typename?: 'HoursOfServiceClock' }
      & Pick<TP.HoursOfServiceClock, 'timeRemainingSeconds' | 'nextResetDateTime'>
    ), readonly cycleClock: (
      { readonly __typename?: 'HoursOfServiceClock' }
      & Pick<TP.HoursOfServiceClock, 'timeRemainingSeconds' | 'nextResetDateTime'>
    ), readonly breakClock: (
      { readonly __typename?: 'HoursOfServiceClock' }
      & Pick<TP.HoursOfServiceClock, 'timeRemainingSeconds' | 'nextResetDateTime'>
    ) }
  ) }
);

export type HoursOfServiceClockFragment = (
  { readonly __typename?: 'HoursOfServiceClock' }
  & Pick<TP.HoursOfServiceClock, 'timeRemainingSeconds' | 'nextResetDateTime'>
);

export const HoursOfServiceClockFragmentDoc = gql`
    fragment HoursOfServiceClock on HoursOfServiceClock {
  timeRemainingSeconds
  nextResetDateTime
}
    `;
export const AssetDriverCurrentHosEventPayloadFragmentDoc = gql`
    fragment AssetDriverCurrentHosEventPayload on AssetDriverCurrentHosEvent {
  id
  type
  driverId
  latestHosEvent {
    driveClock {
      ...HoursOfServiceClock
    }
    shiftClock {
      ...HoursOfServiceClock
    }
    cycleClock {
      ...HoursOfServiceClock
    }
    breakClock {
      ...HoursOfServiceClock
    }
    latestDutyStatusTerm
    latestDutyStatusChangeDate
    hosRuleTerm
  }
}
    ${HoursOfServiceClockFragmentDoc}`;